export const topClipDivStyle = {
    position: "relative",
    marginLeft: "50%",
    clipPath: "polygon(0px 0px, 100% 0px, 100% 100.7%, 0px 0%)",
    backgroundColor: "white",
    width: "50.4%",
    height: "50%"
};
export const bottomClipDivStyle = {
    position: "relative",
    marginLeft: "50%",
    transform: "scaleY(-1)",
    clipPath: "polygon(0px 0px, 100% 0px, 100% 100.7%, 0px 0%)",
    backgroundColor: "white",
    width: "50.4%",
    height: "50%"
};
export const centeredContent = {
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    lineHeight: "100%",
    height: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    clear: "both"
};
export const dashboardBox = {
    width: '250px',
    height: '250px',
    borderRadius: '10px',
    boxShadow: '0px 9px 21px -2px rgba(0,0,0,0.75)',
    float: 'left',
    marginRight: '45px',
    marginBottom: '40px',
    overflow: 'hidden',
    position: 'relative'
};
export const uploadFiles = {
    width: '660px',
    marginTop: '20px',
    textAlign: 'center'
};
