import styled from 'styled-components';

export const Styles = styled.div`
  table {
    border-spacing: 0px;
    border: 1px solid rgb(195, 195, 195);
    border-left: none;
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
    border-top: none;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.3rem;
      border-bottom: 1px solid #cbcbcb;

      :last-child {
        border-right: 0;
      }

      input {
        width: 100%;
      }

      input[type=text] {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
        width: 100%;
      }

      input[type=checkbox] {
        width: 13px;
        margin-left: 10px;
      }
    }
  }

  .pagination {
    padding: 0.3rem;
    position: relative;
    display: inline-block;
    margin-left: 50%;
    width: 546px;
    transform: translateX(-50%);
  }
  .pagination .roundButton {
    border: solid 1px black;
    border-radius: 50%;
    font-family: 'Consolas';
    width: 27px;
    height: 27px;
    font-size: 14px;
    margin-right: 4px;
    cursor: pointer;
  }
  .pagination .roundButton:disabled {
      opacity: 0.6;
  }
  .pageOf {
      margin-left: 5px;
      margin-right: 5px;
  }

  .fullWidth {
    display: flex;
  }
`;