import useGlobalState from "@vighnesh153/use-global-state";
import { useState } from 'react';
import axios from "axios";
import AsyncSearch from "../asyncsearch";
import { Notify, APIURL, BlockUI, UnblockUI, UnblockUIError, GetToken } from "../Helper";

const ShelvesDesignsList = ({ data, onShelfDesignsLoad }) => {
    const [project, setProject] = useState("project", null);

    console.log(project);

    const token = GetToken();

    const makeid = (length) => {
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; ++i) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    let prjId = "";

    const onNameSet = (name) => {
        console.log(name);
    };

    const loadShelfDesign = (id) => {
        BlockUI();
        axios.get(APIURL + 'shelfdesign.php?action=gp&id=' + id + '&t=' + token).then((response) => {
            const sdProj = {
                name: response.data.name,
                author: response.data.author,
                date: response.data.date,
                id: response.data._id,
                data: JSON.parse(response.data.data)
            };
            setProject(sdProj);
            onShelfDesignsLoad(sdProj);
            UnblockUI();
        }).catch(function (error) {
            console.log(error);
            UnblockUIError(error);
        });
    };

    const loadSelectedDesign = (e) => {
        const id = document.getElementById('selectedDesignID').innerText;
        if (id.trim().length > 0) {
            loadShelfDesign(id);
        } else {
            Notify({
                title: "No design selected!",
                type: "danger"
            });
        }
    };

    const copySelectedDesign = (e) => {
        const id = document.getElementById('selectedDesignID').innerText;
        if (id.trim().length > 0) {
            BlockUI();
            axios.get(APIURL + 'shelfdesign.php?action=cp&id=' + id + '&sfx=' + makeid(5) + '&t=' + token).then((response) => {
                const aProj = {
                    name: response.data.name,
                    author: response.data.author,
                    date: response.data.date,
                    id: response.data._id,
                    data: response.data.data
                };
                setProject(aProj);
                onShelfDesignsLoad(aProj);
                //document.getElementById('activeProject').innerText = JSON.stringify(aProj);
                UnblockUI();
            }).catch(function (error) {
                console.log(error);
                UnblockUIError(error);
            });
        } else {
            Notify({
                title: "No design selected!",
                type: "danger"
            });
        }
    };

    const filterDesigns = (e) => {
        const search = document.getElementById('searchDesigns').value.toLowerCase();
        var list = document.getElementById('designsList').children;
        if (search.trim().length > 0) {
            for (let i = 0; i < list.length; ++i) {
                const child = list[i];
                if (child.id.toLowerCase().indexOf(search) >= 0) {
                    child.style.display = 'inline-block';
                } else {
                    child.style.display = 'none';
                }
            }
        } else {
            for (let i = 0; i < list.length; ++i) {
                const child = list[i];
                child.style.display = 'inline-block';
            }
        }
    };

    return (
        <div>
            <div style={{ width: "100%", lineHeight: "34px", textAlign: "center", clear: "both", height: "60px" }}>
                <input id="searchDesigns" type="text" onChange={filterDesigns} placeholder="Search shelf design..." style={{ width: "60%", borderRadius: "20px" }} />
                {/*
                <span className="float-left"><AsyncSearch findBy="name" findWhat="proj" onSet={onNameSet} className="float-left" /></span><input type="button" value="Load" className="w-28 rounded-full inline-block bg-blue-500 p-1 ml-2 cursor-pointer float-left text-white self-center disabled:opacity-50 active:bg-green-700" onClick={() => { loadProject() }} />
                */}
            </div>
            <div style={{ height: '350px', overflow: 'auto', clear: 'both', border: 'solid 1px #aaa', width: '80%', margin: 'auto' }}>
                <ul id="designsList" className="divide-y divide-gray-500">
                    {   data && data.map &&
                        data.map(entry => (
                            <li id={entry.name} className="prodlistitem px-1 py-1 inline-block w-full cursor-pointer hover:bg-gray-200" data={entry.data} style={{ marginBottom: '-4px' }} key={entry._id} onClick={(e) => {
                                document.querySelectorAll("li.prodlistitem").forEach(item => {
                                    item.classList.remove("active");
                                });
                                const ch = e.currentTarget.children;
                                e.currentTarget.classList.add('active');
                                document.getElementById("selectedDesignID").innerText = ch[0].innerText;
                                document.getElementById("selectedDesignName").innerText = ch[1].innerText;
                            }} onDoubleClick={loadSelectedDesign}>
                                <div className="float-left">{entry._id}</div>
                                <div className="float-left ml-6 truncate max-w-2xl text-left">{entry.name}</div>
                                <div className="float-right">{entry.date}</div>
                                <div className="hidden">{entry.author}</div>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="text-left" style={{ marginLeft: '64px', marginTop: '14px' }}>
                <div>Selected design:&nbsp;&nbsp;<span id="selectedDesignName" className="text-gray-900">...</span><span id="selectedDesignID" className="hidden"></span></div>
            </div>
            <div>
                <input type="button" className="w-1/3 rounded-full clear-both inline-block border-2 p-4 mt-5 cursor-pointer font-semibold" style={{ background: "#333333", color: "#FFFFFF" }} value="Open selected design" onClick={loadSelectedDesign}></input>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="button" className="w-1/3 rounded-full clear-both inline-block p-4 mt-5 cursor-pointer text-white font-semibold" style={{ color: "#333333", background: "#E5E5E5" }} value="Copy selected design" onClick={copySelectedDesign}></input>
            </div>
        </div>
    );
};

export default ShelvesDesignsList;