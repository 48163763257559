import axios from 'axios';
import { useState } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { centeredContent } from '../../styles';
import { APIURL, BlockUI, GetToken, UnblockUI, UnblockUIError } from '../Helper';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const Categories = ({ data }) => {
    const [currentCategories, setCurrentCategories] = useState("productsCat");

    const [categories, setCategories] = useState(data);
    let selectedCategoryLevel = "";
    let activeCategory = null;

    const token = GetToken();

    const [sCategories, setSCategories] = useState(null);
    let selectedSCategoryLevel = "";
    let activeSCategory = null;

    const [plCategories, setPlCategories] = useState(null);
    let selectedPlCategoryLevel = "";
    let activePlCategory = null;

    const updateSelectedCategory = (e) => {
        activeCategory = e.target;
        document.getElementById('selectedCategory').value = activeCategory.innerText;
        selectedCategoryLevel = parseInt(activeCategory.getAttribute("lvl"));        

        const cid = activeCategory.getAttribute("orig");

        if (selectedCategoryLevel <= 5) {
            const _t = selectedCategoryLevel + 1;
            document.querySelectorAll('[lvl*="' + _t + '"]').forEach(node => node.style.display = "none");
            document.querySelectorAll('[lvl="' + _t + '"][par="' + cid + '"]').forEach(node => node.style.display = "block");
        }
    };

    const updateSelectedSCategory = (e) => {
        activeSCategory = e.target;
        document.getElementById('selectedSCategory').value = activeSCategory.innerText;
        selectedSCategoryLevel = parseInt(activeSCategory.getAttribute("lvl"));        

        const cid = activeSCategory.getAttribute("orig");

        if (selectedSCategoryLevel <= 5) {
            const _t = selectedSCategoryLevel + 1;
            document.querySelectorAll('[lvl*="' + _t + '"]').forEach(node => node.style.display = "none");
            document.querySelectorAll('[lvl="' + _t + '"][par="' + cid + '"]').forEach(node => node.style.display = "block");
        }
    };

    const updateSelectedPlCategory = (e) => {
        activePlCategory = e.target;
        document.getElementById('selectedPlCategory').value = activePlCategory.innerText;
        selectedPlCategoryLevel = parseInt(activePlCategory.getAttribute("lvl"));        

        const cid = activePlCategory.getAttribute("orig");

        if (selectedPlCategoryLevel <= 5) {
            const _t = selectedPlCategoryLevel + 1;
            document.querySelectorAll('[lvl*="' + _t + '"]').forEach(node => node.style.display = "none");
            document.querySelectorAll('[lvl="' + _t + '"][par="' + cid + '"]').forEach(node => node.style.display = "block");
        }        
    };

    const loadCategories = (e) => {
        switch (e) {
            case "a":
                //if (categories == null) {
                    axios.get(APIURL + "categories.php?action=gac&t=" + token).then(res => {
                        if (res.status === 200) {
                            setCategories(res.data);
                            setTimeout(() => {
                                setCurrentCategories("productsCat");
                                UnblockUI();
                            }, 50);
                        } else {
                            UnblockUIError(res.statusText);
                        }
                    }).catch(err => {
                        UnblockUIError(err);
                    });
                //} else {
                //    setCurrentCategories("productsCat");
                //}
                break;
            
            case "b":
                //if (sCategories == null) {
                    axios.get(APIURL + "categories.php?action=gacs&t=" + token).then(res => {
                        if (res.status === 200) {
                            setSCategories(res.data);
                            setTimeout(() => {
                                setCurrentCategories("shelvesCat");
                                UnblockUI();
                            }, 50);
                        } else {
                            UnblockUIError(res.statusText);
                        }
                    }).catch(err => {
                        UnblockUIError(err);
                    });
                //} else {
                //    setCurrentCategories("shelvesCat");
                //}
                break;
            
            case "c":
                //if (plCategories == null) {
                    BlockUI();
                    axios.get(APIURL + "categories.php?action=gacpl&t=" + token).then(res => {
                        if (res.status === 200) {
                            setPlCategories(res.data);
                            setTimeout(() => {
                            setCurrentCategories("planogramsCat");
                                UnblockUI();
                            }, 50);
                        } else {
                            UnblockUIError(res.statusText);
                        }
                    }).catch(err => {
                        UnblockUIError(err);
                    });
                //} else {
                //    setCurrentCategories("planogramsCat");
                //}
                break;
            
            default:
        }
    };

    return (
        <div className="mt-2 p-4">
        <Accordion preExpanded={["a"]} onChange={(e) => { loadCategories(e[0]); }}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Product categories
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                {
                    currentCategories === "productsCat" &&
                    <div>
                        <div style={centeredContent}>
                            <div className="clear-both mt-5 text-left">
                                <div className="inline-block float-left">
                                    <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 1</div>
                                    <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                        {
                                            categories.length > 0 &&
                                            categories[0]["lvl1"].map(entry => (
                                                entry.name !== "" && entry.name !== "/" &&
                                                <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} lvl="1" onClick={updateSelectedCategory}>{entry.name}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="inline-block float-left">
                                    <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 2 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                        document.querySelectorAll('[lvl*="2"]').forEach(node => node.style.display = "block");
                                    }
                                    } /></div>
                                    <div id="prodLvl2Cat" className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                        {
                                            categories.length > 0 &&
                                            categories[1]["lvl2"].map(entry => (
                                                entry.name !== "" && entry.name !== "/" &&
                                                <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="2" onClick={updateSelectedCategory}>{entry.name}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div id="prodLvl3Cat" className="inline-block float-left">
                                    <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 3 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                        document.querySelectorAll('[lvl*="3"]').forEach(node => node.style.display = "block");
                                    }
                                    } /></div>
                                    <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                        {
                                            categories.length > 0 &&
                                            categories[2]["lvl3"].map(entry => (
                                                entry.name !== "" && entry.name !== "/" &&
                                                <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="3" onClick={updateSelectedCategory}>{entry.name}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div id="prodLvl4Cat" className="inline-block float-left">
                                    <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 4 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                        document.querySelectorAll('[lvl*="4"]').forEach(node => node.style.display = "block");
                                    }
                                    } /></div>
                                    <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                        {
                                            categories.length > 0 &&
                                            categories[3]["lvl4"].map(entry => (
                                                entry.name !== "" && entry.name !== "/" &&
                                                <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="4" onClick={updateSelectedCategory}>{entry.name}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div id="prodLvl5Cat" className="inline-block float-left">
                                    <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 5 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                        document.querySelectorAll('[lvl*="5"]').forEach(node => node.style.display = "block");
                                    }
                                    } /></div>
                                    <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                        {
                                            categories.length > 0 &&
                                            categories[4]["lvl5"].map(entry => (
                                                entry.name !== "" && entry.name !== "/" &&
                                                <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="5" onClick={updateSelectedCategory}>{entry.name}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div id="prodLvl6Cat" className="inline-block float-left">
                                    <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 6 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                        document.querySelectorAll('[lvl*="6"]').forEach(node => node.style.display = "block");
                                    }
                                    } /></div>
                                    <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                        {
                                            categories.length > 0 &&
                                            categories[5]["lvl6"].map(entry => (
                                                entry.name !== "" && entry.name !== "/" &&
                                                <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="6" onClick={updateSelectedCategory}>{entry.name}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={centeredContent} className="clear-both mt-10">
                            <form id="updateCategoryForm" className="w-full">
                                <input id="selectedCategory" type="text" className="w-1/4 rounded"></input>&nbsp;&nbsp;<input type="button" value="Update" className="w-40 rounded-full clear-both inline-block bg-green-500 p-3 cursor-pointer text-white font-semibold hover:bg-red-700 active:bg-green-600" onClick={(e) => {
                                    if (activeCategory != null) {
                                        const newValue = document.getElementById('selectedCategory').value.trim();
                                        const oldValue = activeCategory.getAttribute("orig").trim();
                                        const cID = activeCategory.getAttribute("cid");
                                        const par = activeCategory.getAttribute("par");
                    
                                        if (newValue !== oldValue) {
                                            if (newValue.trim() !== "") {
                                                BlockUI();
                                                axios.get(APIURL + 'categories.php?action=uc&lvl=' + selectedCategoryLevel + "&id=" + cID + "&nname=" + newValue + "&oname=" + oldValue + (par ? "&par=" + par : "") + '&t=' + token)
                                                    .then((res) => {
                                                        activeCategory.setAttribute("orig", newValue);
                                                        activeCategory.innerText = newValue;
                                                        categories[selectedCategoryLevel - 1]["lvl" + selectedCategoryLevel] = res.data;
                                                        UnblockUI({
                                                            title: "Success",
                                                            message: "Category updated.",
                                                            type: "success"
                                                        });
                                                        setCurrentCategories("none");
                                                        loadCategories("a");
                                                    })
                                                    .catch((error) => {
                                                        console.error(error);
                                                        UnblockUIError(error);
                                                    });
                                            } else {
                                                document.getElementById('selectedCategory').setCustomValidity('Please fill out this filed.');
                                                document.getElementById('updateCategoryForm').reportValidity();
                                            }
                                        } else {
                                            document.getElementById('selectedCategory').setCustomValidity('No changes...');
                                            document.getElementById('updateCategoryForm').reportValidity();
                                        }
                                    }
                                }} />
                            </form>
                        </div>
                    </div>
                }
            </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="b">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Shelves categories
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                        {
                            currentCategories === "shelvesCat" && sCategories != null &&
                            <div>
                                <div style={centeredContent}>
                                    <div className="clear-both mt-5 text-left">
                                        <div className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 1</div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    sCategories.length > 0 &&
                                                    sCategories[0]["lvl1"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} lvl="1" onClick={updateSelectedSCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 2 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="2"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div id="prodLvl2Cat" className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    sCategories.length > 0 &&
                                                    sCategories[1]["lvl2"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="2" onClick={updateSelectedSCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl3Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 3 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="3"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    sCategories.length > 0 &&
                                                    sCategories[2]["lvl3"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="3" onClick={updateSelectedSCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl4Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 4 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="4"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    sCategories.length > 0 &&
                                                    sCategories[3]["lvl4"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="4" onClick={updateSelectedSCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl5Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 5 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="5"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    sCategories.length > 0 &&
                                                    sCategories[4]["lvl5"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="5" onClick={updateSelectedSCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl6Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 6 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="6"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    sCategories.length > 0 &&
                                                    sCategories[5]["lvl6"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="6" onClick={updateSelectedSCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={centeredContent} className="clear-both mt-10">
                                    <form id="updateSCategoryForm" className="w-full">
                                        <input id="selectedSCategory" type="text" className="w-1/4 rounded"></input>&nbsp;&nbsp;<input type="button" value="Update" className="w-40 rounded-full clear-both inline-block bg-green-500 p-3 cursor-pointer text-white font-semibold hover:bg-red-700 active:bg-green-600" onClick={(e) => {
                                            console.log(activeSCategory);
                                            if (activeSCategory != null) {
                                                const newValue = document.getElementById('selectedSCategory').value.trim();
                                                const oldValue = activeSCategory.getAttribute("orig").trim();
                                                const cID = activeSCategory.getAttribute("cid");
                                                const par = activeSCategory.getAttribute("par");

                                                console.log(newValue);
                        
                                                if (newValue !== oldValue) {
                                                    if (newValue.trim() !== "") {
                                                        BlockUI();
                                                        axios.get(APIURL + 'categories.php?action=ucs&lvl=' + selectedSCategoryLevel + "&id=" + cID + "&nname=" + newValue + "&oname=" + oldValue + (par ? "&par=" + par : "") + '&t=' + token)
                                                            .then((res) => {
                                                                activeSCategory.setAttribute("orig", newValue);
                                                                activeSCategory.innerText = newValue;
                                                                sCategories[selectedSCategoryLevel - 1]["lvl" + selectedSCategoryLevel] = res.data;
                                                                UnblockUI({
                                                                    title: "Success",
                                                                    message: "Category updated.",
                                                                    type: "success"
                                                                });
                                                                setCurrentCategories("none");
                                                                loadCategories("b");
                                                            })
                                                            .catch((error) => {
                                                                console.error(error);
                                                                UnblockUIError(error);
                                                            });
                                                    } else {
                                                        document.getElementById('selectedSCategory').setCustomValidity('Please fill out this filed.');
                                                        document.getElementById('updateSCategoryForm').reportValidity();
                                                    }
                                                } else {
                                                    document.getElementById('selectedSCategory').setCustomValidity('No changes...');
                                                    document.getElementById('updateSCategoryForm').reportValidity();
                                                }
                                            }
                                        }} />
                                    </form>
                                </div>
                            </div>
                        }
            </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="c">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Planograms categories
                    </AccordionItemButton>
                </AccordionItemHeading>
                    <AccordionItemPanel>
                        {                            
                            currentCategories === "planogramsCat" && plCategories != null &&
                            <div>
                                <div style={centeredContent}>
                                    <div className="clear-both mt-5 text-left">
                                        <div className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 1</div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    plCategories.length > 0 &&
                                                    plCategories[0]["lvl1"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} lvl="1" onClick={updateSelectedPlCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 2 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="2"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div id="prodLvl2Cat" className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    plCategories.length > 0 &&
                                                    plCategories[1]["lvl2"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="2" onClick={updateSelectedPlCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl3Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 3 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="3"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    plCategories.length > 0 &&
                                                    plCategories[2]["lvl3"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="3" onClick={updateSelectedPlCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl4Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 4 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="4"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    plCategories.length > 0 &&
                                                    plCategories[3]["lvl4"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="4" onClick={updateSelectedPlCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl5Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 5 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="5"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    plCategories.length > 0 &&
                                                    plCategories[4]["lvl5"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="5" onClick={updateSelectedPlCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div id="prodLvl6Cat" className="inline-block float-left">
                                            <div className="bg-gray-800 text-gray-50 p-1 w-40 font-semibold">Level 6 <BiRefresh className="float-right cursor-pointer" onClick={() => {
                                                document.querySelectorAll('[lvl*="6"]').forEach(node => node.style.display = "block");
                                            }
                                            } /></div>
                                            <div className="w-40 h-80 border border-gray-400 border-solid mr-5 divide-y divide-gray-500 overflow-auto">
                                                {
                                                    plCategories.length > 0 &&
                                                    plCategories[5]["lvl6"].map(entry => (
                                                        entry.name !== "" && entry.name !== "/" &&
                                                        <div className="cursor-pointer hover:bg-gray-200 p-1 text-sm" orig={entry.name} cid={entry._id} par={entry.parent} lvl="6" onClick={updateSelectedPlCategory}>{entry.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={centeredContent} className="clear-both mt-10">
                                    <form id="updatePlCategoryForm" className="w-full">
                                        <input id="selectedPlCategory" type="text" className="w-1/4 rounded"></input>&nbsp;&nbsp;<input type="button" value="Update" className="w-40 rounded-full clear-both inline-block bg-green-500 p-3 cursor-pointer text-white font-semibold hover:bg-red-700 active:bg-green-600" onClick={(e) => {
                                            if (activePlCategory != null) {
                                                const newValue = document.getElementById('selectedPlCategory').value.trim();
                                                const oldValue = activePlCategory.getAttribute("orig").trim();
                                                const cID = activePlCategory.getAttribute("cid");
                                                const par = activePlCategory.getAttribute("par");
                        
                                                if (newValue !== oldValue) {
                                                    if (newValue.trim() !== "") {
                                                        BlockUI();
                                                        axios.get(APIURL + 'categories.php?action=ucpl&lvl=' + selectedPlCategoryLevel + "&id=" + cID + "&nname=" + newValue + "&oname=" + oldValue + (par ? "&par=" + par : "") + '&t=' + token)
                                                            .then((res) => {
                                                                activePlCategory.setAttribute("orig", newValue);
                                                                activePlCategory.innerText = newValue;
                                                                plCategories[selectedPlCategoryLevel - 1]["lvl" + selectedPlCategoryLevel] = res.data;
                                                                UnblockUI({
                                                                    title: "Success",
                                                                    message: "Category updated.",
                                                                    type: "success"
                                                                });
                                                                setCurrentCategories("none");
                                                                loadCategories("c");
                                                            })
                                                            .catch((error) => {
                                                                console.error(error);
                                                                UnblockUIError(error);
                                                            });
                                                    } else {
                                                        document.getElementById('selectedPlCategory').setCustomValidity('Please fill out this filed.');
                                                        document.getElementById('updatePlCategoryForm').reportValidity();
                                                    }
                                                } else {
                                                    document.getElementById('selectedPlCategory').setCustomValidity('No changes...');
                                                    document.getElementById('updatePlCategoryForm').reportValidity();
                                                }
                                            }
                                        }} />
                                    </form>
                                </div>
                            </div>
                        }
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>
    );

};

export default Categories;