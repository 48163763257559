import { centeredContent, dashboardBox } from '../styles';

const Dashboard = () => {
    return (
        <div className="w-screen mt-10">
            <div style={centeredContent}>
                <div className="clear-both inline-block">
                    <div style={dashboardBox}>
                        <div style={{
                            position: 'absolute',
                            height: '150px',
                            width: '250px',
                            background: 'rgba(144, 19, 57, 0.6)',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                        }}></div>    
                        <img alt="Products" src="../img/products.jpg" />
                        <h1 className="text-4xl absolute top-12 w-full text-white">Products</h1>
                        <div className="w-1/2 rounded-full clear-both block bg-white border border-gray-900 p-5 ml-16 cursor-pointer mt-6 hover:bg-red-400 hover:text-white">Dive in</div>    
                    </div>
                    <div style={dashboardBox}>
                    <div style={{
                            position: 'absolute',
                            height: '150px',
                            width: '250px',
                            background: 'rgba(100, 26, 78, 0.6)',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                        }}></div>    
                        <img alt="Shelves" src="../img/shelves.jpg" />
                        <h1 className="text-4xl absolute top-12 w-full text-white">Shelves</h1>
                        <div className="w-1/2 rounded-full clear-both block bg-white border border-gray-900 p-5 ml-16 cursor-pointer mt-6 hover:bg-red-400 hover:text-white">Dive in</div>
                    </div>
                    <div style={dashboardBox}>
                        <div style={{
                            position: 'absolute',
                            height: '150px',
                            width: '250px',
                            background: 'rgba(78, 59, 133, 0.6)',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                        }}></div>    
                        <img alt="Environments" src="../img/environments.jpg" />
                        <h1 className="text-4xl absolute top-12 w-full text-white">Environments</h1>
                        <div className="w-1/2 rounded-full clear-both block bg-white border border-gray-900 p-5 ml-16 cursor-pointer mt-6 hover:bg-red-400 hover:text-white">Dive in</div>
                    </div>
                    <div style={dashboardBox}>
                        <div style={{
                            position: 'absolute',
                            height: '150px',
                            width: '250px',
                            background: 'rgba(37, 65, 112, 0.6)',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                        }}></div>
                        <img alt="Planograms" src="../img/planograms.jpg" />
                        <h1 className="text-4xl absolute top-12 w-full text-white">Planograms</h1>
                        <div className="w-1/2 rounded-full clear-both block bg-white border border-gray-900 p-5 ml-16 cursor-pointer mt-6 hover:bg-red-400 hover:text-white">Dive in</div>
                    </div>
                </div>
            </div>
            <div style={centeredContent}>
                <div className="clear-both inline-block">
                    <div style={dashboardBox}>
                        <div style={{
                            position: 'absolute',
                            height: '150px',
                            width: '250px',
                            background: 'rgba(194, 160, 0, 0.6)',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                        }}></div>
                        <img alt="Categories" src="../img/categories.jpg" />
                        <h1 className="text-4xl absolute top-12 w-full text-white">Categories</h1>
                        <div className="w-1/2 rounded-full clear-both block bg-white border border-gray-900 p-5 ml-16 cursor-pointer mt-6 hover:bg-yellow-400 hover:text-white">Dive in</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Dashboard;