import ExcelJS from 'exceljs';
import useGlobalState from "@vighnesh153/use-global-state";
import { ACCESSORS_ENVIRONMENTS, ACCESSORS_VALUES_ENVIRONMENTS } from './DataFormat';
import axios from 'axios';
import { useState } from 'react';
import EnvironmentsTableComponent, { tableSelectionChanged } from './table/environments';
import { BlockUI, UnblockUI, Notify, APIURL, UnblockUIError, GetSortOrder, GetToken } from './Helper';
import { BiDownload, BiPlusCircle, BiTrash } from 'react-icons/bi';
import SearchEnvironments from './SearchEnvironments';
import MiniSignal from 'mini-signals';

export const projectEnvironmentsUpdated = new MiniSignal();

const Environments = ({ data, isAllEnvironments, isUpload }) => {
    const [user] = useGlobalState("user");
    const [project] = useGlobalState("project");
    const [downloadEnabled, setDownloadEnabled] = useState(false);
    const [allEnvironments, setAllEnvironments] = useState(null);

    const token = GetToken();

    if (data && data !== allEnvironments) {
        data.sort(GetSortOrder("img"));
        setAllEnvironments(data);
    }
    
    tableSelectionChanged.add((data) => {
        const btnDownload = document.getElementById('environmentsDownload');
        const btnAddToProject = document.getElementById('addToProject');
        const environmentsDelete = document.getElementById('environmentsDelete');
        
        if (data > 0) {
            if (btnDownload) {
                setDownloadEnabled(true);
                btnDownload.style.opacity = 1;
            }
            if (environmentsDelete) {
                environmentsDelete.style.opacity = 1;
            }
            if (btnAddToProject) {
                btnAddToProject.style.opacity = 1;
            }
        } else {
            if (btnDownload) {
                setDownloadEnabled(false);
                btnDownload.style.opacity = 0.4;
            }
            if (environmentsDelete) {
                environmentsDelete.style.opacity = 0.4;
            }
            if (btnAddToProject) {
                btnAddToProject.style.opacity = 0.4;
            }
        }
    });

    const downloadSelectedEnvironments = (e) => {
        if (document.getElementById('environmentsForUpload')) {
            const environmentsForUpload = JSON.parse(document.getElementById('environmentsForUpload').innerText);
            if (environmentsForUpload.length === 0) {
                e.preventDefault();
                return;
            }
            BlockUI({
                title: "Please wait!",
                message: "Generating data...",
                position: "top-center",
                type: "info",
                duration: 2000,
                pauseOnHover: false
            });

            //let zip = new JSZip();
            let workbook = new ExcelJS.Workbook();
            workbook.creator = 'Eyesee web portal';
            workbook.lastModifiedBy = user.user_name;
            workbook.created = new Date();
            const worksheet = workbook.addWorksheet('List');
            const columns = [];
            const columnWidths = [15, 15, 15, 15, 15, 15, 50, 50, 50, 50, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
            ACCESSORS_VALUES_ENVIRONMENTS.forEach((entry, i) => {
                columns.push({
                    header: entry,
                    key: ACCESSORS_ENVIRONMENTS[i],
                    width: columnWidths[i]
                });
            });
            worksheet.columns = columns;
            worksheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffff0000' }                
            };
            worksheet.getRow(1).font = {
                name: 'Calibri',
                color: { argb: 'ffffff' },
                family: 2,
                bold: true
            };            

            const imagesToDownload = {};
            Object.keys(environmentsForUpload).forEach((key, i) => {
                let entry = environmentsForUpload[key];
                imagesToDownload[i] = entry.img;
                delete entry._id;
                worksheet.addRow(entry);                
            });

            workbook.xlsx.writeBuffer().then(buffer => {
                const blobEnvironments = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });                

                const json = JSON.stringify(imagesToDownload);
                const blob = new Blob([json], {
                    type: 'application/json'
                });
                const data = new FormData();
                data.append("file[]", blob, "environments.json");
                data.append("file[]", blobEnvironments, "environments.xlsx");

                let progDiv = document.createElement('div');
                progDiv.id = "downloadProgressContainer";
                progDiv.innerHTML = "<div id='downloadProgressBar'></div><span style='position: relative'>Downloading...</span>";
                document.body.appendChild(progDiv);

                axios.post(APIURL + "downloadFiles.php", data).then(res => {
                    if (res.status === 200 && res.data.msg === "Success") {
                        const fileData = new FormData();
                        fileData.set("file", res.data.filename);
                        axios.post(APIURL + "getf.php", fileData, {
                            responseType: 'blob',
                            onDownloadProgress: progressEvent => {
                                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                document.getElementById('downloadProgressBar').style.width = percentage + "%";
                            }
                        }).then((r) => {
                            const date = new Date();
                            let fileName = 'env_' + (project ? project.name + '_' : '_') + date.toLocaleDateString() + '_' + date.toLocaleTimeString() + '.zip';
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                                window.navigator.msSaveOrOpenBlob(new Blob([r.data],
                                        { type: 'application/zip' }
                                    ),
                                    fileName
                                );
                            } else {
                                const url = window.URL.createObjectURL(new Blob([r.data], { type: 'application/zip' }));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', fileName);
                                document.body.appendChild(link);
                                link.click();
                                document.getElementById('downloadProgressContainer').remove();
                                UnblockUI();
                                // delete file on server
                                axios.get(APIURL + 'project.php?action=delzip&file=' + res.data.filename).then(r => {});
                            }
                        });
                    } else {
                        document.getElementById('downloadProgressContainer').remove();
                        UnblockUIError(res.status);
                    }
                }).catch(err => {
                    document.getElementById('downloadProgressContainer').remove();
                    UnblockUIError(err);
                });
                
            });
            
        }
    };

    const addToCurrentProject = (e) => {
        const environmentsForUpload = JSON.parse(document.getElementById('environmentsForUpload').innerText);
        if (environmentsForUpload.length === 0) {
            e.preventDefault();
            return;
        }
        BlockUI();
        let environments = '';
        Object.keys(environmentsForUpload).forEach((key, i) => {
            environments += environmentsForUpload[key]._id + (i === environmentsForUpload.length - 1 ? '' : ',');
        });
        axios.get(APIURL + 'project.php?action=aen&id=' + project.id + '&envs=' + environments + '&t=' + token).then((response) => {
            const data = response.data;
            if (data.added.length > 0) {
                Notify({
                    title: "Success",
                    message: data.added.length + " environment(s) added to active project!",
                    type: "success"
                });
            }
            if (data.notadded.length > 0) {
                Notify({
                    title: data.notadded.length + " environment(s) not added as they already exist in project!",
                    message: data.notadded.join(' , '),
                    type: "warning"
                });
            }
            UnblockUI();
        }).catch((error) => {
            console.log(error);
            UnblockUIError(error);
        });
    };

    const deleteSelectedEnvironments = (e) => {
        if (document.getElementById('environmentsForUpload')) {
            const environmentsForUpload = JSON.parse(document.getElementById('environmentsForUpload').innerText);
            if (environmentsForUpload.length === 0) {
                e.preventDefault();
                return;
            }
            let environmentIDs = '';
            Object.keys(environmentsForUpload).forEach((key, i) => {
                let entry = environmentsForUpload[key];
                environmentIDs += entry._id;
                if (i < environmentsForUpload.length - 1) {
                    environmentIDs += ',';
                }
            });

            let confirmMsg = "Are you sure you want to delete selected environments? This will delete these environments from all projects!";
            let getURL = `${APIURL}environments.php?action=de&ids=${environmentIDs}&t=${token}`;
            
            if (!isAllEnvironments) {
                confirmMsg = "Are you sure you want to delete selected environments?";
                getURL = `${APIURL}project.php?action=den&id=${project.id}&envrs=${environmentIDs}&t=${token}`;
            }
                
            if (window.confirm(confirmMsg)) {
                BlockUI({
                    title: "Please wait!",
                    message: "Deleting data...",
                    position: "top-center",
                    type: "info",
                    duration: 2000,
                    pauseOnHover: false
                });
                axios.get(getURL).then(res => {
                    if (res.status === 200) {
                        Notify({
                            title: "Success",
                            message: "Environments deleted successfully!",
                            type: "success",
                            duration: 2000,
                            position: "top-center"
                        });
                        const environmentsIDsArray = environmentIDs.split(',');
                        const updatedEnvironments = allEnvironments.filter(environment => !environmentsIDsArray.includes(environment._id + ""));
                        projectEnvironmentsUpdated.dispatch({ data: updatedEnvironments, all: isAllEnvironments });
                        UnblockUI();
                    } else {
                        UnblockUIError(res.statusText);
                    }
                }).catch(err => {
                    UnblockUIError(err);
                });
            }
        }
    };
    
    return (
        <div className="App container mx-auto mt-3 font-thin">
            {
                !isUpload && allEnvironments && allEnvironments.length > 0 &&
                <SearchEnvironments onSearch={(data) => {
                    projectEnvironmentsUpdated.dispatch({ data: data, all: isAllEnvironments });
                }} />
            }
            {
                allEnvironments && allEnvironments.length > 0 &&
                <>
                <div className="relative pr-8">
                    <div className="absolute right-1" style={{ marginTop: '-50px', zIndex: '101' }}>
                        {
                            project != null && isAllEnvironments &&
                            <div id="addToProject" type="button" className={`inline-block p-2 mr-2 cursor-pointer text-pink-500 self-center text-center font-medium disabled:opacity-50 float-right active:text-gray text-center text-3xl ${downloadEnabled ? "cursor-pointer active:text-red" : "cursor-not-allowed"}`} onClick={(e) => { addToCurrentProject(e) }}><BiPlusCircle className="inline-block -mt-1 ml-1" /></div>
                        }
                        {
                            !isUpload &&
                            <div id="environmentsDownload" className={`inline-block p-2 mr-2 text-black self-center disabled:opacity-50 float-right text-center active:text-gray text-center text-3xl ${downloadEnabled ? "cursor-pointer active:text-gray" : "cursor-not-allowed"}`} onClick={(e) => { downloadSelectedEnvironments(e) }}><BiDownload className="inline-block -mt-1 ml-1" /></div>
                        }
                        {
                            !isUpload && (!isAllEnvironments || user.isAdmin) &&
                            <div id="environmentsDelete" className={`mr-2 p-2 inline-block text-black self-center disabled:opacity-50 float-right active:text-gray text-center text-3xl ${downloadEnabled ? "cursor-pointer active:text-gray" : "cursor-not-allowed"}`} onClick={(e) => { deleteSelectedEnvironments(e) }}><BiTrash className="inline-block -mt-1 ml-2" /></div>
                        }
                    </div>
                </div>
                <div>
                    <EnvironmentsTableComponent providedData={allEnvironments} />
                </div>
                </>
            }
        </div>
    );
};

export default Environments;