import useGlobalState from "@vighnesh153/use-global-state";
import { topClipDivStyle, bottomClipDivStyle, centeredContent } from '../styles';
import axios from 'axios';
import { APIURL, GetToken } from './Helper';

const LoginForm = ({ userLoggedIn, handleLogin }) => {
    const [user, setUser] = useGlobalState("user");

    if (userLoggedIn) {
        return null;
    }

    const token = GetToken();
    if (token && user == null) {
        axios.get(APIURL + 'project.php?action=ptkn&t=' + token).then((res) => {
            if (res.status === 200 && res.data.status === 'success') {
                let userData = res.data.data;
                userData.token = token;
                setUser(userData);
                document.getElementById('loginMsg').innerHTML = "<p class='animate three'><b><span>E</span><span>Y</span><span>E</span><span>S</span><span>E</span><span>E</span></b></p><div id='welcomeMsg'><div>Welcome</div><p><i>" + res.data.data.user_name + "</i></p></div>";
                setTimeout(() => {
                    handleLogin();
                }, 2000);
            } else if (res.status === 200 && res.data.status === 'token_error') {
                document.getElementById('loginMsg').innerHTML = "Token Error!";
                document.getElementById('loginMsg').style.color = "red";
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    
    return (
        <div className="w-screen h-screen">
            <div id="logginDiv" className="w-1/2 h-screen bg-gradient-to-r from-red-500 via-pink-500 to-purple-600 float-left">                
                <div style={topClipDivStyle}></div>
                <img alt="Eyesee logo" src="./img/logo.png" className="transform -translate-y-1/2 -translate-x-1/4 absolute w-1/4 left-1/4 top-1/22 block" />
                <div style={bottomClipDivStyle}></div>
            </div>
            <div className="w-1/2 h-screen relative float-right">
                <div style={centeredContent}>
                    <form id="loginForm">
                        {
                            token ? <h1 id="loginMsg" className="text-4xl mb-3 clear-both">Checking token...</h1> : <h1 className="text-4xl mb-3 clear-both">ERROR: No token!</h1>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
};

export default LoginForm;