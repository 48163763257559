import React, { useCallback, useRef, useState } from "react";
import { RgbaColorPicker } from "react-colorful";
import $ from "jquery";

import UseClickOutside from "./UseClickOutside";

export const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => { toggle(false); $("#settings").css("overflow", "auto"); } , []);
  UseClickOutside(popover, close);

  return (
    <div className="picker">
      <div
        className="swatch"
        style={{ backgroundColor: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')' }}
        onClick={() => { toggle(true); $("#settings").css("overflow", "unset"); }}
      />

      {isOpen && (
        <div className="popover" ref={popover}>
          <RgbaColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};