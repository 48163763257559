import { BiCaretDown } from "react-icons/bi";
import AsyncSearch from "./asyncsearch";
import { useState } from 'react';
import { BlockUI, UnblockUI, UnblockUIError, APIURL, GetToken } from "./Helper";
import axios from "axios";
import $ from "jquery";

const SearchShelves = ({ onSearch }) => {
  const [toggleAll, setToggleAll] = useState(false);
  const [categories, setCategories] = useState([]);

  const token = GetToken();

  const loadCategories = () => {
    if (categories.length === 0) {
      BlockUI();
      axios.get(APIURL + "categories.php?action=gacs&t=" + token)
        .then((res) => {
          setCategories(res.data);
          UnblockUI();
          setToggleAll(!toggleAll);
        })
        .catch((error) => {
          console.error(error);
          UnblockUIError(error);
        });
    } else {
      setToggleAll(!toggleAll);
    }
  };

  const toggleAdvancedSearch = () => {
    loadCategories();
  };

  let lvl1 = "";
  let lvl2 = "";
  let lvl3 = "";
  let lvl4 = "";
  let lvl5 = "";
  let lvl6 = "";

  let img = "";
  let stat = "";
  let stype = "";
  let acc = "";
  let len = "";
  let rows = "";
  let mat = "";
  let stg = "";
  let tech = "";
  let pnum = "";
  let ctry = "";
  let rtlr = "";
  let nsku = "";

  const findShelves = () => {    
    if ($("input[placeholder='Image Name']", "#mainSearch").val() !== "") {
      img = $("input[placeholder='Image Name']", "#mainSearch").val();
    }
    if (document.getElementById("advancedSearchContainer") != null) {
      let extraParams = {
        action: "findshelvs"
      };
      if (lvl1 !== "") {
        extraParams.lvl1 = lvl1;
      }
      if (lvl2 !== "") {
        extraParams.lvl2 = lvl2;
      }
      if (lvl3 !== "") {
        extraParams.lvl3 = lvl3;
      }
      if (lvl4 !== "") {
        extraParams.lvl4 = lvl4;
      }
      if (lvl5 !== "") {
        extraParams.lvl5 = lvl5;
      }
      if (lvl6 !== "") {
        extraParams.lvl6 = lvl6;
      }
      if (img !== "" && img !== undefined) {
        extraParams.img = img;
      }
      if (stat !== "" && stat !== undefined) {
        extraParams.stat = stat;
      }
      if (stype !== "" && stype !== undefined) {
        extraParams.stype = stype;
      }
      if (acc !== "" && acc !== undefined) {
        extraParams.acc = acc;
      }
      if (len !== "" && len !== undefined) {
        extraParams.len = len;
      }
      if (rows !== "" && rows !== undefined) {
        extraParams.rows = rows;
      }
      if (mat !== "" && mat !== undefined) {
        extraParams.mat = mat;
      }
      if (stg !== "" && stg !== undefined) {
        extraParams.stg = stg;
      }
      if (tech !== "" && tech !== undefined) {
        extraParams.tech = tech;
      }
      if (pnum !== "" && pnum !== undefined) {
        extraParams.pnum = pnum;
      }
      if (ctry !== "" && ctry !== undefined) {
        extraParams.ctry = ctry;
      }
      if (rtlr !== "" && rtlr !== undefined) {
        extraParams.rtlr = rtlr;
      }
      if (nsku !== "" && nsku !== undefined) {
        extraParams.nsku = nsku;
      }
      extraParams.t = token;
      if (Object.keys(extraParams).length > 1) {
        BlockUI();
        axios({
          method: "get",
          url: APIURL + "search.php",
          params: extraParams
        }).then((res) => {
          if (res.status === 200) {
            onSearch(res.data);
            UnblockUI();
          } else {
            UnblockUIError(res.statusText);
          }
        }).catch((error) => {
          UnblockUIError(error);
        });
      }
    } else {
      if (img !== "" && img !== undefined) {
        BlockUI();
        axios({
          method: "get",
          url: APIURL + "search.php",
          params: {
            action: "findshelvs",
            pname: img,
            t: token
          }
        }).then((res) => {
          if (res.status === 200) {
            onSearch(res.data);
            UnblockUI();
          } else {
            UnblockUIError(res.statusText);
          }
        }).catch((error) => {
          UnblockUIError(error);
        });
      } else {
        // get all shelves
        BlockUI();
        axios.get(APIURL + "shelves.php?action=gas&t=" + token).then((res) => {
          if (res.status === 200) {
            onSearch(res.data);
            UnblockUI();
          } else {
            UnblockUIError(res.statusText);
          }
        }).catch((error) => {
          console.error(error);
          UnblockUIError(error);
        });
      }
    }
  };

  const onImgSet = val => {
    img = val.selected[0];
  };
  const onStatSet = val => {
    stat = val.selected[0];
  };
  const onStypeSet = val => {
    stype = val.selected[0];
  };
  const onAccSet = val => {
    acc = val.selected[0];
  };
  const onLenSet = val => {
    len = val.selected[0];
  };
  const onRowsSet = val => {
    rows = val.selected[0];
  };
  const onMatSet = val => {
    mat = val.selected[0];
  };
  const onStgSet = val => {
    stg = val.selected[0];
  };
  const onTechSet = val => {
    tech = val.selected[0];
  };
  const onPnumSet = val => {
    pnum = val.selected[0];
  };
  const onCtrySet = val => {
    ctry = val.selected[0];
  };
  const onRtlrSet = val => {
    rtlr = val.selected[0];
  };
  const onNskuSet = val => {
    nsku = val.selected[0];
  };

  const changeLvl1 = e => {
    const selLvl2 = document.getElementById('ctg_lvl2');
    if (e.target.value !== "_select_") {
      lvl1 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl2.value = "_select_";
      selLvl2.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl1 = "";
      selLvl2.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl2 = e => {
    const selLvl3 = document.getElementById('ctg_lvl3');
    if (e.target.value !== "_select_") {
      lvl2 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl3.value = "_select_";
      selLvl3.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl2 = "";
      selLvl3.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl3 = e => {
    const selLvl4 = document.getElementById('ctg_lvl4');
    if (e.target.value !== "_select_") {
      lvl3 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl4.value = "_select_";
      selLvl4.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl3 = "";
      selLvl4.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl4 = e => {
    const selLvl5 = document.getElementById('ctg_lvl5');
    if (e.target.value !== "_select_") {
      lvl4 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl5.value = "_select_";
      selLvl5.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl4 = "";
      selLvl5.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl5 = e => {
    const selLvl6 = document.getElementById('ctg_lvl6');
    if (e.target.value !== "_select_") {
      lvl5 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl6.value = "_select_";
      selLvl6.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl5 = "";
      selLvl6.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl6 = e => {
    if (e.target.value === "_select_") {
      lvl6 = "";
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    } else {
      lvl6 = e.target.selectedOptions[0].text;//e.target.value;
    }
  };

  return (
    <div id="searchComponent">
      <div id="mainSearch" className="inline-block mb-1 relative z-10" style={{ lineHeight: '27px', width: '600px' }}>
        <span className="float-left"><AsyncSearch findBy="img" findWhat="shel" onSet={onImgSet} /></span>
        <input type="button" value="Find" className="w-28 rounded-full bg-white border-solid border border-gray-400 inline-block p-1 ml-2 cursor-pointer text-gray-800 self-center disabled:opacity-50 float-left" onClick={() => { findShelves() }} />
        <div className="rounded-full bg-white border-solid border border-gray-400 inline-block p-1 pr-2 pl-2 cursor-pointer ml-2 text-gray-800 self-center" onClick={() => { toggleAdvancedSearch() }}><span className="ml-1">Advanced search</span><BiCaretDown className="ml-2 inline" /></div>
      </div>
      {
        toggleAll &&
        <div id="advancedSearchContainer">
          <div className="clear-both mb-2 inline-block">
            <span className="ml-2 float-left z-20">
              <select id="ctg_lvl1" onChange={changeLvl1}>
                <option value="_select_">1st level...</option>
                {
                  categories.length &&
                  categories[0]["lvl1"].map(entry => (
                    <option value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl2" onChange={changeLvl2}>
                <option value="_select_">2nd level...</option>
                {
                  categories.length &&
                  categories[1]["lvl2"].map(entry => (
                    <option value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl3" onChange={changeLvl3}>
                <option value="_select_">3rd level...</option>
                {
                  categories.length &&
                  categories[2]["lvl3"].map(entry => (
                    <option value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span id="ctg_lvl4" className="ml-4 float-left">
              <select onChange={changeLvl4}>
                <option value="_select_">4th level...</option>
                {
                  categories.length &&
                  categories[3]["lvl4"].map(entry => (
                    <option value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl5" onChange={changeLvl5}>
                <option value="_select_">5th level...</option>
                {
                  categories.length &&
                  categories[4]["lvl5"].map(entry => (
                    <option value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl6" onChange={changeLvl6}>
                <option value="_select_">6th level...</option>
                {
                  categories.length &&
                  categories[5]["lvl6"].map(entry => (
                    <option value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
          </div>
          <div className="clear-both inline-block mb-2">
            <span className="ml-2 float-left"><AsyncSearch findBy="img" findWhat="shel" onSet={onImgSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="stat" findWhat="shel" onSet={onStatSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="stype" findWhat="shel" onSet={onStypeSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="acc" findWhat="shel" onSet={onAccSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="len" findWhat="shel" onSet={onLenSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="rows" findWhat="shel" onSet={onRowsSet} /></span>
          </div>
          <div className="clear-both inline-block">
            <span className="ml-2 float-left"><AsyncSearch findBy="mat" findWhat="shel" onSet={onMatSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="stg" findWhat="shel" onSet={onStgSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="tech" findWhat="shel" onSet={onTechSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="pnum" findWhat="shel" onSet={onPnumSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="ctry" findWhat="shel" onSet={onCtrySet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="rtlr" findWhat="shel" onSet={onRtlrSet} /></span>
          </div>
          <div className="clear-both mt-2 inline-block">
            <span className="ml-2 float-left"><AsyncSearch findBy="nsku" findWhat="shel" onSet={onNskuSet} /></span>
          </div>
        </div>
      }
    </div>
  );
}

export default SearchShelves;