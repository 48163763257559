import axios from 'axios';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export const APIURL = "https://api.test.pl.eyesee-collab.com/";
export const IMGURL = "https://media.test.pl.eyesee-collab.com/";
//export const APIURL = "php/";

// notification types: success, danger, info, default, warning
export const Notify = (msg) => {
    console.log(msg.duration);
    Store.addNotification({
        title: msg.title,
        message: msg.message,
        container: msg.position || "top-center",
        type: msg.type || "info",
        dismiss: {
            duration: msg.duration || 2000,
            pauseOnHover: msg.pauseOnHover || true
        }
    });
};

export const BlockUI = (msg) => {
    if (msg && msg.title) {
        Notify(msg);
    }
    let blocker = document.getElementById('blocker');
    blocker.style.display = "block";
    document.body.appendChild(blocker);
    if (document.getElementById('app_content')) {
        document.getElementById('app_content').style.filter = "blur(4px)";
    }
};

export const UnblockUI = (msg) => {
    if (msg && msg.title) {
        Notify(msg);
    }
    document.getElementById('blocker').style.display = "none";
    if (document.getElementById('app_content')) {
        document.getElementById('app_content').style.filter = "";
    }
};

export const UnblockUIError = (msg) => {
    UnblockUI({
        title: "Error!",
        message: msg,
        type: "danger"
    });
};

export const GetSortOrder = (prop) => {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    };
};

export const UpdateObject = (type, id, prop, value, proj) => {
    const token = GetToken();
    axios.get(APIURL + type + ".php?action=up&id=" + id + "&prop=" + prop + "&val=" + value + "&t=" + token + (proj != null ? "&proj=" + proj : "")).then((res) => {
        if (res.status === 200) {
            Notify({
                title: "Success!",
                message: "Updated successfully",
                type: "success"
            });
        }
    }).catch((err) => {
        UnblockUI(err);
    });
};

export const GetToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('t');
    return token;
};
