
import { useState, useCallback, useEffect } from 'react';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Dashboard';
import UploadFiles from './components/UploadFiles';
import { ReactNotifications } from 'react-notifications-component';
import useGlobalState from "@vighnesh153/use-global-state";
import 'react-notifications-component/dist/theme.css';
import Projects from './components/Projects';
import Categories from './components/categories';
import axios from 'axios';
import { BlockUI, UnblockUI, APIURL, UnblockUIError, GetToken } from './components/Helper';
import Products, { projectProductsUpdated } from './components/Products';
import Shelves, { projectShelvesUpdated } from './components/Shelves';
import Planograms, { projectPlanogramsUpdated } from './components/Planograms';
import Environments, { projectEnvironmentsUpdated } from './components/Environments';


const App = () => {

  const [user, setUser] = useGlobalState('user', null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState("projects");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [shelves, setShelves] = useState([]);
  const [planograms, setPlanograms] = useState([]);
  const [environments, setEnvironments] = useState([]);


  const token = GetToken();

  const [project] = useGlobalState("project", null);
  const [prodCols] = useGlobalState("prodCols", ["lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6", "pname"]);
  const [shelCols] = useGlobalState("shelCols", ["lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6", "img"]);
  const [enviCols] = useGlobalState("enviCols", ["img"]);
  const [planCols] = useGlobalState("planCols", ["lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6", "img"]);

  projectProductsUpdated.add((data) => {
    if (data.all) {
      loadProducts(data.data);
    }
  });
  projectShelvesUpdated.add((data) => {
    if (data.all) {
      loadShelves(data.data);
    }
  });
  projectPlanogramsUpdated.add((data) => {
    if (data.all) {
      loadPlanograms(data.data);
    }
  });
  projectEnvironmentsUpdated.add((data) => {
    if (data.all) {
      loadEnvironments(data.data);
    }
  });

  const handleUserLoggedIn = (uname) => {
    setUserLoggedIn(true);
  };

  const loadCategories = (data) => {
    BlockUI();
    axios.get(APIURL + "categories.php?action=gac&t=" + token)
      .then((res) => {
        if (categories !== res.data) {
          setCategories(res.data);
        }
        setCurrentPage("categories");
        UnblockUI();
      })
      .catch((error) => {
        console.error(error);
        UnblockUIError(error);
      });   
  };

  const loadProducts = (data) => {
    BlockUI();
    if (data) {
      if (products !== data) {
        setProducts(data);
        setCurrentPage("products");
      }
      UnblockUI();
    } else {
      axios.get(APIURL + "products.php?action=gap&t=" + token)
        .then((res) => {
          if (products !== res.data) {
            setProducts(res.data);
            setCurrentPage("products");
          }
          UnblockUI();
        })
        .catch((error) => {
          console.error(error);
          UnblockUIError(error);
        });
    }
  };

  const loadShelves = (data) => {
    BlockUI();
    if (data) {
      if (shelves !== data) {
        setShelves(data);
        setCurrentPage("shelves");
      }
      UnblockUI();
    } else {
      axios.get(APIURL + "shelves.php?action=gas&t=" + token)
        .then((res) => {
          if (res.data !== shelves) {
            setShelves(res.data);
            setCurrentPage("shelves");
          }
          UnblockUI();
        })
        .catch((error) => {
          console.error(error);
          UnblockUIError(error);
        });
    }
  };

  const loadPlanograms = (data) => {
    BlockUI();
    if (data) {
      if (data !== planograms) {
        setPlanograms(data);
        setCurrentPage("planograms");
      }
      UnblockUI();
    } else {
      axios.get(APIURL + "planograms.php?action=gap&t=" + token)
        .then((res) => {
          if (res.data !== planograms) {
            setPlanograms(res.data);
            setCurrentPage("planograms");
          }
          UnblockUI();
        })
        .catch((error) => {
          console.error(error);
          UnblockUIError(error);
        });
    }
  };

  const loadEnvironments = (data) => {
    BlockUI();
    if (data) {
      if (data !== environments) {
        setEnvironments(data);
        setCurrentPage("environments");
      }
      UnblockUI();
    } else {
      axios.get(APIURL + "environments.php?action=gae&t=" + token)
        .then((res) => {
          if (res.data !== environments) {
            setEnvironments(res.data);
            setCurrentPage("environments");
          }
          UnblockUI();
        })
        .catch((error) => {
          console.error(error);
          UnblockUIError(error);
        });
    }
  };

  if (user != null && user.token !== token) {
    setUserLoggedIn(false);
    setUser(null);
  }

  return (
    <div>
      <code id="activeProject" className="hidden"></code>
      <input type="text" id="allImagesDownload" className="hidden" />
      <ReactNotifications />
      <LoginForm userLoggedIn={userLoggedIn} handleLogin={handleUserLoggedIn} />
      {
        userLoggedIn &&
        <div id="app_content">
          <div style={{ height: '70px', backgroundImage: 'linear-gradient(90deg, #af2f68,#96548f,#ef4444)', overflow: 'hidden' }}>
            <div className="container mx-auto font-thin h-full">
              <img src="./img/logo_small.png" alt="Eyesee logo" style={{ height: "31px", marginTop: '22px', marginLeft: '50px', float: 'left' }} />
              <div style={{ display: 'flex', marginLeft: '2rem', float: 'left', lineHeight: '2.7', fontSize: '16px', fontWeight: '400' }}>
                <div className={`mt-3 mr-4 cursor-pointer ${currentPage === "dashboard" ? 'text-gray-50 font-bold underline' : 'text-gray-200'}`}>Dashboard</div>
                <div className={`mt-3 mr-4 cursor-pointer ${currentPage === "_1_" ? 'text-gray-50 font-semibold underline' : 'text-gray-300'}`}>Your projects</div>
                <div className={`mt-3 mr-4 cursor-pointer ${currentPage === "_2_" ? 'text-gray-50 font-semibold underline' : 'text-gray-300'}`}>Launch new</div>
                <div className={`mt-3 mr-4 cursor-pointer ${currentPage === "stimuli" ? 'text-gray-50 font-semibold underline' : 'text-gray-300'}`}>Stimuli</div>
                <div className={`mt-3 mr-4 cursor-pointer ${currentPage === "study" ? 'text-gray-50 font-semibold underline' : 'text-gray-300'}`}>Study designs</div>
                </div>
              </div>
            </div>
            <div className="App container mx-auto mt-3 font-thin" id="subMenu">
              <div className="float-left align-bottom md:leading-loose flex sm:space-x-2 md:space-x-6" style={{ marginLeft: '23px', fontSize: '15px', fontWeight: '500' }}>
                {
                  user.isAdmin &&
                  <div onClick={() => { setCurrentPage("productsUpload") }} className={`cursor-pointer ${currentPage === "productsUpload" ? 'text-pink-500' : 'text-gray-500'}`}>Upload</div>
                }
                <div onClick={() => { setCurrentPage("projects") }} className={`cursor-pointer ${currentPage === "projects" ? 'text-pink-500' : 'text-gray-500'}`}>Projects</div>
                {
                  user.isAdmin &&
                  <div onClick={() => { loadCategories() }} className={`cursor-pointer ${currentPage === "categories" ? 'text-pink-500' : 'text-gray-500'}`}>Categories</div>
                }
                <div onClick={() => { loadProducts() }} className={`cursor-pointer ${currentPage === "products" ? 'text-pink-500' : 'text-gray-500'}`}>All Products</div>
                <div onClick={() => { loadShelves() }} className={`cursor-pointer ${currentPage === "shelves" ? 'text-pink-500' : 'text-gray-500'}`}>All Shelves</div>
                <div onClick={() => { loadEnvironments() }} className={`cursor-pointer ${currentPage === "environments" ? 'text-pink-500' : 'text-gray-500'}`}>All Environments</div>
                <div onClick={() => { loadPlanograms() }} className={`cursor-pointer ${currentPage === "planograms" ? 'text-pink-500' : 'text-gray-500'}`}>All Planograms</div>
              </div>
              {
                project != null &&
                <span onClick={() => { setCurrentPage("projects") }} className="text-white float-right text-sm block mt-2 pl-2 pr-2 cursor-pointer" style={{ position: 'absolute', right: '40px', top: '14px' }}>Active project: <b>{project.name}</b></span>
              }
            </div>
            {
              currentPage === "projects" && <Projects />
            }
            {
              currentPage === "dashboard" && <Dashboard />
            }
            {
              currentPage === "productsUpload" &&
              <div>
                <br/>
                <UploadFiles />
              </div>
            }
            {
              currentPage === "categories" &&
              <div className="clear-both">
                <Categories data={categories} />
              </div>
            }
            {
              currentPage === "products" &&
              <div className="App container mx-auto mt-16 font-thin">
                <Products data={products} isAllProducts={true} />
              </div>
            }
            {
              currentPage === "shelves" &&
              <div className="App container mx-auto mt-16 font-thin">
                <Shelves data={shelves} isAllShelves={true} />
              </div>
            }
            {
              currentPage === "planograms" &&
              <div className="App container mx-auto mt-16 font-thin">
                <Planograms data={planograms} isAllPlanograms={true} />
              </div>
            }
            {
              currentPage === "environments" &&
              <div className="App container mx-auto mt-16 font-thin">
                <Environments data={environments} isAllEnvironments={true} />
              </div>
            }
        </div>
        }
        <div id="blocker">
          <section className="wrapper">
            <div className="spinner">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </div>
          </section>
        </div>
      </div>
  )
}

export default App;
