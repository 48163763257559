import { BiCaretDown } from "react-icons/bi";
import AsyncSearch from "./asyncsearch";
import { useState } from 'react';
import { BlockUI, UnblockUI, UnblockUIError, APIURL, GetToken } from "./Helper";
import axios from "axios";
import $ from "jquery";

const SearchEnvironments = ({ onSearch }) => {
  const [toggleAll, setToggleAll] = useState(false);

  const token = GetToken();

  const toggleAdvancedSearch = () => {
    setToggleAll(!toggleAll);
  };

  let img = "";
  let rtlr = "";
  let envr = "";
  let strt = "";
  let ctry = "";

  const findEnvironments = () => {
    if ($("input[placeholder='Image Name']", "#mainSearch").val() !== "") {
      img = $("input[placeholder='Image Name']", "#mainSearch").val();
    }
    if (document.getElementById("advancedSearchContainer") != null) {
        let extraParams = {
            action: "findenvrs"
        };        
        if (img !== "" && img !== undefined) {
            extraParams.img = img;
        }
        if (ctry !== "" && ctry !== undefined) {
            extraParams.ctry = ctry;
        }
        if (envr !== "" && envr !== undefined) {
            extraParams.envr = envr;
        }
        if (rtlr !== "" && rtlr !== undefined) {
            extraParams.rtlr = rtlr;
        }
        if (strt !== "" && strt !== undefined) {
            extraParams.strt = strt;
        }
        extraParams.t = token;
        if (Object.keys(extraParams).length > 1) {
            BlockUI();
            axios({
                method: "get",
                url: APIURL + "search.php",
                params: extraParams
            }).then((res) => {
            if (res.status === 200) {
                onSearch(res.data);
                UnblockUI();
            } else {
                UnblockUIError(res.statusText);
            }
            }).catch((error) => {
                UnblockUIError(error);
            });
        }
        } else {
        if (img !== "" && img !== undefined) {
            BlockUI();
            axios({
                method: "get",
                url: APIURL + "search.php",
                params: {
                    action: "findenvrs",
                    pname: img,
                    t: token
                }
            }).then((res) => {
                if (res.status === 200) {
                    onSearch(res.data);
                    UnblockUI();
                } else {
                    UnblockUIError(res.statusText);
                }
            }).catch((error) => {
                UnblockUIError(error);
            });
        } else {
            // get all environments
            BlockUI();
            axios.get(APIURL + "environments.php?action=gae&t=" + token).then((res) => {
                if (res.status === 200) {
                    onSearch(res.data);
                    UnblockUI();
                } else {
                    UnblockUIError(res.statusText);
                }
            }).catch((error) => {
                console.error(error);
                UnblockUIError(error);
            });
        }
    }
};

  const onImgSet = val => {
    img = val.selected[0];
  };
  const onRtlrSet = val => {
    rtlr = val.selected[0];
  };
  const onEnvrSet = val => {
    envr = val.selected[0];
  };
  const onStrtSet = val => {
    strt = val.selected[0];
  };
  const onCtrySet = val => {
    ctry = val.selected[0];
  };

  return (
    <div id="searchComponent">
      <div id="mainSearch" className="inline-block mb-1 relative z-10" style={{ lineHeight: '27px', width: '600px' }}>
        <span className="float-left"><AsyncSearch findBy="img" findWhat="envr" onSet={onImgSet} /></span>
        <input type="button" value="Find" className="w-28 bg-white border-solid border border-gray-400 rounded-full inline-block p-1 ml-2 cursor-pointer text-gray-800 self-center disabled:opacity-50 float-left" onClick={() => { findEnvironments() }} />
        <div className="rounded-full bg-white border-solid border border-gray-400 inline-block p-1 pr-2 pl-2 cursor-pointer ml-2 text-gray-800 self-center" onClick={() => { toggleAdvancedSearch() }}><span className="ml-1">Advanced search</span><BiCaretDown className="ml-2 inline" /></div>
      </div>
      {
        toggleAll &&
        <div id="advancedSearchContainer">
          <div className="clear-both">
            <span className="ml-2 float-left"><AsyncSearch findBy="strt" findWhat="envr" onSet={onStrtSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="envr" findWhat="envr" onSet={onEnvrSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="rtlr" findWhat="envr" onSet={onRtlrSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="ctry" findWhat="envr" onSet={onCtrySet} /></span>
          </div>
        </div>
      }
    </div>
  );
}

export default SearchEnvironments;