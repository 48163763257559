import { centeredContent, uploadFiles } from '../styles';
import { BiUpload } from 'react-icons/bi';
import axios from 'axios';
import { Store as noty } from 'react-notifications-component';
import ExcelJS from 'exceljs';
import { useState } from 'react';
import Products from './Products';
import Shelves from './Shelves';
import Planograms from './Planograms';
import { ACCESSORS, ACCESSORS_ENVIRONMENTS, ACCESSORS_PLANOGRAMS, ACCESSORS_SHELVES } from './DataFormat';
import { APIURL, UnblockUIError, UnblockUI, BlockUI, Notify, GetToken } from './Helper';
import { tableSelectionChanged } from './table/products';
import Environments from './Environments';

const UploadFiles = () => {

    const [objectsToUpload, setObjectsToUpload] = useState("none");
    const [productData, setProductData] = useState([]);
    const [shelvesData, setShelvesData] = useState([]);
    const [planogramsData, setPlanogramsData] = useState([]);
    const [environmentsData, setEnvironmentsData] = useState([]);
    const [filesForUpload, setFilesForUpload] = useState([]);
    const [maxFileSize, setMaxFileSize] = useState(0);
    const [maxFilesCount, setMaxFilesCount] = useState(0);

    console.log(noty);

    const token = GetToken();

    if (maxFileSize === 0) {
        axios.get(APIURL + '/stats.php').then(res => {
            if (res.status === 200) {
                setMaxFileSize(res.data.maxfilesize);
                setMaxFilesCount(res.data.maxfilesupload);
            } else {
                UnblockUIError(res.statusText);
            }
        }).catch(err => {
            UnblockUIError(err);
        });
    }

    const [xlsxType, setXlsxType] = useState("");
    const [accessors, setAccessors] = useState(null);
    let xlsxTypeTmp = "";
    let accessorsTmp = null;

    let uploadedFiles = [];

    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    let selectedFiles = [];
    let filesToUploadCount = 0;
    let totalFilesToUpload = 0;
    

    tableSelectionChanged.add((selectedRows) => {
        if (document.getElementById('selectedFilesInfo')) {
            document.getElementById('selectedFilesInfo').innerText = "Selected " + selectedRows + " files for upload.";
        }
    });

    const setUpFilesForUpload = (files) => {
        if (files.length > maxFilesCount) {
            Notify({
                title: "ERROR!",
                message: "Total number of files exceedes system limit of " + maxFilesCount + " files. Contact your system administrator about the issue.",
                type: "danger"
            });
        }
        for (let i = 0; i < files.length; ++i) {
            if (files[i].name.toLowerCase().endsWith(".xlsx")) {
                reader.readAsArrayBuffer(files[i]);
                /*eslint-disable */
                reader.onload = () => {
                    let data = [];
                    const buffer = reader.result;                    
                    let sheetName = "";
                    let headerRowIndex = -1;
                    workbook.xlsx.load(buffer).then(workbook => {
                        // first pass to determine what kind of xlsx is it,
                        // products, planograms, shelves or environments...
                        workbook.eachSheet((sheet, id) => {
                            sheet.eachRow((row, rowIndex) => {
                                if (rowIndex <= 2) {
                                    if (row.values.indexOf("EAN") > -1 && row.values.indexOf("ASIN") > -1 && row.values.indexOf("UPC") > -1) {
                                        xlsxTypeTmp = "products";
                                        sheetName = sheet.name;
                                        headerRowIndex = rowIndex;
                                        accessorsTmp = ACCESSORS;
                                    }
                                    else if (row.values.indexOf("Shelf length") > -1 && row.values.indexOf("Material") > -1) {
                                        xlsxTypeTmp = "shelves";
                                        sheetName = sheet.name;
                                        headerRowIndex = rowIndex;
                                        accessorsTmp = ACCESSORS_SHELVES;
                                    }
                                    else if (row.values.indexOf("Date") > -1 && row.values.indexOf("Country") > -1) {
                                        xlsxTypeTmp = "planograms";
                                        sheetName = sheet.name;
                                        headerRowIndex = rowIndex;
                                        accessorsTmp = ACCESSORS_PLANOGRAMS;
                                    }
                                    else if (row.values.indexOf("Environment type") > -1) {
                                        xlsxTypeTmp = "environments";
                                        sheetName = sheet.name;
                                        headerRowIndex = rowIndex;
                                        accessorsTmp = ACCESSORS_ENVIRONMENTS;
                                    }
                                }
                            });
                        });

                        setAccessors(accessorsTmp);
                        setXlsxType(xlsxTypeTmp);
                        
                        workbook.eachSheet((sheet, id) => {
                            if (sheet.name === sheetName) {
                                sheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {
                                    if (rowIndex > headerRowIndex) {
                                        let isRowEmpty = false;
                                        /*row.eachCell({ includeEmpty: false }, (e, i) => {
                                            if (e.value === null || e.value === undefined || e.value === "" || ('' + e.value).trim() === "") {
                                                isRowEmpty = true;
                                            }
                                        });*/
                                        if (!isRowEmpty) {
                                            const newDataEntry = {};
                                            row.eachCell({ includeEmpty: false }, (e, i) => {
                                                if (i > 0) {
                                                    newDataEntry[accessorsTmp[i - 1]] = e.value;
                                                }
                                            });

                                            let img = "";
                                            let imgExt = 'NO_EXT';
                                            if (xlsxTypeTmp === "products") {
                                                img = newDataEntry.pnpng.split(';');
                                                for (let i = 0; i < img.length; ++i) {
                                                    if (img[i].indexOf('.') > -1) {
                                                        imgExt = img[i].substring(img[i].lastIndexOf('.') + 1).toLowerCase();
                                                        if (["png", "jpg", "jpeg"].indexOf(imgExt) === -1) {
                                                            imgExt = 'NO_EXT';
                                                            break;
                                                        } else {
                                                            imgExt = "EXT_OK";
                                                        }
                                                    } else {
                                                        imgExt = 'NO_EXT';
                                                        break;
                                                    }
                                                }
                                            } else {
                                                img = newDataEntry.img;
                                                if (img && img.indexOf('.') > -1) {
                                                    imgExt = img.substring(img.lastIndexOf('.') + 1).toLowerCase();
                                                    if (["png", "jpg", "jpeg"].indexOf(imgExt) === -1) {
                                                        imgExt = 'NO_EXT';
                                                    } else {
                                                        imgExt = "EXT_OK";
                                                    }
                                                } else {
                                                    imgExt = 'NO_EXT';
                                                }
                                            }
                                            if (imgExt !== 'NO_EXT') {
                                                data.push(newDataEntry);
                                            }
                                            else {
                                                Notify({
                                                    title: "ERROR!",
                                                    message: "Invalid image data '" + img + "' !",
                                                    type: "danger"
                                                });
                                            }
                                        }
                                    }
                                });

                                const fixData = (data, dataType) => {
                                    let dataTypeToFix = ACCESSORS;
                                    switch (dataType) {
                                        case "products":
                                            dataTypeToFix = ACCESSORS;
                                            break;
                                        
                                        case "shelves":
                                            dataTypeToFix = ACCESSORS_SHELVES;                                            
                                            break;
                                        
                                        case "planograms":
                                            dataTypeToFix = ACCESSORS_PLANOGRAMS;
                                            break;
                                        
                                        case "environments":
                                            dataTypeToFix = ACCESSORS_ENVIRONMENTS;
                                            break;
                                    }

                                    for (let i = 0; i < data.length; ++i) {
                                        dataTypeToFix.forEach((e) => {
                                            if (data[i][e] == null) {
                                                data[i][e] = "/";
                                            }
                                        });
                                    }
                                };
                                
                                if (data.length > 0) {
                                    fixData(data, xlsxTypeTmp);
                                    switch (xlsxTypeTmp) {
                                        case "products":                                            
                                            setProductData(data);
                                            let pnames = [];
                                            data.forEach(e => {
                                                pnames.push(e.pname);
                                            });
                                            BlockUI();
                                            axios.post(APIURL + 'products.php', 'action=gbnms&t=' + token + '&nms=' + pnames).then(response => {
                                                if (response.data.length > 0) {
                                                    response.data.forEach(e => {
                                                        let input = document.querySelectorAll("input[value='" + e + "']");
                                                        if (input && input[0] && input[0].parentElement) {
                                                            input[0].parentElement.parentElement.style.backgroundColor = "#f8b1b3";
                                                        }
                                                    });
                                                }
                                                UnblockUI();
                                            }).catch(err => {
                                                UnblockUIError(err);
                                            });                                            
                                            break;
                                    
                                        case "shelves":
                                            setShelvesData(data);
                                            break;
                                    
                                        case "planograms":
                                            setPlanogramsData(data);
                                            break;
                                    
                                        case "environments":
                                            setEnvironmentsData(data);
                                            break;
                                    
                                        default:
                                    }
                                
                                    setObjectsToUpload(xlsxTypeTmp);
                                }
                                else {
                                    disableUploadButton();
                                }
                            }
                        });
                    });
                };
                /*eslint-enable */
            } else {
                const img = new Image();
                img.style.display = "none";
                if (files[i].size > maxFileSize) {
                    Notify({
                        title: "ERROR!",
                        message: "File\n" + files[i].name + "\nis too big.\nMax file size is " + maxFileSize + " bytes. Contact your system administrator about the issue.",
                        type: "danger"
                    });
                }
                if (files[i].size > 10485760) {                    
                    img.src = "img/file_too_large.jpg";
                } else {
                    img.src = URL.createObjectURL(files[i]);
                }
                img.id = files[i].name;
                document.body.appendChild(img);
            }
            selectedFiles.push(files[i]);                
        }
        setFilesForUpload(filesForUpload.concat(selectedFiles));
        enableUploadButton(selectedFiles.length);
    };
     
    // On file select (from the pop up)
    const onFileChange = event => {
        const files = event.target.files;        
        setUpFilesForUpload(files);
    };
      
    // On file upload (click the upload button)
    const onFileUpload = () => {
        document.getElementById('uploadBlocker').style.display = "block";

        console.log(xlsxType);

        const jsonString = document.getElementById(xlsxType + "ForUpload").innerText;
        const jsonObject = JSON.parse(jsonString);
        if (jsonObject.length === 0) {
            noty.addNotification({
                title: "Error",
                message: "No " + xlsxType + " selected.",
                container: "bottom-left",
                type: "danger",
                dismiss: {
                    duration: 1200,
                    pauseOnHover: true
                }
            });
            document.getElementById('uploadBlocker').style.display = "none";
            return;
        }

        const upCont = document.getElementById('uploadingProgressContainer');

        const imagesForUpload = []; // we will upload only images from products selected in the table
        const objectsNames = [];    // send objects names to service for checking if they already exist
        
        // check if all fields are there, add empty content if not
        jsonObject.forEach(element => {
            const name = element[xlsxType === "products" ? "pname" : "img"];
            if (name && name !== "" && objectsNames.indexOf(name) === -1) {
                objectsNames.push(element[xlsxType === "products" ? "pname" : "img"]);
            }
            accessors.forEach(a => {
                if (element[a] === undefined) {
                    element[a] = "";
                }
                if (a === (xlsxType === "products" ? "pnpng" : "img") && element[a] !== undefined) {
                    if (element[a].indexOf(";") > -1) {
                        const imgs = element[a].split(";");
                        imgs.forEach(img => {
                            imagesForUpload.push(img);
                            uploadedFiles.push(img);
                        });
                    } else {
                        imagesForUpload.push(element[a]);
                        uploadedFiles.push(element[a]);
                    }
                }
            });
        });

        const continueUpload = () => {
            filesToUploadCount = imagesForUpload.length + 1; // images + json file
            const proceedWithUpload = () => {
                upCont.style.display = "block";
                setObjectsToUpload("none");
                disableUploadButton();

                let uploadULR = "";
                let dataArray = null;
                switch (xlsxType) {
                    case "products":
                        uploadULR = "uploadProducts.php";
                        dataArray = productData;
                        break;
                
                    case "shelves":
                        uploadULR = "uploadShelves.php";
                        dataArray = shelvesData;
                        break;
                
                    case "planograms":
                        uploadULR = "uploadPlanograms.php";
                        dataArray = planogramsData;
                        break;
                
                    case "environments":
                        uploadULR = "uploadEnvironments.php";
                        dataArray = environmentsData;
                        break;
                
                    default:
                }

                /*eslint-disable */
                const uploadFile = (formData, fileName) => {
                    axios.post(APIURL + uploadULR, formData, {
                        onUploadProgress: progressEvent => {
                            if (document.getElementById('selectedFilesInfo')) {
                                document.getElementById('selectedFilesInfo').innerText = "Uploading " + filesToUploadCount + " files...";
                                const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                                if (document.getElementById(fileName + "_progress")) {
                                    document.getElementById(fileName + "_progress").style.width = progress + "%";
                                }
                            }
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            if ((res.data + "").indexOf("Fatal error") > -1) {
                                Notify({
                                    title: "ERROR!",
                                    message: "Fatal error: Out of memory. Image thumbnail is not created!",
                                    type: "danger",
                                    duration: 8000
                                });
                            }
                            --filesToUploadCount;
                            for (let i = 0; i < dataArray.length; ++i) {
                                if (xlsxType === "products") {
                                    if (dataArray[i].pnpng.indexOf(fileName) > -1) {
                                        dataArray.splice(i, 1);
                                    }
                                } else {
                                    if (dataArray[i].img === fileName) {
                                        dataArray.splice(i, 1);
                                        break;
                                    }
                                }
                            }
                            if (document.getElementById(fileName + "_progress")) {
                                // remove progressbar
                                document.getElementById(fileName + "_progress").parentElement.remove();
                            }
                            if (document.getElementById(fileName)) {
                                // remove image
                                document.getElementById(fileName).remove();
                            }
                            if (filesToUploadCount === 0 && document.getElementById('file')) {
                                upCont.style.display = "none";
                                document.getElementById('file').value = '';
                                document.getElementById('btnUploadFiles').style.display = 'inline-block';
                                document.getElementById('selectedFilesInfo').innerHTML = "&nbsp;";
                                document.getElementById('uploadBlocker').style.display = "none";
                                setObjectsToUpload(xlsxType);
                                noty.addNotification({
                                    title: "Success",
                                    message: "All files uploaded.",
                                    container: "bottom-left",
                                    type: "success",
                                    dismiss: {
                                        duration: 2200,
                                        pauseOnHover: true
                                    }
                                });
                                let allFilesCopy = filesForUpload.slice(0, filesForUpload.length);
                                uploadedFiles.forEach(fName => {
                                    for (let i = 0; i < allFilesCopy.length; ++i) {
                                        if (allFilesCopy[i].name === fName) {
                                            allFilesCopy.splice(i, 1);
                                            break;
                                        }
                                    }
                                });
                                setFilesForUpload(allFilesCopy);
                                switch (xlsxType) {
                                    case "products":
                                        setProductData(dataArray);

                                        break;
                            
                                    case "shelves":
                                        setShelvesData(dataArray);
                                        break;
                            
                                    case "planograms":
                                        setPlanogramsData(dataArray);
                                        break;
                            
                                    case "environments":
                                        setEnvironmentsData(dataArray);
                                        break;
                            
                                    default:
                                }
                                
                                if (dataArray.length === 0) {
                                    disableUploadButton();
                                } else {
                                    enableUploadButton();
                                }
                            }
                            if (filesToUploadCount > 0 && imagesForUpload.length > 0) {
                                generateUpload();
                            }
                        } else {
                            UnblockUIError("Failed to upload file: " + fileName);
                        }
                    }).catch(err => {
                        UnblockUIError(err);
                    });
                };
                /*eslint-enable */

                const generateUpload = () => {
                    const formData = new FormData();

                    let file = null;
                    let fileName = imagesForUpload.pop();

                    for (let j = 0; j < filesForUpload.length; ++j) {
                        if (filesForUpload[j].name === fileName) {
                            file = filesForUpload[j];
                            break;
                        }
                    }

                    formData.append("file[]", file, fileName);

                    const entryDiv = document.createElement("div");
                    entryDiv.style.cssText = "width: 740px; margin: 5px 10px 0px; padding: 0px 4px 4px; border-radius: 7px;";
                    entryDiv.innerHTML = "<div style='font-size: 11px; margin-right: 5px; margin-bottom: 3px width: 100%; clear: both; text-align: left;'>" + fileName + "</div><div id='" + fileName + "_progress' class='bar'></div>";
                    upCont.append(entryDiv);
                    
                    uploadFile(formData, fileName);
                };

                const jsonFormData = new FormData();

                const blob = new Blob([JSON.stringify(jsonObject)], { type: "application/json" });

                let xlsxFileName = "";
                for (let i = 0; i < filesForUpload.length; ++i) {
                    if (filesForUpload[i].name.toLowerCase().endsWith(".xlsx")) {
                        xlsxFileName = filesForUpload[i].name;
                        break;
                    }
                }
                const jsonFileName = xlsxFileName.replace("xlsx", "json");
                jsonFormData.append("file[]", blob, jsonFileName);
                uploadFile(jsonFormData, jsonFileName);

                if (imagesForUpload.length <= 14) {
                    // Create an object of formData
                    while (imagesForUpload.length > 0) {
                        generateUpload();
                    }
                } else {                    
                    // limit number of uploads to 10 at a time
                    let i = 0;
                    while (i < 14 && imagesForUpload.length > 0) {
                        generateUpload();
                        ++i;
                    }
                }
            };

            axios.post(APIURL + xlsxType + '.php', 'action=gimgs&t=' + token + '&nms=' + imagesForUpload).then(response => {
                if (response.data.length === 0) {
                    if (jsonObject.length < totalFilesToUpload - 1) {
                        if (window.confirm((totalFilesToUpload - 1) + ' objects are available but only ' + jsonObject.length + ' objects are selected for upload. Are you sure you want to proceed?')) {
                            proceedWithUpload();
                        } else {
                            document.getElementById('uploadBlocker').style.display = "none";
                        }
                    } else {
                        proceedWithUpload();
                    }
                } else {
                    if (window.confirm('Following images are already in the database:\n\n' +
                        response.data.join('\n') + 
                        '\n\nDo you want to proceed and overwrite existing images?')) {
                        proceedWithUpload();
                    } else {
                        UnblockUI();
                    }
                }
            }).catch(err => {
                UnblockUIError(err);
            });
        };

        // check if objects already exist, ask if they should be overwritten
        axios.post(APIURL + xlsxType + '.php', 'action=gbnms&t=' + token + '&nms=' + objectsNames).then(response => {
            if (response.data.length > 0) {
                if (window.confirm('Following ' + xlsxType + ' are already in the database:\n\n' +
                    response.data.join('\n') +
                    '\n\nDo you want to proceed and overwrite existing data?')) {
                    continueUpload();
                } else {
                    UnblockUI();
                }
            } else {
                continueUpload();
            }
        }).catch(err => {
            UnblockUIError(err);
        });
    };

    const enableUploadButton = (filesCount) => {
        document.getElementById('dropInfo').style.display = 'none';
        document.getElementById('dropArea').style.display = 'none';
        document.getElementById('dropDiv').style.display = 'none';
        document.getElementById('btnUploadFiles').style.display = "inline";
        document.getElementById('btnUploadFiles').addEventListener('click', () => onFileUpload);

        noty.addNotification({
            title: "Success",
            message: filesCount + ' files ready for upload.',
            container: "bottom-left",
            type: "success",
            dismiss: {
                duration: 1200,
                pauseOnHover: true
            }
        });
    };

    const disableUploadButton = () => {
        document.getElementById('dropInfo').style.display = 'block';
        document.getElementById('dropArea').style.display = 'block';
        document.getElementById('dropDiv').style.display = 'block';
        document.getElementById('btnUploadFiles').style.display = "none";
    };

    const onDragOver = (event) => {
        if (event.target.id === "dropArea") {
            event.stopPropagation();
            event.preventDefault();
            event.target.style.backgroundColor = '#ffa63e';
            event.target.style.border = "solid red 1px";
            event.dataTransfer.dropEffect = 'copy';
        }
    };
    const onDragLeave = (event) => {
        if (event.target.id === "dropArea") {
            event.stopPropagation();
            event.preventDefault();
            event.target.style.backgroundColor = '#eee';
            event.target.style.border = "dashed #000 1px";
        }
    };
    const onDrop = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.id === "dropArea") {            
            event.target.style.backgroundColor = '#eee';
            event.target.style.border = "dashed #000 1px";
        
            const files = event.dataTransfer.files;
            setUpFilesForUpload(files);
        }
    };

    return (
        <div>
            <input id="duplicatesList" className="hidden" type="text" value="" />
            <div id="uploadingProgressContainer">

            </div>
            <div style={centeredContent} id="uploadContent">
                <div style={uploadFiles}>
                    <div id="dropInfo">
                        <h1 className="text-3xl mt-10">Upload your files</h1>
                        <p className="mb-8 mt-10 text-gray-500 leading-5">
                            Note: Zip file needs to include master library xlsx/csv file and individual products images.<br/>
                            Checker below will alert you if any issues are identified and column will turn red.
                        </p>
                    </div>
                    <div id="dropDiv" className="border border-gray-400 border-dashed p-10 rounded-xl text-center relative">
                        <div id="dropArea" onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
                            <BiUpload className="text-6xl text-gray-400 self-center inline-block mb-5 -mt-6 pointer-events-none" />
                            <div className="self-center text-gray-600 mb-4 font-semibold pointer-events-none">
                                Drag'n'drop files here or
                            </div>
                        </div>
                        <div className="m-auto">
                            <input name="file[]" type="file" id="file" multiple className="custom-file-input" onChange={onFileChange}></input>
                        </div>
                        <div id="uploadBlocker" className="w-full h-full bg-gray-400 absolute inset-0 rounded-xl hidden overflow-hidden"></div>
                    </div>
                    <div className="w-full mt-5">
                        <div className="mt-4">
                            <input id="btnUploadFiles" type="button" className="w-1/2 rounded-full clear-both inline-block bg-green-500 p-4 cursor-pointer text-white self-center disabled:opacity-50 hidden active:bg-green-700" value="Upload" onClick={onFileUpload}></input>
                        </div>
                        <div id="selectedFilesInfo" className="mb-5 text-gray-400">&nbsp;</div>
                    </div>
                </div>
            </div>
            <div className="App container mx-auto mt-3 font-thin">
                {
                    objectsToUpload === "products" &&
                    <div>
                        <Products data={productData} isUpload={true} />
                    </div>
                }
                {
                    objectsToUpload === "shelves" &&
                    <div>
                        <Shelves data={shelvesData} isUpload={true} />
                    </div>
                }
                {
                    objectsToUpload === "planograms" &&
                    <div>
                        <Planograms data={planogramsData} isUpload={true} />
                    </div>
                }
                {
                    objectsToUpload === "environments" &&
                    <div>
                        <Environments data={environmentsData} isUpload={true} />
                    </div>
                }
            </div>
        </div>
    );
};

export default UploadFiles;