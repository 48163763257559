import React from 'react';
import { centeredContent, uploadFiles } from '../styles';
import axios from 'axios';
import useGlobalState from "@vighnesh153/use-global-state";
import { APIURL, IMGURL, BlockUI, UnblockUI, UnblockUIError, GetSortOrder, GetToken } from './Helper';
import $ from "jquery";
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import ProjectList from './projectList';
import { useState } from 'react';
import Products, { projectProductsUpdated } from './Products';
import Shelves, { projectShelvesUpdated } from './Shelves';
import Planograms, { projectPlanogramsUpdated } from './Planograms';
import Environments, { projectEnvironmentsUpdated } from './Environments';
import ShelvesDesigner, { projectShelvesDesignUpdated } from './ShelvesDesigner';
import ShelvesDesignsList from './shelvesDesignsList';

export default function Projects() {
    const [user, setUser, updateUser] = useGlobalState("user");
    const [project, setProject] = useGlobalState("project");
    const [projectProducts, setProjectProducts] = useState(null);
    const [projectShelves, setProjectShelves] = useState(null);
    const [projectPlanograms, setProjectPlanograms] = useState(null);
    const [projectEnvironments, setProjectEnvironments] = useState(null);
    const [projectList, setProjectList] = useState([]);
    const [shelfDesignsList, setShelfDesignsList] = useState([]);
    const [shelfTemplateList, setShelfTemplateList] = useState([]);
    const [shelfDesign, setShelfDesign] = useState(null);
    const [currentPage, setCurrentPage] = useState("products");

    const token = GetToken();

    let _urlVars = null;
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    _urlVars = vars;

    const getUrlVar = (varName) => {
        if (_urlVars[varName] != null) {
            return _urlVars[varName];
        }
        return null;
    };

    let backupDBvisible = false;
    if (getUrlVar("bkpdb") != null) {
        backupDBvisible = true;
    }

    let restoreDBvisible = false;
    if (getUrlVar("rstdb") != null) {
        restoreDBvisible = true;
    }

    let shouldLoadProjects = true;

    let projectName = {
        title: ""
    };
    if (project != null) {
        projectName.title = project.name;
    }

    projectProductsUpdated.add((data) => {
        if (!data.all) {
            setProjectProducts(null);
        }
    });
    projectShelvesUpdated.add((data) => {
        if (!data.all) {
            setProjectShelves(null);
            loadShelves();
        }
    });
    projectPlanogramsUpdated.add((data) => {
        if (!data.all) {
            setProjectPlanograms(null);
            loadPlanograms();
        }
    });
    projectEnvironmentsUpdated.add((data) => {
        if (!data.all) {
            setProjectEnvironments(null);
            loadEnvironments();
        }
    });
    projectShelvesDesignUpdated.add(() => {
        setShelfDesign(null);
        setCurrentPage("shelvesdesignerhome");
        UnblockUI();
    });

    const exitProject = () => {
        if (window.confirm('Are you sure?')) {
            setProject(null);
            shouldLoadProjects = true;
            setProjectList([]);
            setProjectProducts(null);
            setProjectEnvironments(null);
            setProjectShelves(null);
            setProjectPlanograms(null);
            setCurrentPage("projects");
        }
    };

    const backupDatabase = () => {
        BlockUI();

        const data = new FormData();
        data.append("action", "dbbackup");
        data.append("t", token);

        axios.post(APIURL + 'project.php', data, {
            responseType: 'blob'
        }).then(res => {
            if (res.status === 200) {
                console.log(res);
                const fileName = res.headers["content-disposition"].replace("attachment; filename=", "");
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                UnblockUI();
            } else {
                UnblockUIError(res.status);
            }
        }).catch(err => {
            UnblockUIError(err);
        });
    };

    const restoreDatabase = (e) => {
        const files = e.target.files;   
        const reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);
        // upload file
        reader.onload = function (e) {
            BlockUI();
            const data = new FormData();
            data.append("action", "dbrestore");
            data.append("t", token);
            data.append("file", files[0]);
            axios.post(APIURL + 'project.php', data).then(res => {
                if (res.status === 200 && res.data.status === 'ok') {
                    console.log(res);
                    UnblockUI();
                } else {
                    UnblockUIError(res.status);
                }
            }).catch(err => {
                UnblockUIError(err);
            });
        };
    };

    const loadProjects = () => {
        if (shouldLoadProjects && projectList.length === 0) {
            BlockUI();
            axios.get(APIURL + 'project.php?action=gap&t=' + token).then((response) => {
                setProjectList(response.data.sort(GetSortOrder("_id")));
                shouldLoadProjects = false;
                UnblockUI();
            }).catch((error) => {
                console.log(error);
                UnblockUIError(error);
            });
        }
    };

    const loadAllShelvesDesigns = () => {
        console.log('loadAllShelvesDesigns');
        if (project != null) {
            BlockUI();
            axios.get(APIURL + 'shelfdesign.php?action=gap&pid=' + project.id + '&t=' + token).then((response) => {
                setShelfDesignsList(response.data);
                axios.get(APIURL + 'shelftemplate.php?action=gat&pid=' + project.id + '&t=' + token).then((response) => {
                    setShelfTemplateList(response.data);
                    setCurrentPage("shelvesdesignerhome");
                    UnblockUI();
                }).catch((error) => {
                    console.log(error);
                    UnblockUIError(error);
                });
            }).catch((error) => {
                console.log(error);
                UnblockUIError(error);
            });
        }
    };

    const loadProducts = () => {
        setCurrentPage("products");
    };

    const loadShelves = () => {
        setCurrentPage("shelves");
        if (project != null && projectShelves == null) {
            BlockUI();
            axios.get(APIURL + 'project.php?action=gp&id=' + project.id + '&t=' + token).then(function (response) {
                const projectShelves = response.data.shel;
                if (projectShelves && projectShelves.length) {
                    if (projectShelves.length === 1 && projectShelves[0].trim() === "") {
                        UnblockUI();
                    } else {
                        axios.get(APIURL + 'shelves.php?action=gbids&ids=' + projectShelves.join() + '&t=' + token).then(function (response) {
                            const res = response.data;
                            setProjectShelves(res);
                            UnblockUI();
                        }).catch(function (error) {
                            console.log(error);
                            UnblockUIError(error);
                        });
                    }
                } else {
                    UnblockUI();
                }
            }).catch(function (error) {
                console.log(error);
                UnblockUIError(error);
            });
        }
    };

    const loadPlanograms = () => {
        setCurrentPage("planograms");
        if (project != null && projectPlanograms == null) {
            BlockUI();
            axios.get(APIURL + 'project.php?action=gp&id=' + project.id + '&t=' + token).then(function (response) {
                const projectPlanograms = response.data.plnr;
                if (projectPlanograms && projectPlanograms.length) {
                    if (projectPlanograms.length === 1 && projectPlanograms[0].trim() === "") {
                        UnblockUI();
                    } else {
                        axios.get(APIURL + 'planograms.php?action=gbids&ids=' + projectPlanograms.join() + '&t=' + token).then((response) => {
                            const res = response.data;
                            setProjectPlanograms(res);
                            UnblockUI();
                        }).catch(function (error) {
                            console.log(error);
                            UnblockUIError(error);
                        });
                    }
                } else {
                    UnblockUI();
                }
            }).catch(function (error) {
                console.log(error);
                UnblockUIError(error);
            });
        }
    };

    const loadEnvironments = () => {
        setCurrentPage("environments");
        if (project != null && projectEnvironments == null) {
            BlockUI();
            axios.get(APIURL + 'project.php?action=gp&id=' + project.id + '&t=' + token).then(function (response) {
                const projectEnvironments = response.data.envr;
                if (projectEnvironments && projectEnvironments.length) {
                    if (projectEnvironments.length === 1 && projectEnvironments[0].trim() === "") {
                        UnblockUI();
                    } else {
                        axios.get(APIURL + 'environments.php?action=gbids&ids=' + projectEnvironments.join() + '&t=' + token).then((response) => {
                            const res = response.data;
                            setProjectEnvironments(res);
                            UnblockUI();
                        }).catch(function (error) {
                            console.log(error);
                            UnblockUIError(error);
                        });
                    }
                } else {
                    UnblockUI();
                }
            }).catch(function (error) {
                console.log(error);
                UnblockUIError(error);
            });
        }
    };

    const loadShelvesDesigner = data => {
        setShelfDesign(data);
        UnblockUI();        
        BlockUI();
        axios.get(APIURL + 'project.php?action=gp&id=' + project.id + '&t=' + token).then((response) => {
            const _data = response.data;    
            const prjShelves = response.data.shel ? response.data.shel : response.data.shelf;
            //if (prjShelves && prjShelves.length) {
                if (prjShelves.length === 1 && prjShelves[0].trim() === "") {
                    UnblockUI();
                } else {
                    axios.get(APIURL + 'shelves.php?action=gbids&ids=' + prjShelves.join() + '&t=' + token).then(function (response) {
                        const res = response.data;
                        setProjectShelves(res);
                        //axios.get(APIURL + 'project.php?action=gp&id=' + project.id + '&t=' + token).then((response) => {
                            //const res = response.data;
                            setProjectProducts(_data.prod);
                            setCurrentPage("shelvesdesigner");
                            UnblockUI();
                        //}).catch(function (error) {
                        //    console.log(error);
                        //    UnblockUIError(error);
                        //});                               
                        
                    }).catch(function (error) {
                        console.log(error);
                        UnblockUIError(error);
                    });
                }
            //} else {
            //    UnblockUI();
            //}
        }).catch(function (error) {
            console.log(error);
            UnblockUIError(error);
        });
    };

    const toggleTemplateList = () => {
        $("#shelfTemplateSelector").toggleClass("hidden");
    };

    const onProjectLoad = (proj) => {
        setProject(proj);
    };

    const onProjectNameChange = (e) => {
        BlockUI();
        const newProjectDesc = {
            name: e.value,
            author: project.author,
            date: project.date,
            id: project.id,
            products: project.products,
            environments: project.environments,
            shelves: project.shelves,
            planograms: project.planograms
        };
        axios.get(APIURL + 'project.php?action=chk&name=' + e.value + '&t=' + token).then((response) => {
            if (response.status === 200 && response.data.length === 0) {
                axios.get(APIURL + 'project.php?action=upn&id=' + project.id + '&name=' + e.value + '&t=' + token).then((response) => {
                    if (response.status === 200) {
                        const res = response.data;
                        setProject(newProjectDesc);
                        UnblockUI({
                            title: "Success",
                            message: "Project name updated.",
                            type: "success"
                        });
                    } else {
                        UnblockUIError("Response status: " + response.status);
                    }
                }).catch((error) => {
                    console.log(error);
                    UnblockUIError(error);
                });
            } else {
                UnblockUIError("Project name already exists.");
                setProject(project);
            }
        }).catch((error) => {
            UnblockUIError(error);
        });
    };

    if (project != null && projectProducts == null) {
        BlockUI();
        axios.get(APIURL + 'project.php?action=gp&id=' + project.id + '&t=' + token).then((response) => {
            const projectProducts = response.data.prod;
            if (projectProducts && projectProducts.length) {
                projectProducts.forEach((e, i) => {
                    if (e === '') {
                        projectProducts.splice(i, 1);
                    }
                });
                if (projectProducts.length) {
                    //axios.get(APIURL + 'products.php?action=gbids&ids=' + projectProducts.join() + '&t=' + token).then((response) => {
                    //    const res = response.data;
                        setProjectProducts(projectProducts);
                        UnblockUI();
                    //}).catch(function (error) {
                    //    console.log(error);
                    //    UnblockUIError(error);
                    //});
                } else {
                    UnblockUI();
                }
            } else {
                UnblockUI();
            }
        }).catch(function (error) {
            console.log(error);
            UnblockUIError(error);
        });
    }

    return (
        <>
        {
            project == null &&
            <div style={centeredContent}>
                <div style={uploadFiles}>
                    <div>
                        <p id="backupDatabaseArea" className={`${backupDBvisible ? 'block' : 'hidden'}`}>
                            <button id="btnBackupDatabase" className="btn btn-primary" onClick={() => {
                                backupDatabase();
                            }}>Backup Database</button>
                        </p>
                        <p id="restoreDatabaseArea" className={`${restoreDBvisible ? 'block' : 'hidden'}`}>
                            <input type="file" id="restoreDatabase" className="form-control" onChange={(e) => {
                                restoreDatabase(e);
                            }} />
                        </p>
                        <p className="mb-2 mt-6 text-gray-500 leading-5 font-semibold">
                            <Accordion preExpanded={["a"]} onChange={(e) => {
                                if (e[0] !== "a") {
                                    loadProjects();
                                }
                            }}>
                                <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Create new project
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            <form id="createProjectForm">
                                                <input id="txtProjectName" style={{ width: "70%", borderRadius: "20px" }} type="text" placeholder="Enter project name..." /><br />
                                                <input type="button" className="w-1/4 rounded-full clear-both inline-block p-4 mt-6 cursor-pointer mb-6 text-white font-bold" style={{ background: '#333333' }} value="Create" onClick={() => {
                                                    const projectForm = document.getElementById("createProjectForm");
                                                    const pnameDOM = document.getElementById("txtProjectName");
                                                    const pname = pnameDOM.value.trim();
                                                    if (pname !== "") {
                                                        BlockUI();
                                                        axios.get(APIURL + 'project.php?action=chk&name=' + pname + '&t=' + token).then((response) => {
                                                            if (response.status === 200 && response.data.length === 0) {
                                                                shouldLoadProjects = true;
                                                                axios.get(APIURL + 'project.php?action=np&name=' + pname + '&auth=' + user.user_name + '&t=' + token).then((response) => {
                                                                    const aProj = {
                                                                        name: response.data.name,
                                                                        author: response.data.author,
                                                                        date: response.data.date,
                                                                        id: response.data._id,
                                                                        products: [],
                                                                        environments: [],
                                                                        shelves: [],
                                                                        planograms: []
                                                                    };
                                                                    setProject(aProj);
                                                                    document.getElementById('activeProject').innerText = JSON.stringify(aProj);
                                                                    UnblockUI();
                                                                }).catch((error) => {
                                                                    console.log(error);
                                                                    UnblockUIError(error);
                                                                });
                                                            } else {
                                                                UnblockUIError("Project name already exists.");
                                                                setProject(project);
                                                            }
                                                        }).catch((error) => {
                                                            UnblockUIError(error);
                                                        });
                                                    } else {
                                                        pnameDOM.setCustomValidity(pnameDOM.value.trim() === "" ? 'Please fill out this filed.' : '');
                                                        projectForm.reportValidity();
                                                    }
                                                }} />
                                            </form>
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="b">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Open or copy existing project
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <ProjectList data={projectList} onProjectLoad={onProjectLoad} />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </p>
                    </div>
                </div>
            </div>
        }
        {
            project != null &&
            <h1 className="pl-8 pt-2 pb-2 mt-2 mr-8 inline-block leading-8 2xl w-full align-left bg-gray-300 mb-2 clear-both">
                        <div className="float-left mr-8">Project name:&nbsp;<b id="loadedProjectName"> {
                            user.isAdmin ?
                            <EditText
                                type="text"
                                inline={true}
                                defaultValue={projectName.title}
                                onSave={onProjectNameChange}
                                style={{ width: '200px', padding: '1px 4px' }}
                            /> : <span>{projectName.title}</span>
                        }</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Created:&nbsp;{project.date}&nbsp;&nbsp;&nbsp;by&nbsp;{project.author}
                </div>
                <div className="ml-8 float-left">
                    <div onClick={() => { loadProducts() }} className={`cursor-pointer float-left rounded-full px-8 mr-1 ${currentPage === "products" ? 'text-gray-800 font-semibold bg-white' : 'text-gray-500'}`}>Products</div>
                    <div onClick={() => { loadShelves() }} className={`cursor-pointer float-left rounded-full px-8 mr-1 ${currentPage === "shelves" ? 'text-gray-800 font-semibold bg-white' : 'text-gray-500'}`}>Shelves</div>
                    <div onClick={() => { loadEnvironments() }} className={`cursor-pointer float-left rounded-full px-8 mr-1 ${currentPage === "environments" ? 'text-gray-800 font-semibold bg-white' : 'text-gray-500'}`}>Environments</div>
                    <div onClick={() => { loadPlanograms() }} className={`cursor-pointer hidden float-left rounded-full px-8 mr-1 ${currentPage === "planograms" ? 'text-gray-800 font-semibold bg-white' : 'text-gray-500'}`}>Planograms</div>
                    <div onClick={() => { loadAllShelvesDesigns() }} className={`cursor-pointer float-left rounded-full px-8 mr-1 ${currentPage === "shelvesdesignerhome" ? 'text-gray-800 font-semibold bg-white' : 'text-gray-500'}`}>Shelves designs</div>
                </div>
                <div className="ml-8 float-right">        
                    <div onClick={() => { exitProject() }} className="cursor-pointer float-right text-pink-500 pl-2 pr-2 mr-8 active:text-green-600 font-semibold">Close project</div>
                </div>
            </h1>
        }
        {
            currentPage === "products" && project != null && projectProducts != null &&
            <div>
                <Products data={projectProducts} isAllProducts={false} />
            </div>
        }
        {
            currentPage === "shelves" && project != null && projectShelves != null &&
            <div>
                <Shelves data={projectShelves} />
            </div>
        }
        {
            currentPage === "planograms" && project != null && projectPlanograms != null &&
            <div>
                <Planograms data={projectPlanograms} />
            </div>
        }
        {
            currentPage === "environments" && project != null && projectEnvironments != null &&
            <div>
                <Environments data={projectEnvironments} />
            </div>
        }
        {
            currentPage === "shelvesdesignerhome" &&
            <div style={centeredContent}>
                <div style={uploadFiles}>
                    <p className="mb-2 mt-6 text-gray-500 leading-5 font-semibold">
                        <Accordion preExpanded={["a"]} onChange={(e) => {
                            if (e[0] !== "a") {
                                loadAllShelvesDesigns();
                            }
                        }}>
                            <AccordionItem uuid="a">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Design new shelf
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p style={{ marginBottom: '20px' }}>
                                        <div><label><input type="checkbox" id="chkUseShelfTemplate" onChange={toggleTemplateList}></input>&nbsp;Use layout template</label></div>
                                        <div id="shelfTemplateSelector" style={{ marginTop: '15px' }} className="hidden">
                                            {
                                                shelfTemplateList && shelfTemplateList.length &&
                                                    shelfTemplateList.map(item => (
                                                        <div className="shelfTemplateDiv">
                                                        <img className="shelfTemplateThumb" src={IMGURL + "shelvtpltsimgs/" + item.name + "_thumb.jpeg"} data={item.data} name={item.name} onClick={(e) => {
                                                        $("img.shelfTemplateThumb").removeAttr("selected");
                                                        $("img.shelfTemplateThumb").removeClass("selected");
                                                        e.target.setAttribute("selected", true);
                                                        $(e.target).toggleClass("selected");
                                                        }} alt={item.name} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </p>
                                    <p>
                                        <form id="createShelfForm">
                                            <input id="txtShelfName" style={{ width: "70%", borderRadius: "20px" }} type="text" placeholder="Enter shelf design name..." /><br />
                                            <input type="button" className="w-1/4 rounded-full clear-both inline-block p-4 mt-6 cursor-pointer mb-6 text-white font-bold" style={{ background: '#333333' }} value="Create" onClick={() => {
                                                const createShelfForm = document.getElementById("createShelfForm");
                                                const sdnameDOM = document.getElementById("txtShelfName");
                                                const sdname = sdnameDOM.value.trim();
                                                if (sdname !== "") {
                                                    BlockUI();
                                                    axios.get(APIURL + 'shelfdesign.php?action=chk&name=' + sdname + '&pid=' + project.id + '&t=' + token).then((response) => {
                                                        if (response.status === 200 && response.data.length === 0) {
                                                            shouldLoadProjects = true;
                                                            axios.get(APIURL + 'shelfdesign.php?action=np&name=' + sdname + '&auth=' + user.user_name + '&pid=' + project.id + '&t=' + token).then((response) => {
                                                                setProjectShelves([]);
                                                                console.log(response.data);
                                                                if (document.getElementById("chkUseShelfTemplate").checked) {
                                                                    console.log($("img.shelfTemplateThumb.selected").length);
                                                                    if ($("img.shelfTemplateThumb.selected").length > 0) {
                                                                        response.data.data = $("img.shelfTemplateThumb.selected").attr("data");
                                                                    }
                                                                }
                                                                setShelfDesign(response.data);
                                                                setCurrentPage("shelvesdesigner");
                                                                UnblockUI();
                                                            }).catch((error) => {
                                                                console.log(error);
                                                                UnblockUIError(error);
                                                            });
                                                        } else {
                                                            UnblockUIError("Shelf design name already exists.");
                                                            //setProject(project);
                                                        }
                                                    }).catch((error) => {
                                                        UnblockUIError(error);
                                                    });                                                    
                                                } else {
                                                    sdnameDOM.setCustomValidity(sdnameDOM.value.trim() === "" ? 'Please fill out this filed.' : '');                               
                                                    createShelfForm.reportValidity();
                                                }
                                            }} />
                                        </form>
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="b">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Open or copy existing shelf design
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>                                
                                    <ShelvesDesignsList data={shelfDesignsList} onShelfDesignsLoad={loadShelvesDesigner} />                                
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </p>
                </div>
            </div>    
        }
        {
            currentPage === "shelvesdesigner" && project != null && projectProducts != null &&
                projectShelves != null && shelfDesign != null &&           
            <div id="shelfDesignerContainer">
                <ShelvesDesigner dataProducts={projectProducts} dataShelves={projectShelves} dataShelfDesign={shelfDesign} />     
            </div>
        }
        </>
    );
}
