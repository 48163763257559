import {
    BiCaretDown,
    BiFullscreen,
    BiExitFullscreen
} from 'react-icons/bi';
import { useState, useRef, useEffect } from 'react';
import React from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { ACCESSORS_PLANOGRAMS, ACCESSORS_VALUES_PLANOGRAMS, COLUMNS_PLANOGRAMS } from '../DataFormat';
import MiniSignal from 'mini-signals';
import useDraggableScroll from 'use-draggable-scroll';
import SimpleReactLightbox from 'simple-react-lightbox';

import {
    Styles
} from './index.style';
import { UpdateObject } from '../Helper';
import { SRLWrapper } from 'simple-react-lightbox';
import useGlobalState from "@vighnesh153/use-global-state";

// used to dispatch signals to this component from other components
export const tableSelectionChanged = new MiniSignal();
export const tableResize = new MiniSignal();
export const planogramPropertyUpdated = new MiniSignal();

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;
  
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);
  
        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const VisibleColumns = ({ allColumns, toggle, hideCheckboxes }) => {
    const nonHidableColumns = ["selection", "pic"];
    const [planCols, setPlanCols] = useGlobalState("planCols");

    const toggleAll = (e) => {
        document.getElementById('visibleColumnsContainer').querySelectorAll(".planogramColumn").forEach(el => {
            if (el !== e.target) {
                if (el.checked !== e.target.checked) {
                    el.click();
                }
            }
        });
    };

    const updateCols = (e, id) => {
        if (e.target.checked) {
            setPlanCols(planCols.concat(id));
        }
        else {
            setPlanCols(planCols.filter(col => col !== id));
        }
    };

    if (!toggle) {
        return null;
    }
    return (
        <div id="visibleColumnsContainer">
            <div className="float-left mr-4 mb-1 select-none">
                <label className="cursor-pointer inline-block p-1 pl-2 pr-2 border-solid rounded-md">
                    <input type="checkbox" onClick={toggleAll} />{' '}Toggle all
                </label>
            </div>
            {
                allColumns.map(column => (
                    nonHidableColumns.indexOf(column.id) === -1 /*!== "selection" && column.id !== "pnpng"*/ &&
                    <div key={column.id} className="float-left mr-4 mb-1 select-none">
                        <label className="cursor-pointer inline-block p-1 pl-2 pr-2 border-solid rounded-md">
                            <input className="planogramColumn" type="checkbox" {...column.getToggleHiddenProps()} onClick={(e) => { updateCols(e, column.id) }} />{' '}
                            {ACCESSORS_VALUES_PLANOGRAMS[ACCESSORS_PLANOGRAMS.indexOf(column.id)]}
                        </label>
                    </div>
                ))
            }
        </div>
    );
}

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [user] = useGlobalState("user");

    const nonEditables = ["lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6", "img"];

    const onChange = e => {
        if (id === "img") return;
        e.target.style.width = "100%";
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = (e) => {
        if (id === "img") return;
        e.target.style.width = "100%";
        updateData(index, id, value)
    };

    const onFocus = (e) => {
        if (id === "img") {
            e.preventDefault();
            return;
        }
        e.target.style.width = "100%";
        e.target.style.minWidth = "200px";
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    if (!user.isAdmin || nonEditables.indexOf(id) > -1) {
        return <input value={value} disabled />
    }
    return <input value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
};

// Be sure to pass our updateData and the skipPageReset option
function PlanogramsTable({ columns, data, updateData, skipPageReset, hideCheckboxes }) {
    const [toggleColumns, setToggleColumns] = useState(false);
    const [planCols] = useGlobalState("planCols");

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        allColumns,
        selectedFlatRows,
        state: {
            pageIndex,
            pageSize,
            selectedRowIds
        },
        setHiddenColumns
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // use the skipPageReset option to disable page resetting temporarily
            autoResetPage: !skipPageReset,
            // updateData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateData,
            initialState: { pageSize: 50 }
        },
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    );
    let hiddenCols = ["lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6", "img", "ctry", "date", "rtlr"];
    planCols.forEach(col => {
        if (hiddenCols.indexOf(col) !== -1) {
            hiddenCols.splice(hiddenCols.indexOf(col), 1);
        }
    });
    useEffect(() => {
        setHiddenColumns(hiddenCols);
    }, []);

    const [fullscreen, setFullscreen] = useState(false);

    const goFullscreen = (e) => {
        if (document.fullscreenElement == null) {
            document.getElementById('fullScreenTable').requestFullscreen().then(() => {
                setFullscreen(true);
                recalculateTableViewHeight();
            });
        } else {
            document.exitFullscreen().then(() => {
                setFullscreen(false);
                recalculateTableViewHeight();
            });
        }        
    };

    const recalculateTableViewHeight = () => {
        setTimeout(() => {
            const tVP = document.getElementById('tableFixHead');
            if (tVP) {
                const r = tVP.getBoundingClientRect();
                tVP.style.height = (document.body.clientHeight - r.y - 40) + "px";
            }
        }, 30);
    };
    document.addEventListener('resize', recalculateTableViewHeight);
    document.addEventListener('fullscreenchange', recalculateTableViewHeight);
    tableResize.add(recalculateTableViewHeight);

    setTimeout(_ => {
        console.log("onload");
        document.getElementById('searchComponent').appendChild(document.getElementById('visibleColsDiv'));
    }, 10);

    recalculateTableViewHeight();

    const ref = useRef(null);
    const { onMouseDown } = useDraggableScroll(ref);
    
    return (
        <div id="tableViewProducts" style={{ position: "relative" }}>
            <div id="topLineFiller"></div>
            <div id="fullScreenTable" className="bg-white">
                <SimpleReactLightbox>
                    <div ref={ref} onMouseDown={onMouseDown} id="tableFixHead" className="overflow-auto">
                        <SRLWrapper options={{
                            settings: { showThumbnailsButton: false, showThumbnails: false },
                            buttons: { showDownloadButton: false },
                            thumbnails: {
                                showThumbnails: false
                            }
                        }}>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>   
                        </SRLWrapper>             
                        <code id="planogramsForUpload" className="hidden">
                            {
                                JSON.stringify(selectedFlatRows.map(d => d.original))                             
                            } 
                        </code>
                        {
                            setTimeout(() => { tableSelectionChanged.dispatch(selectedFlatRows.length); }, 1)
                        }
                    </div>
                </SimpleReactLightbox>
                <div className="pagination">
                    <button className="roundButton active:bg-green-700" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button className="roundButton active:bg-green-700" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button className="roundButton active:bg-green-700" onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button className="roundButton active:bg-green-700" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                    <span className="pageOf">
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span className="pageOf">
                        | &nbsp;&nbsp;Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '70px', borderRadius: '5px', padding: '2px', border: 'solid 1px #a5a5a5' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        style={{ width: '100px', borderRadius: '5px', padding: '2px', border: 'solid 1px #a5a5a5' }}
                    >
                        {[5, 10, 20, 30, 40, 50, 100, 200, 500].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>{' '}
                    <div style={{ display: 'inline-block' }} id="visibleColsDiv">
                        <div style={{ border: '1px solid rgb(165, 165, 165)', borderRadius: '25px', verticalAlign: 'bottom', display: 'inline', padding: '7px 7px 8px 11px', background: 'white', color: 'rgb(153 153 153)', cursor: 'pointer', marginLeft: '0.5em' }} onClick={() => {
                            setToggleColumns(!toggleColumns);
                            recalculateTableViewHeight();
                        }}><span className="ml-1">Visible columns</span><BiCaretDown className="ml-2 inline" /></div>
                        <VisibleColumns toggle={toggleColumns} allColumns={allColumns} hideCheckboxes={hideCheckboxes} />
                    </div>
                    {/* <div title="Toggle fullscreen" id="tableFullscreenBtn" className="roundButton active:bg-green-700 ml-4" onClick={() => goFullscreen()}>
                        <BiFullscreen className={!fullscreen ? '' : 'hidden'} />
                        <BiExitFullscreen className={fullscreen ? '' : 'hidden'} />
                    </div>{' '} */}                    
                </div>
            </div>
        </div>
    );
}

function PlanogramsTableComponent({ providedData, hideCheckboxes }) {
    const [data, setData] = React.useState(providedData);
    const [originalData] = React.useState(data);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    if (providedData !== data) {
        setData(providedData);
    }

    const updateData = (rowIndex, columnId, value) => {
        setSkipPageReset(true);
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    if (document.getElementById('uploadContent') == null) {
                        if (row[columnId] !== value) {
                            UpdateObject("planograms", row._id, columnId, value);
                        }
                    }
                    
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row;
            })
        );
        // fire signal that row has changed
        setTimeout(() => { planogramPropertyUpdated.dispatch(value); }, 1);
    };

    React.useEffect(() => {
        setSkipPageReset(false)
    }, [data]);

    // can be used to reset data to original value
    const resetData = () => setData(originalData);

    return (
        <Styles>
            <PlanogramsTable
                columns={COLUMNS_PLANOGRAMS}
                data={data}
                updateData={updateData}
                skipPageReset={skipPageReset}
                hideCheckboxes={hideCheckboxes}
            />
        </Styles>
    );
}

export default PlanogramsTableComponent;