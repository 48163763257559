import { Fragment, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  ACCESSORS,
  ACCESSORS_VALUES,
  ACCESSORS_SHELVES,
  ACCESSORS_VALUES_SHELVES,
  ACCESSORS_PLANOGRAMS,
  ACCESSORS_VALUES_PLANOGRAMS,
  ACCESSORS_ENVIRONMENTS,
  ACCESSORS_VALUES_ENVIRONMENTS
} from "../DataFormat";
import { APIURL, GetToken } from "../Helper";
import 'react-bootstrap-typeahead/css/Typeahead.css';


const AsyncSearch = ({ findBy, findWhat, onSet }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
  
    const token = GetToken();
  
    const handleSearch = (query) => {
        setIsLoading(true);
  
        fetch(`${APIURL}/search.php?action=${findWhat}&by=${findBy}&str=${query}&page=1&limit=50&t=${token}`).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    if (data.names) {
                        console.log(options);
                        setOptions(data.names);
                        setIsLoading(false);
                    }
                });
            } else {
                console.log(response);
            }
        });
    };
  
    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;
  
    let accessors = null;
    let accessorsValues = null;
    switch (findWhat) {
      case "prod":
        accessors = ACCESSORS;
        accessorsValues = ACCESSORS_VALUES;
        break;
      
      case "shel":
        accessors = ACCESSORS_SHELVES;
        accessorsValues = ACCESSORS_VALUES_SHELVES;
        break;
      
      case "plnr":
        accessors = ACCESSORS_PLANOGRAMS;
        accessorsValues = ACCESSORS_VALUES_PLANOGRAMS;
        break;
      
      case "envr":
        accessors = ACCESSORS_ENVIRONMENTS;
        accessorsValues = ACCESSORS_VALUES_ENVIRONMENTS;
        break;
      
      default:
    }  
  
    return (
      <AsyncTypeahead
        filterBy={filterBy}
        id={"searchby_" + findBy}
        onChange={(selected) => {
            onSet({selected});
        }}
        isLoading={isLoading}
        minLength={3}
        onSearch={handleSearch}
        options={options}
        placeholder={[accessorsValues[accessors.indexOf(findBy)]]}
        renderMenuItemChildren={(option, props) => (
          <Fragment>            
            <span className="hover:bg-blue-300" style={{ width: '205px', display: 'block', fontSize: '12px', lineHeight: "23px" }}>{option}</span>
          </Fragment>
        )}
      />
    );
};
  
export default AsyncSearch;