import ExcelJS from 'exceljs';
import useGlobalState from "@vighnesh153/use-global-state";
import { ACCESSORS, ACCESSORS_VALUES } from './DataFormat';
import axios from 'axios';
import { useState } from 'react';
import ProductsTableComponent, { tableSelectionChanged } from './table/products';
import { BlockUI, UnblockUI, Notify, APIURL, UnblockUIError, GetSortOrder, GetToken } from './Helper';
import SearchProducts from './SearchProducts';
import { BiDownload, BiPlusCircle, BiTrash } from 'react-icons/bi';
import MiniSignal from 'mini-signals';

export const projectProductsUpdated = new MiniSignal();

const Products = ({ data, isAllProducts, isUpload }) => {
    const [user] = useGlobalState("user");
    const [project] = useGlobalState("project");
    const [downloadEnabled, setDownloadEnabled] = useState(false);
    const [allProducts, setAllProducts] = useState(null);

    const token = GetToken();
    
    if (data && data !== allProducts) {
        data.sort(GetSortOrder("pname"));
        setAllProducts(data);
    }
    
    tableSelectionChanged.add((data) => {
        const btnDownload = document.getElementById('productsDownload');
        const btnAddToProject = document.getElementById('addToProject');
        const productsDelete = document.getElementById('productsDelete');
        
        if (data > 0) {
            if (btnDownload) {
                setDownloadEnabled(true);
                btnDownload.style.opacity = 1;
            }
            if (productsDelete) {
                productsDelete.style.opacity = 1;
            }
            if (btnAddToProject) {
                btnAddToProject.style.opacity = 1;
            }
        } else {
            if (btnDownload) {
                setDownloadEnabled(false);
                btnDownload.style.opacity = 0.4;
            }
            if (productsDelete) {
                productsDelete.style.opacity = 0.4;
            }
            if (btnAddToProject) {
                btnAddToProject.style.opacity = 0.4;
            }
        }        
    });

    const deleteSelectedProducts = (e) => {
        if (document.getElementById('productsForUpload')) {
            const productsForUpload = JSON.parse(document.getElementById('productsForUpload').innerText);
            if (productsForUpload.length === 0) {
                e.preventDefault();
                return;
            }
            let productIDs = '';
            Object.keys(productsForUpload).forEach((key, i) => {
                let entry = productsForUpload[key];
                productIDs += entry._id;
                if (i < productsForUpload.length - 1) {
                    productIDs += ',';
                }
            });
            
            let confirmMsg = "Are you sure you want to delete selected products? This will delete these products from all projects!";
            let getURL = `${APIURL}products.php?action=dp&ids=${productIDs}&t=${token}`;

            if (!isAllProducts) {
                confirmMsg = "Are you sure you want to delete selected products from active project?";
                getURL = `${APIURL}project.php?action=dprod&id=${project.id}&prods=${productIDs}&t=${token}`;
            }

            if (window.confirm(confirmMsg)) {
                BlockUI({
                    title: "Please wait!",
                    message: "Deleting data...",
                    position: "top-center",
                    type: "info",
                    duration: 2000,
                    pauseOnHover: false
                });
                axios.get(getURL).then(res => {
                    if (res.status === 200) {
                        Notify({
                            title: "Success",
                            message: "Products deleted successfully!",
                            type: "success",
                            duration: 2000,
                            position: "top-center"
                        });
                        const productsIDsArray = productIDs.split(',');
                        const updatedProducts = allProducts.filter(product => !productsIDsArray.includes(product._id + ""));
                        console.log(updatedProducts);
                        console.log(isAllProducts);
                        projectProductsUpdated.dispatch({ data: updatedProducts, all: isAllProducts });
                        UnblockUI();
                    } else {
                        UnblockUIError(res.statusText);
                    }
                }).catch(err => {
                    UnblockUIError(err);
                });
            }            
        }
    };

    const dowloadSelectedProducts = (e) => {
        if (document.getElementById('productsForUpload')) {
            const productsForUpload = JSON.parse(document.getElementById('productsForUpload').innerText);
            if (productsForUpload.length === 0) {
                e.preventDefault();
                return;
            }
            BlockUI({
                title: "Please wait!",
                message: "Generating data...",
                position: "top-center",
                type: "info",
                duration: 2000,
                pauseOnHover: false
            });

            //let zip = new JSZip();
            let workbook = new ExcelJS.Workbook();
            workbook.creator = 'Eyesee web portal';
            workbook.lastModifiedBy = user.user_name;
            workbook.created = new Date();
            const worksheet = workbook.addWorksheet('List');
            const columns = [];
            const columnWidths = [15, 15, 15, 15, 15, 15, 50, 50, 50, 50, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
            ACCESSORS_VALUES.forEach((entry, i) => {
                columns.push({
                    header: entry,
                    key: ACCESSORS[i],
                    width: columnWidths[i]
                });
            });
            worksheet.columns = columns;
            worksheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffff0000' }                
            };
            worksheet.getRow(1).font = {
                name: 'Calibri',
                color: { argb: 'ffffff' },
                family: 2,
                bold: true
            };

            let workbookDS = new ExcelJS.Workbook();
            workbookDS.creator = "Eyesee web portal";
            workbookDS.created = new Date();
            const worksheetDS = workbookDS.addWorksheet('Sheet1');
            worksheetDS.columns = [
                { header: "CDB_stimuli_id", key: "cdb_stimuli_id", width: 20 },
                { header: "Product Short Name", key: "pname1", width: 20 },
                { header: "Product Full Name (SKU)", key: "pname2", width: 20 },
                { header: "Product Description 1", key: "pd1", width: 20 },
                { header: "Product Description 2", key: "pd2", width: 20 },
                { header: "Product Description 3", key: "pd3", width: 20 },
                { header: "Brand", key: "brnd", width: 20 },
                { header: "Sub-Brand", key: "sbrnd", width: 20 },
                { header: "Purpose", key: "prps", width: 20 },
                { header: "Client", key: "client", width: 20 },
                { header: "Unit of Measurement", key: "unit", width: 20 },
                { header: "Product Price", key: "price", width: 20 },
                { header: "Product Main Image", key: "pnpng", width: 20 },
                { header: "Video 360 View", key: "v360", width: 20 },
                { header: "Loop Video", key: "vloop", width: 20 },
                { header: "Product Category", key: "pcat", width: 20 },
                { header: "KPI 1", key: "kp1", width: 20 },
                { header: "KPI 2", key: "kp2", width: 20 },
                { header: "KPI 3", key: "kp3", width: 20 },
                { header: "KPI 4", key: "kp4", width: 20 },
                { header: "KPI 5", key: "kp5", width: 20 },
                { header: "Display Only", key: "dnly", width: 20 },
                { header: "VS_platform_product_id", key: "vsid", width: 20 },
                { header: "Available in bulk", key: "ablk", width: 20 }
            ];
            worksheetDS.addRow({
                cdb_stimuli_id: "Input stimuli_id",
                pname1: "(up to 64 characters)",
                pname2: "(up to 128 characters)",
                pd1: "(up to 255 characters)",
                pd2: "(up to 255 characters)",
                pd3: "(up to 255 characters)",
                brnd: "(up to 127 characters)",
                sbrnd: "(up to 127 characters)",
                prps: "(up to 255 characters)",
                client: "(up to 127 characters)",
                unit: "Unit of Measurement",
                price: "(float)",
                pnpng: "(only filename, i.e. \"deodorant.png\")",
                v360: "Video 360 View",
                vloop: "1 if true, otherwise empty",
                pcat: "(check Sheet 2 for mapping)",
                kp1: 1,
                kp2: 2,
                kp3: 3,
                kp4: 4,
                kp5: 5,
                dnly: "(only product image, no adding to cart)",
                vsid: "(do not edit contents of this column!)",
                ablk: "1 if true, otherwise empty"
            });
            worksheetDS.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f2f2f2' }                
            };
            worksheetDS.getRow(1).font = {
                name: 'Calibri',
                color: { argb: '000000' },
                family: 2,
                bold: true
            };

            const allImagesDownload = document.getElementById('allImagesDownload').value;
            const imagesToDownload = {};
            Object.keys(productsForUpload).forEach((key, i) => {
                let entry = productsForUpload[key];
                if (allImagesDownload.indexOf(entry._id) === -1) {
                    imagesToDownload[i] = entry.pnpng.split(';')[0];
                } else {
                    imagesToDownload[i] = entry.pnpng;
                }
                delete entry._id;
                worksheet.addRow(entry);
                worksheetDS.addRow({
                    cdb_stimuli_id: "",
                    pname1: entry.pname,
                    pname2: entry.pname,
                    pd1: "",
                    pd2: "",
                    pd3: "",
                    brnd: entry.brnd,
                    sbrnd: entry.sbrnd,
                    prps: "",
                    client: "",
                    unit: entry.unit,
                    price: entry.price,
                    pnpng: entry.pnpng.indexOf(";") > -1 ? entry.pnpng.split(";")[0] : entry.pnpng,
                    v360: "",
                    vloop: "",
                    pcat: "",
                    kp1: "",
                    kp2: "",
                    kp3: entry.dim,
                    kp4: "",
                    kp5: "",
                    dnly: "",
                    vsid: "",
                    ablk: ""
                });
            });

            workbook.xlsx.writeBuffer().then(buffer => {
                const blobProd = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                //zip.file("products.xlsx", blobProd);

                workbookDS.xlsx.writeBuffer().then(bufferDS => {
                    const blobDS = new Blob([bufferDS], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    //zip.file("products_ds.xlsx", blobDS);

                    const json = JSON.stringify(imagesToDownload);
                    const blob = new Blob([json], {
                        type: 'application/json'
                    });
                    const data = new FormData();
                    data.append("file[]", blob, "products.json");
                    data.append("file[]", blobProd, "products.xlsx");
                    data.append("file[]", blobDS, "products_ds.xlsx");

                    let progDiv = document.createElement('div');
                    progDiv.id = "downloadProgressContainer";
                    progDiv.innerHTML = "<div id='downloadProgressBar'></div><span style='position: relative'>Downloading...</span>";
                    document.body.appendChild(progDiv);

                    axios.post(APIURL + "downloadFiles.php", data).then(res => {
                        if (res.status === 200 && res.data.msg === "Success") {
                            const fileData = new FormData();
                            fileData.set("file", res.data.filename);
                            axios.post(APIURL + "getf.php", fileData, {
                                responseType: 'blob',
                                onDownloadProgress: progressEvent => {
                                    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                    document.getElementById('downloadProgressBar').style.width = percentage + "%";
                                }
                            }).then((r) => {
                                const date = new Date();
                                let fileName = 'prod_' + (project ? project.name + '_' : '_') + date.toLocaleDateString() + '_' + date.toLocaleTimeString() + '.zip';
                                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                                    window.navigator.msSaveOrOpenBlob(new Blob([r.data],
                                            { type: 'application/zip' }
                                        ),
                                        fileName
                                    );
                                } else {
                                    const url = window.URL.createObjectURL(new Blob([r.data], { type: 'application/zip' }));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', fileName);
                                    document.body.appendChild(link);
                                    link.click();
                                    document.getElementById('downloadProgressContainer').remove();
                                    UnblockUI();
                                    // delete file on server
                                    axios.get(APIURL + 'project.php?action=delzip&file=' + res.data.filename).then(r => {});
                                }
                            });
                        } else {
                            document.getElementById('downloadProgressContainer').remove();
                            UnblockUIError(res.status);
                        }
                    }).catch(err => {
                        document.getElementById('downloadProgressContainer').remove();
                        UnblockUIError(err);
                    });
                });
            });
            
        }
    };

    const addToCurrentProject = (e) => {
        const productsForUpload = JSON.parse(document.getElementById('productsForUpload').innerText);
        if (productsForUpload.length === 0) {
            e.preventDefault();
            return;
        }
        BlockUI();
        let prods = '';
        Object.keys(productsForUpload).forEach((key, i) => {
            prods += productsForUpload[key]._id + (i === productsForUpload.length - 1 ? '' : ',');
        });
        axios.get(APIURL + 'project.php?action=apr&id=' + project.id + '&prods=' + prods + '&t=' + token).then((response) => {
            const data = response.data;
            if (data.added.length > 0) {
                Notify({
                    title: "Success",
                    message: data.added.length + " product(s) added to active project!",
                    type: "success"
                });
            }
            if (data.notadded.length > 0) {
                Notify({
                    title: data.notadded.length + " product(s) not added as they already exist in project!",
                    message: data.notadded.join(' , '),
                    type: "warning"
                });
            }
            UnblockUI();
        }).catch((error) => {
            console.log(error);
            UnblockUIError(error);
        });
    };
    
    return (
        <div className="App container mx-auto mt-3 font-thin">
            {
                !isUpload && allProducts && allProducts.length > 0 &&
                <SearchProducts onSearch={(data) => {
                    projectProductsUpdated.dispatch({ data: data, all: isAllProducts });
                }} byNameOnly={false} />
            }
            <div className="relative pr-8">
                <div className="absolute right-1" style={{ marginTop: '-50px', zIndex: '101' }}>
                    {
                        project != null && isAllProducts &&
                        <div id="addToProject" type="button" className={`inline-block p-2 mr-2 cursor-pointer text-pink-500 self-center text-center font-medium disabled:opacity-50 float-right active:text-gray text-center text-3xl ${downloadEnabled ? "cursor-pointer active:text-red" : "cursor-not-allowed"}`} onClick={(e) => { addToCurrentProject(e) }}><BiPlusCircle className="inline-block -mt-1 ml-1" /></div>
                    }
                    {
                        !isUpload &&
                        <div id="productsDownload" className={`inline-block p-2 mr-2 text-black self-center disabled:opacity-50 float-right text-center active:text-gray text-center text-3xl ${downloadEnabled ? "cursor-pointer active:text-gray" : "cursor-not-allowed"}`} onClick={(e) => { dowloadSelectedProducts(e) }}><BiDownload className="inline-block -mt-1 ml-1" /></div>
                    }
                    {
                        !isUpload && isAllProducts &&
                        <div id="productsDelete" className={`mr-2 p-2 inline-block text-black self-center disabled:opacity-50 float-right active:text-gray text-center text-3xl ${downloadEnabled ? "cursor-pointer active:text-gray" : "cursor-not-allowed"}`} onClick={(e) => { deleteSelectedProducts(e) }}><BiTrash className="inline-block -mt-1 ml-2" /></div>
                    }
                </div>
            </div>
            {
                allProducts && allProducts.length > 0 &&
                <div>
                    <ProductsTableComponent isAllProducts={isAllProducts} providedData={allProducts} isUpload={isUpload} />
                </div>
            }
        </div>
    );
};

export default Products;