import { ShelvesDesignerStyles } from "./shelvesdesign.style";
import useGlobalState from "@vighnesh153/use-global-state";
import axios from 'axios';
import { useState } from 'react';
import { BlockUI, UnblockUI, Notify, APIURL, IMGURL, UnblockUIError, GetSortOrder, GetToken } from './Helper';
//import { BiDownload, BiPlusCircle, BiTrash } from 'react-icons/bi';
import MiniSignal from 'mini-signals';
import $ from "jquery";
import SimpleBar from 'simplebar';
import Draggable from 'draggable';
import { Resizable } from 'resizable-dom';
import { PopoverPicker } from "./PopoverPicker";
import "react-color-palette/lib/css/styles.css";
import { useDebouncyEffect } from "use-debouncy";
import 'jquery.splitter';
import 'jquery-contextmenu';
import 'jquery-contextmenu/dist/jquery.contextMenu.min.css';
import Popbox from "./Popbox";
import LightRuler from "light-ruler";
import tabs from "tabs";
import SearchProducts from "./SearchProducts";
import Switch from "react-switch";
import ExcelViewer from "./excel-viewer/ExcelViewer";
import html2canvas from "html2canvas";
import ExcelJS from 'exceljs';
import { ACCESSORS, ACCESSORS_VALUES } from './DataFormat';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { Progress } from 'uiw';

export const projectShelvesDesignUpdated = new MiniSignal();

const Preview = ({ meta }) => {
	const { name, percent, status } = meta
	return (
	  <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
		{name}, {Math.round(percent)}%, {status}
	  </span>
	);
};

const MultiFileUploader = () => {
	const getUploadParams = () => ({ url: APIURL + '/uploadProjectProduct.php' })
  
	const handleSubmit = (files, allFiles) => {
	  console.log(files.map(f => f.meta))
	  allFiles.forEach(f => f.remove())
	}
  
	return (
	  <Dropzone
		getUploadParams={getUploadParams}
		onSubmit={handleSubmit}
		PreviewComponent={Preview}
		inputContent="Drop Files (Custom Preview)"
		disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
	  />
	);
};

const ShelvesDesigner = ({ dataProducts, dataShelves, dataShelfDesign }) => {
    const [user] = useGlobalState("user");
	const [project] = useGlobalState("project");
	const [shelfProject] = useState(dataShelfDesign);
	const [allProducts, setAllProducts] = useState([]);
	const [pricesVisible, setPricesVisible] = useState(true);
	const [rulerVisible, setRulerVisible] = useState(true);

	const [verticalSpacing, setVerticalSpacing] = useState(3);
	const [horizontalSpacing, setHorizontalSpacing] = useState(3);

	const [allSplitters, setAllSplitters] = useState({});
	window.allSplitters = allSplitters;
	const [ruler, setRuler] = useState(null);
	
	const [activeCellColor, setActiveCellColor] = useState({ r: 85, g: 255, b: 105, a: 0.5 });
	const [inactiveCellColor, setInactiveCellColor] = useState({ r: 255, g: 255, b: 255, a: 0.0 });
	const [separatorColor, setSeparatorColor] = useState({ r: 0, g: 0, b: 0, a: 1 });
	const [linesColor, setLinesColor] = useState({ r: 0, g: 255, b: 0, a: 0.7 });

	window.activeCellColor = activeCellColor;
	window.separatorColor = separatorColor;

	console.log(dataProducts);

    const token = GetToken();
    
	if (dataProducts && dataProducts !== allProducts) {
		if (dataProducts.length > 0) {
			dataProducts.sort(GetSortOrder("pname"));
			setAllProducts(dataProducts);
		}
	}
	
	let workbookDS = null;
	
	let simplebar = null;
	let simplebarAllProducts = null;
	let shelvesSimplebar = null;

	let popboxProdID = null;
	let popboxTemplate = null;
	let popboxNewProduct = null;

	let contextMenuEntryID = null;

	let tmp_ContextMenuProdID = null;
	let contextMenuProdID = null;

	let tmp_ContextMenuShelfID = null;
	let contextMenuShelfID = null;

	let dragGhost = document.createElement("div");
	dragGhost.id = "drag-ghost";
	let lastMovedElID1 = null;
	let lastMovedElID2 = null;
	
	const roundTo = (num, scale) => {
		if (!("" + num).includes("e")) {
			return + (Math.round(num + "e+" + scale) + "e-" + scale);
		} else {
			let arr = ("" + num).split("e");
			let sig = "";
			if (+arr[1] + scale > 0) {
				sig = "+";
			}
			return + (Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
		}
	};

    const makeUUID = (length) => {
        let uuid = "";
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            uuid += characters.charAt(Math.floor(Math.random() * charactersLength));
        }  

        // .toUpperCase() here flattens concatenated strings to save heap memory space.
        return length ? uuid : uuid.toUpperCase();
    };

    const clearPx = (val) => {
        return parseInt(val.replace('px', ''));
    };

	const moveChildren = (oldParent, newParent) => {
		$(oldParent).children().appendTo(newParent);
	};
	
	const applyColors = () => {
		const acColor = $('#activeCellColorCont .swatch').css('background-color');
		const icColor = $("#inactiveCellColorCont .swatch").css('background-color');
		const scColor = $('#shelvesSeparatorColorCont .swatch').css('background-color');
		const lColor = $('#linesColorCont .swatch').css('background-color');
		$('.shelfCell').css('background-color', icColor);
		$('.splitter_panel').css('background-color', 'transparent');
		$('.activeCell').css('background-color', acColor);
		$('.splitter_panel .vsplitter').css('background', scColor);
		$('.splitter_panel .hsplitter').css('background', scColor);
		$('.ruler-line').css('background', lColor);
	};

	const toggleProductsPrices = () => {
		setPricesVisible(!pricesVisible);
		if (pricesVisible) {
			$('.prodPrice').hide();
		} else {
			$('.prodPrice').show();
		}
	};

	const toggleRuler = () => {
		setRulerVisible(!rulerVisible);
		if (rulerVisible) {
			$("#canvas-ruler-wrapper-my-ruler").hide();
		} else {
			$("#canvas-ruler-wrapper-my-ruler").show();
		}
	};

	const showActiveCellControls = (show) => {
		if (show === undefined) {
			show = true;
		}
		if (show) {
			toggleShelfButtonsEnabled(true);
		} else {
			toggleShelfButtonsEnabled(false);
		}
	};

	const onSpliterDrag = (event, orientation) => {
		showActiveCellControls();
		$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
			$(e).css("display", "block");
			if (e.offsetLeft + e.scrollWidth >= e.parentElement.offsetWidth) {
				$(e).css("display", "none");
			}
			if (e.offsetTop + e.scrollHeight >= e.parentElement.offsetHeight) {
				$(e).css("display", "none");
			}
		});
		$(event.target.parentElement).find("div.shelfCell").each((i, e) => {
			if (clearPx(orientation === "horizontal" ? e.style.height : e.style.width) < 30) {
				e.style.backgroundColor = "red";
				/* event.preventDefault();
				event.stopPropagation();
				return; */
			} else {
				const val = $("#inactiveCellColorCont .swatch").css('background-color');
				$('.splitter_panel').css('background-color', 'transparent');
				e.style.backgroundColor = val;
			}
		});
	};

	const splitCellVertical = () => {
		if ($("div.activeCell").length) {
			let shouldProceed = true;
			if ($("div.activeCell").find(".productImage").length > 0) {
				// eslint-disable-next-line no-restricted-globals
				shouldProceed = confirm("Shelf is not empty. Some products might be lost due to potential lack of space. Do you want to proceed?");
			}
			if (shouldProceed) {
				if ($("div.activeCell").height() < 60) {
					Notify({
						title: "Error",
						message: "Cell can not be divided, not enough space!",
						type: "danger",
						duration: 900,
						position: "top-center"
					});
					return;
				}
				let tmpDiv = document.createElement("div");
				tmpDiv.style.cssText = "position: absolute; top: 10000px; right: 10000px;";
				tmpDiv.style.width = $("div.activeCell").width() + "px";
				tmpDiv.style.height = $("div.activeCell").height() + "px";
				document.body.appendChild(tmpDiv);
				$("div.activeCell .product-shelf-entry").each((i, e) => {
					tmpDiv.appendChild(e);
				});
				const orientation = $("div.activeCell")[0]._orientation || "horizontal";
				$("div.activeCell").html("<div class='shelfCell'></div><div class='shelfCell'></div>");
				var splitter = $("div.activeCell").split({
					orientation: 'horizontal',
					limit: 50,
					/* onDragStart: event => {
						
					},
					onDrag: event => {
						onSpliterDrag(event, "horizontal");
					},
					onDragEnd: event => {
						$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
							if ($(e).css("display") === "none") {
								$(e).remove();
							}
						});
					} */
				});
				splitter.limit.leftUpper = splitter.limit.rightBottom = 10;
				splitter._id = makeUUID(8);
				allSplitters[splitter._id] = splitter;
				splitter._splitters[0][0].id = splitter._id;
				var newActiveCell1 = $("div.activeCell div.shelfCell")[0];
				var newActiveCell2 = $("div.activeCell div.shelfCell")[1];
				newActiveCell1.id = makeUUID(8);
				newActiveCell2.id = makeUUID(8);
				newActiveCell1._orientation = orientation;
				newActiveCell2._orientation = orientation;
				newActiveCell1._splitter = splitter;
				newActiveCell2._splitter = splitter;
				$("div.shelfCell").removeClass("activeCell");
				$(newActiveCell1).toggleClass("activeCell");

				$(".product-shelf-entry", tmpDiv).each((i, e) => {
					if (e.offsetLeft + e.scrollWidth < newActiveCell1.offsetWidth && e.offsetHeight < newActiveCell1.offsetHeight) {
						$(newActiveCell1).append(e);
					}
				});
				let leftMost = 100000;
				$(".product-shelf-entry", tmpDiv).each((i, e) => {
					if (e.offsetLeft - 2 < leftMost) {
						leftMost = e.offsetLeft - 2;
					}
				});
				$(".product-shelf-entry", tmpDiv).each((i, e) => {
					$(e).css("left", (e.offsetLeft - leftMost) + "px");
					if (e.offsetLeft + e.scrollWidth < newActiveCell2.offsetWidth && e.offsetHeight < newActiveCell2.offsetHeight) {						
						$(newActiveCell2).append(e);
					}
				});
				tmpDiv.remove();
			
				showActiveCellControls();
			
				applyColors();
			}
		} else {
			Notify({
				title: "Error",
				message: "No cell is selected!",
				type: "danger",
				duration: 900,
				position: "top-center"
			});
		}
	};

	const splitCellHorizontal = () => {
		if ($("div.activeCell").length) {
			let shouldProceed = true;
			if ($("div.activeCell").find(".productImage").length > 0) {
				// eslint-disable-next-line no-restricted-globals
				shouldProceed = confirm("Shelf is not empty. Some products might be lost due to potential lack of space. Do you want to proceed?");
			}
			if (shouldProceed) {
				if ($("div.activeCell").width() < 190) {
					Notify({
						title: "Error",
						message: "Cell can not be divided, not enough space!",
						type: "danger",
						duration: 900,
						position: "top-center"
					});
					return;
				}
				let tmpDiv = document.createElement("div");
				tmpDiv.style.cssText = "position: absolute; top: 10000px; right: 10000px;";
				tmpDiv.style.width = $("div.activeCell").width() + "px";
				tmpDiv.style.height = $("div.activeCell").height() + "px";
				document.body.appendChild(tmpDiv);
				$("div.activeCell .product-shelf-entry").each((i, e) => {
					tmpDiv.appendChild(e);
				});
				const orientation = $("div.activeCell")[0]._orientation || "horizontal";
				$("div.activeCell").html("<div class='shelfCell'></div><div class='shelfCell'></div>");
				let splitter = $("div.activeCell").split({
					orientation: 'vertical',
					limit: 50,
					/* onDragStart: event => {
						
					},
					onDrag: event => {
						onSpliterDrag(event, "vertical");
					},
					onDragEnd: event => {
						$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
							if ($(e).css("display") === "none") {
								$(e).remove();
							}
						});
					} */
				});
				splitter.limit.leftUpper = splitter.limit.rightBottom = 10;
				splitter._id = makeUUID(8);
				allSplitters[splitter._id] = splitter;
				splitter._splitters[0][0].id = splitter._id;
				let newActiveCell1 = $("div.activeCell div.shelfCell")[0];
				let newActiveCell2 = $("div.activeCell div.shelfCell")[1];
				newActiveCell1.id = makeUUID(8);
				newActiveCell2.id = makeUUID(8);
				newActiveCell1._orientation = orientation;
				newActiveCell2._orientation = orientation;
				newActiveCell1._splitter = splitter;
				newActiveCell2._splitter = splitter;
				$("div.shelfCell").removeClass("activeCell");
				$(newActiveCell1).toggleClass("activeCell");

				$(".product-shelf-entry", tmpDiv).each((i, e) => {
					if (e.offsetLeft + e.offsetWidth < newActiveCell1.offsetWidth && e.offsetHeight < newActiveCell1.offsetHeight) {
						$(newActiveCell1).append(e);
					}
				});
				let leftMost = 100000;
				$(".product-shelf-entry", tmpDiv).each((i, e) => {
					if (e.offsetLeft - 2 < leftMost) {
						leftMost = e.offsetLeft - 2;
					}
				});
				$(".product-shelf-entry", tmpDiv).each((i, e) => {
					$(e).css("left", (e.offsetLeft - leftMost) + "px");
					if (e.offsetLeft + e.offsetWidth < newActiveCell2.offsetWidth && e.offsetHeight < newActiveCell2.offsetHeight) {						
						$(newActiveCell2).append(e);
					}
				});
				tmpDiv.remove();

				showActiveCellControls();
		
				applyColors();
			}
		} else {
			Notify({
				title: "Error",
				message: "No cell is selected!",
				type: "danger",
				duration: 900,
				position: "top-center"
			});
		}
	};

	const cloneCell = () => {
		if ($("div.activeCell").length) {
			let activeCell = $("div.activeCell");
			let otherCell = activeCell.parent().find(".shelfCell").not(".activeCell");
			const isVertical = activeCell.parent().find('.vsplitter').length;
			if (isVertical) {
				// split vertically
				if ($("div.activeCell").length) {
					if (otherCell.width() <= activeCell.width()) {
						Notify({
							title: "Error",
							message: "Cell can not be clonned, not enough space!",
							type: "danger",
							duration: 900,
							position: "top-center"
						});
						return;
					}

					activeCell.removeClass("activeCell");
					otherCell.toggleClass("activeCell");

					$("div.activeCell").html("<div class='shelfCell'></div><div class='shelfCell'></div>");
					let splitter = $("div.activeCell").split({
						orientation: 'vertical',
limit: 50,
						position: activeCell.width(),
						/* onDrag: event => {
							onSpliterDrag(event, "vertical");
							showActiveCellControls();
						},
						onDragEnd: event => {
							$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
								if ($(e).css("display") === "none") {
									$(e).remove();
								}
							});
						} */
					});
					splitter.limit.leftUpper = splitter.limit.rightBottom = 10;
					splitter._id = makeUUID(8);
					allSplitters[splitter._id] = splitter;
					let newActiveCell1 = $("div.activeCell div.shelfCell")[0];
					let newActiveCell2 = $("div.activeCell div.shelfCell")[1];
					newActiveCell1.id = makeUUID(8);
					newActiveCell2.id = makeUUID(8);
					newActiveCell1._splitter = splitter;
					newActiveCell2._splitter = splitter;
					$("div.shelfCell").removeClass("activeCell");
					$(newActiveCell1).toggleClass("activeCell");
					
					showActiveCellControls();
					
					applyColors();
				}
			} else {
				// split horizontally
				if ($("div.activeCell").length) {
					if (otherCell.height() <= activeCell.height()) {
						Notify({
							title: "Error",
							message: "Cell can not be clonned, not enough space!",
							type: "danger",
							duration: 900,
							position: "top-center"
						});
						return;
					}

					activeCell.removeClass("activeCell");
					otherCell.toggleClass("activeCell");

					$("div.activeCell").html("<div class='shelfCell'></div><div class='shelfCell'></div>");
					let splitter = $("div.activeCell").split({
						orientation: 'horizontal',
limit: 50,
						position: activeCell.height(),
						/* onDrag: event => {
							onSpliterDrag(event, "horizontal");
							showActiveCellControls();
						},
						onDragEnd: event => {
							$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
								if ($(e).css("display") === "none") {
									$(e).remove();
								}
							});
						} */
					});
					splitter.limit.leftUpper = splitter.limit.rightBottom = 10;
					splitter._id = makeUUID(8);
					allSplitters[splitter._id] = splitter;
					let newActiveCell1 = $("div.activeCell div.shelfCell")[0];
					let newActiveCell2 = $("div.activeCell div.shelfCell")[1];
					newActiveCell1.id = makeUUID(8);
					newActiveCell2.id = makeUUID(8);
					newActiveCell1._splitter = splitter;
					newActiveCell2._splitter = splitter;
					$("div.shelfCell").removeClass("activeCell");
					$(newActiveCell1).toggleClass("activeCell");

					showActiveCellControls();
					
					applyColors();
				}
			}
		} else {
			Notify({
				title: "Error",
				message: "No cell is selected!",
				type: "danger",
				duration: 900,
				position: "top-center"
			});
		}
	};

	const deleteCell = () => {
		if ($("div.activeCell").length && $("div.activeCell")[0] !== $("#rootShelf")[0]) {
			var split = $("div.activeCell")[0]._splitter;
			var parent = $("div.activeCell")[0].parentElement;
			var activeCellID = $("div.activeCell").id;
			var otherCell = null;
			var nodesToRemove = [];
			var isSingleCell = false;
			var top = clearPx($("div.activeCell")[0].style.top);
			var left = clearPx($("div.activeCell")[0].style.left);
			if (parent.childElementCount < 3) {
				isSingleCell = true;
				var elToRemove = parent;
				split = parent._splitter;
				parent = parent.parentElement;
				elToRemove.remove();
			}
			if (!isSingleCell) {
				parent.childNodes.forEach(function (node) {
					if (node.classList.contains("vsplitter") || node.classList.contains("hsplitter") || node.classList.contains("activeCell")) {
						nodesToRemove.push(node);
					}
				});
			}
			
			while (nodesToRemove.length) {
				nodesToRemove.pop().remove();
			}

			otherCell = parent.querySelector(".shelfCell");
			delete allSplitters[split._id];
			split.destroy();
			
			if (otherCell.querySelectorAll(".shelfCell").length === 0) {
				parent._orientation = otherCell._orientation; 
				moveChildren(otherCell, parent);
				otherCell.remove();
			} else {
				const data = layoutToJSON(parent.id, otherCell.id);
				otherCell.remove();
				parent.innerHTML = "";
				rebuildShelf(JSON.stringify(data));
			}			
		} else {
			if ($("div.activeCell").length === 0) {
				Notify({
					title: "Error",
					message: "No cell is selected!",
					type: "danger",
					duration: 900,
					position: "top-center"
				});
			} else {
				Notify({
					title: "Error",
					message: "Can not delete root cell!",
					type: "danger",
					duration: 900,
					position: "top-center"
				});
			}
		}
		applyColors();
	};

	let cellContentCopyJSON = null;
	const copyCellContent = () => {
		if ($("div.activeCell").length) {
			var data = {
				width: $("div.activeCell")[0].offsetWidth,
				height: $("div.activeCell")[0].offsetHeight,
				data: cellContentToJSON($("div.activeCell")[0].id, $("div.activeCell")[0]._orientation)
			};
			var json = JSON.stringify(data);
			cellContentCopyJSON = json;
			Notify({ message: "Cell content copied to clipboard", type: "success", duration: 900, position: "top-center" });
		} else {
			Notify({ message: "No cell selected", type: "warning", duration: 900, position: "top-center" });
		}
	};

	const cellContentToJSON = (cellID, orientation) => {
		var data = {
			"id": cellID,
			"orientation": orientation,
			"entries": []
		};
		$("#" + cellID).find(".product-shelf-entry").each((i, e) => {
			const images = e.querySelectorAll(".productImage");
			const positions = [];
			images.forEach((image, i) => {
				positions.push({
					"left": clearPx(image.style.left),
					"bottom": clearPx(image.style.bottom)
				});
			});
			data.entries.push({
				"left": e.offsetLeft,
				"top": e.offsetTop,
				"bottom": clearPx(e.style.bottom),
				"width": e.offsetWidth,
				"height": e.offsetHeight,
				"pid": e.getAttribute("pid"),
				"price": e.querySelector(".prodPrice").innerText,
				"image": e.querySelector(".productImage").src,
				"imgW": e.querySelector(".productImage").offsetWidth,
				"imgH": e.querySelector(".productImage").offsetHeight,
				"positions": positions
			});
		});
		return data;
	};

	const pasteCellContent = (data) => {
		if (data == null) {
			data = cellContentCopyJSON;
		}
		if (data == null) {
			Notify({ message: "No cell content to paste", type: "warning", duration: 900, position: "top-center" });
			return;
		}
		const content = JSON.parse(data);
		data = content.data;
		if ($("div.activeCell").length) {
			if ($("div.activeCell .product-shelf-entry").length > 0) {
				Notify({ message: "Cell is not empty!", type: "warning", duration: 900, position: "top-center" });
				return;
			}
			if ($("div.activeCell")[0]._orientation !== data.orientation) {
				Notify({ message: "Cell orientation does not match!", type: "warning", duration: 900, position: "top-center" });
				return;
			}
			if ($("div.activeCell").width() >= content.width && $("div.activeCell").height() >= content.height) {
				var cellID = $("div.activeCell")[0].id;
				var cell = $("#" + cellID)[0];
				var entries = data.entries;
				entries.forEach((e, i) => {
					var entry = document.createElement("div");// $("#" + entryID)[0];
					entry.id = makeUUID(8);
					entry.setAttribute("pid", e.pid);
					entry.className = "product-shelf-entry";
					entry.style.left = e.left + "px";
					entry.style.top = e.top + "px";
					entry.style.width = e.width + "px";
					entry.style.height = e.height + "px";

					const prodPrice = document.createElement("div");
					prodPrice.className = "prodPrice";
					prodPrice.innerText = e.price;
					if (!pricesVisible) {
						prodPrice.style.display = "none";
					}
					entry.append(prodPrice);

					e.positions.forEach((p, i) => {
						const image = document.createElement("img");
						image.className = "productImage";
						image.src = e.image;
						image.style.position = "absolute";
						image.style.left = p.left + "px";
						image.style.bottom = p.bottom + "px";
						image.style.width = e.imgW + "px";
						image.style.height = e.imgH + "px";
						entry.append(image);
					});
					
					cell.appendChild(entry);

					if (data.orientation === "horizontal") {
						entry.style.top = "";
						entry.style.bottom = e.bottom + "px";
						enableHorizontalSort(entry);
						addEntryButtons(entry, "horizontal");
					} else {
						enableVerticalSort(entry);
						addEntryButtons(entry, "vertical");
					}
				});
			} else {
				Notify({
					message: "Cell size should be the same or larger then the original one!",
					type: "danger",
					duration: 900,
					position: "top-center"
				});
			}
		}
	};

	const changeAlignment = () => {
		if ($("div.activeCell").length && $("div.activeCell")[0] !== $("#rootShelf")[0]) {
			let shouldProceed = true;
			if ($("div.activeCell div.product-shelf-entry").length > 1) {
				// eslint-disable-next-line no-restricted-globals
				shouldProceed = confirm("Changing alignment will remove all products in the cell. Do you want to proceed?");
			}
			if (shouldProceed) {
				$("div.activeCell").empty();
				let align = $("div.activeCell")[0]._orientation;
				if (align === "horizontal") {
					align = "vertical";
				} else {
					align = "horizontal";
				}
				$("div.activeCell")[0]._orientation = align;
				$("#shelfAlignSVG").css("transform", "rotate(" + (align === "horizontal" ? 90 : 180) + "deg)");
				applyColors();
			}
		}
	};

	const addEntryButtons = (div, orientation) => {
		if (orientation === "horizontal") {
			const floatDiv = document.createElement("div");
			floatDiv.className = "floatCtrlsDiv";
			div.append(floatDiv);
			const btnDelete = new Image();
			btnDelete.src = "./img/trash.png";
			btnDelete.className = "delete-product-from-shelf";
			btnDelete.addEventListener("click", (e) => {
				let cell = e.currentTarget.parentElement.parentElement;
				let leftMost = e.currentTarget.parentElement.parentElement.offsetLeft;
				let width = e.currentTarget.parentElement.parentElement.offsetWidth;
				$("div.product-shelf-entry", cell.parentElement).each((i, e) => {
					if (e.offsetLeft > leftMost) {
						$(e).css("left", "-=" + (width + horizontalSpacing) + "px");
					}
				});
				cell.remove();
			});
			floatDiv.append(btnDelete);

			const btnStackOnTop = document.createElement("div");
			btnStackOnTop.className = "prodStackOnTop";
			btnStackOnTop.addEventListener("click", (e) => {
				let parent = e.currentTarget.parentElement.parentElement;
				let imgCount = parent.getElementsByClassName("productImage").length;
				let origImg = parent.getElementsByClassName("productImage")[0];

				const width = origImg.offsetWidth;
				const height = (origImg.offsetHeight * imgCount) + (3 * imgCount);

				const addImageOnTop = () => {
					if (document.querySelector(".activeCell").offsetHeight > height + origImg.offsetHeight + 3) {
						origImg.style.bottom = "0px";
						origImg.style.left = "0px";
						origImg.style.position = "absolute";
						const onTopImg = new Image();
						onTopImg.src = origImg.src;
						onTopImg.className = "productImage";
						onTopImg.style.cssText = "width: " + width + "px; height: " + origImg.offsetHeight + "px; left: 0px; position: absolute; bottom: " + (height + 3) + "px";
						parent.appendChild(onTopImg);
						parent.style.height = (height + origImg.offsetHeight) + "px";
						parent.style.width = width + "px";
					} else {
						Notify({ message: "Not enough space on shelf!", type: "warning", duration: 900, position: "top-center" });
					}
				};

				if (imgCount === 1) {
					addImageOnTop();
				} else {					
					if (origImg.style.left === parent.getElementsByClassName("productImage")[1].style.left) {
						//Notify({ message: "Image is already stacked!", type: "warning", duration: 900, position: "top-center" });	
						addImageOnTop();
						return;
					}
					
					// eslint-disable-next-line no-restricted-globals
					if (confirm("Cell has more than one product image. Extra images will be deleted if you stack on top. Continue?")) {
						while (imgCount > 1) {
							parent.removeChild(parent.lastChild);
							imgCount = parent.getElementsByClassName("productImage").length;
						}
						addImageOnTop();
					}
				}
			});
			floatDiv.append(btnStackOnTop);

			const btnPlus = document.createElement("div");
			btnPlus.className = "prodPlus";
			btnPlus.addEventListener("click", (e) => {
				let parent = e.currentTarget.parentElement.parentElement;
				let imgCount = parent.getElementsByClassName("productImage").length;
				let origImg = parent.getElementsByClassName("productImage")[0];

				const width = clearPx(origImg.style.width);
				const height = clearPx(origImg.style.height);

				const totalWidth = $("div.activeCell").width();
				if (10 + clearPx(parent.style.width) + clearPx(parent.style.left) > totalWidth) {
					Notify({ message: "Cell is full!", type: "warning", duration: 900, position: "top-center" });
					return;
				}

				if (imgCount > 1) {
					if (origImg.style.left === parent.getElementsByClassName("productImage")[1].style.left) {
						Notify({ message: "Not available. Images are stacked!", type: "warning", duration: 900, position: "top-center" });	
						return;
					}
				}

				const newImg = new Image();
				newImg.src = origImg.src;
				newImg.className = "productImage";
				newImg.style.cssText = "width: " + width + "px; height: " + height + "px; left: " + (imgCount * 10) + "px; position: absolute; bottom: 0px";
				parent.append(newImg);
				parent.style.width = (width + (imgCount * 10)) + "px";

				$("div.activeCell div.product-shelf-entry").each((i, e) => {
					if (e.offsetLeft > parent.offsetLeft) {
						$(e).css("left", "+=" + 10 + "px");
						const mostLeft = clearPx($(e)[0].style.left) + clearPx($(e)[0].style.width);
						//console.log(mostLeft, totalWidth);
						if (mostLeft > totalWidth) {
							$(e).remove();
						}
					}
				});
			});
			floatDiv.append(btnPlus);

			const btnMinus = document.createElement("div");
			btnMinus.className = "prodMinus";
			btnMinus.addEventListener("click", (e) => {
				let parent = e.currentTarget.parentElement.parentElement;
				let imgCount = parent.querySelectorAll(".productImage").length;

				if (imgCount > 1) {
					let isStacked = false;
					
					//if (imgCount > 2) {
						if (Math.abs(parent.querySelectorAll(".productImage")[0].offsetTop - parent.querySelectorAll(".productImage")[1].offsetTop) >= parent.querySelectorAll(".productImage")[0].offsetHeight) {
							isStacked = true;
						}
					//}

					$(".productImage", parent).last().remove();

					if (!isStacked) {
						$("div.activeCell div.product-shelf-entry").each((i, e) => {
							if (e.offsetLeft > parent.offsetLeft) {
								$(e).css("left", "-=" + 10 + "px");
							}
						});
					}

					imgCount = parent.querySelectorAll(".productImage").length;
					let origImg = parent.querySelectorAll(".productImage")[0];

					const width = clearPx(origImg.style.width);
					let height = clearPx(origImg.style.height);
					
					if (isStacked) {
						height = ((height * imgCount) + (imgCount * 2) - 2);
						if (imgCount === 1) {
							height -= 2;
						}
						imgCount = 1;
					}
					parent.style.width = (width + ((imgCount - 1) * 10)) + "px";
					parent.style.height = height + "px";
				}
			});
			floatDiv.append(btnMinus);
		} else {
			const floatDiv = document.createElement("div");
			floatDiv.className = "floatCtrlsDiv";
			div.append(floatDiv);
			const btnDelete = new Image();
			btnDelete.src = "./img/trash.png";
			btnDelete.className = "delete-product-from-shelf";
			btnDelete.addEventListener("click", (e) => {
				const parent = e.currentTarget.parentElement.parentElement;
				const height = parent.offsetHeight;
				const parentCell = parent.parentElement;
				const parentWidth = parent.offsetWidth;
				const parentLeft = clearPx(parent.style.left);
				const parentTop = parent.offsetTop;

				// find widest product
				let columns = {};
				$("div.product-shelf-entry", parentCell).each((i, e) => {
					if (clearPx(e.style.top) <= 5) {
						columns[clearPx(e.style.left)] = {
							widestProduct: e.offsetWidth,
							allEntries: [e]
						};
					}
				});		
				$("div.product-shelf-entry", parentCell).each((i, e) => {
					if (clearPx(e.style.left) >= parentLeft) {
						if (columns[clearPx(e.style.left)].allEntries.indexOf(e) === -1) {
							columns[clearPx(e.style.left)].allEntries.push(e);
							columns[clearPx(e.style.left)].allEntries.forEach(e => {
								if (e.offsetWidth > columns[clearPx(e.style.left)].widestProduct) {
									columns[clearPx(e.style.left)].widestProduct = e.offsetWidth;
								}
							});
						}
					}
				});

				let isColumnCleared = false;
				let columnFound = false;
				let widest = columns[clearPx(parent.style.left)].widestProduct;
				const oldWidest = widest;
				Object.keys(columns).forEach(key => {
					if (!columnFound) {
						const col = columns[key];
						const entries = col.allEntries;
						if (clearPx(entries[0].style.left) === clearPx(parent.style.left)) {
							columnFound = true;
							entries.forEach((e, i) => {
								if (e === parent) {
									entries.splice(i, 1);
								}
							});
							if (entries.length === 0) {
								isColumnCleared = true;
								Object.keys(columns).forEach(newKey => {
									if (parseInt(newKey) > parseInt(key)) {
										columns[newKey].allEntries.forEach(e => {
											e.style.left = (clearPx(e.style.left) - parentWidth) + "px";
										});
									}
								});
							} else {
								// find new widest product
								widest = 0;
								entries.forEach(e => {
									if (e.offsetWidth > widest) {
										e.style.marginLeft = "0px";
										widest = e.offsetWidth;
									}
								});
								entries.forEach(e => {
									if (clearPx(e.style.left) === clearPx(parent.style.left)) {
										if (e.offsetWidth <= widest) {
											e.style.marginLeft = ((widest - e.offsetWidth) / 2) + "px";
										}
										if (e.offsetTop > parentTop + 4) {
											e.style.top = (clearPx(e.style.top) - height - 2) + "px";
										}
									}
								});
							}
						}
					}
				});

				parent.remove();

				if (!isColumnCleared) {
					let shouldFindNewWidest = true;
					columns[clearPx(parent.style.left)].allEntries.forEach(e => {
						if (oldWidest === e.offsetWidth) {
							shouldFindNewWidest = false;
						}
					});
					if (shouldFindNewWidest) {
						columns[clearPx(parent.style.left)].allEntries.forEach(e => {
							if (oldWidest > e.offsetWidth) {
								widest = (oldWidest - e.offsetWidth);
							}
						});
					}
				}

				if (oldWidest > widest) {
					let prevWidest = 0;
					Object.keys(columns).forEach(key => {
						if (parseInt(key) > parentLeft) {
							const col = columns[key];
							const entries = col.allEntries;
							if (entries.length > 0) {
								entries.forEach(e => {
									e.style.left = (clearPx(e.style.left) - widest) + "px";
									if (e.offsetWidth > prevWidest) {
										prevWidest = e.offsetWidth;
									}
								});
							}
						}
					});
				}				
			});
			floatDiv.append(btnDelete);
			
			const btnPlus = document.createElement("div");
			btnPlus.className = "prodPlus";
			// verticaly flip the plus button
			btnPlus.style.transform = "rotate(90deg)";
			btnPlus.addEventListener("click", (e) => {
				let parent = e.currentTarget.parentElement.parentElement;
				let imgCount = parent.querySelectorAll(".productImage").length;
				let origImg = parent.querySelector('.productImage');

				const width = origImg.width; // clearPx(origImg.style.width);
				const height = origImg.height;// clearPx(origImg.style.height);

				const totalHeight = $("div.activeCell").height();
				if (10 + clearPx(parent.style.height) + clearPx(parent.style.top) > totalHeight) {
					Notify({ message: "Cell is full!", type: "warning", duration: 900, position: "top-center" });
					return;
				}

				if (clearPx(parent.style.top) + (height + (imgCount * 10)) < $("div.activeCell").height()) {
					const newImg = new Image();
					newImg.src = origImg.src;
					newImg.className = "productImage";
					newImg.style.cssText = "width: " + width + "px; height: " + height + "px; top: " + (imgCount * 10) + "px; position: absolute;";
					parent.append(newImg);
					parent.style.height = (height + (imgCount * 10)) + "px";
				}

				$("div.activeCell div.product-shelf-entry").each((i, e) => {
					if (clearPx(parent.style.left) === clearPx(e.style.left) && e.offsetTop > parent.offsetTop) {
						$(e).css("top", "+=" + 10 + "px");
						const mostTop = clearPx($(e)[0].style.top) + clearPx($(e)[0].style.height);
						if (mostTop > totalHeight) {
							$(e).remove();
						}
					}
				});
			});
			floatDiv.append(btnPlus);
			
			const btnMinus = document.createElement("div");
			btnMinus.className = "prodMinus";
			btnMinus.addEventListener("click", (e) => {
				let parent = e.currentTarget.parentElement.parentElement;
				let imgCount = parent.querySelectorAll(".productImage").length;
				const origImg = parent.querySelector('.productImage');

				if (imgCount > 1) {
					parent.removeChild(parent.lastChild);

					$("div.activeCell div.product-shelf-entry").each((i, e) => {
						if (clearPx(parent.style.left) === clearPx(e.style.left) && e.offsetTop > parent.offsetTop) {
							$(e).css("top", "-=" + 10 + "px");
						}
					});

					imgCount = parent.querySelectorAll(".productImage").length;
					const height = clearPx(origImg.style.height);
					parent.style.height = (height + ((imgCount - 1) * 10)) + "px";
				}
			});
			floatDiv.append(btnMinus);
		}
	};

	const showUploadNewProduct = (e, curVal) => {
		if (popboxNewProduct == null) {
			popboxNewProduct = new Popbox({
				blur: true,
				overlay: true,
			});
		}
		popboxNewProduct.open("popboxNewProduct");
	};

	const showPriceEditPopup = (e, curVal) => {
		let pID = e.currentTarget ? e.currentTarget.parentElement.getAttribute("id") : e;
		let currentVal = curVal ? curVal : e.currentTarget.innerText;
		document.getElementById("btnSaveNewProductPrice").setAttribute("pid", pID);
		document.getElementById("btnSaveNewProductPrice").setAttribute("oldPrice", currentVal);
		document.getElementById("txtProductPrice").value = (currentVal);
		if (popboxProdID == null) {
			popboxProdID = new Popbox({
				blur: true,
				overlay: true,
			});
		}
		popboxProdID.open("popbox1");
	};

	const showNameEditPopup = (e, curVal) => {
		let pID = e.currentTarget ? e.currentTarget.parentElement.getAttribute("id") : e;
		let currentVal = curVal ? curVal : e.currentTarget.innerText;
		document.getElementById("btnSaveNewProductName").setAttribute("pid", pID);
		document.getElementById("btnSaveNewProductName").setAttribute("oldPrice", currentVal);
		document.getElementById("txtProductName").value = (currentVal);
		if (popboxProdID == null) {
			popboxProdID = new Popbox({
				blur: true,
				overlay: true,
			});
		}
		popboxProdID.open("popbox2");
	};

	const showDimEditPopup = (e, curVal) => {
		let pID = e.currentTarget ? e.currentTarget.parentElement.getAttribute("id") : e;
		let currentVal = curVal ? curVal : e.currentTarget.innerText;
		document.getElementById("btnSaveNewProductDim").setAttribute("pid", pID);
		document.getElementById("btnSaveNewProductDim").setAttribute("oldDim", currentVal);
		document.getElementById("txtProductDim").value = (currentVal);
		if (popboxProdID == null) {
			popboxProdID = new Popbox({
				blur: true,
				overlay: true,
			});
		}
		popboxProdID.open("popbox3");
	};

	const updateProductDim = () => {
		let newDim = document.getElementById("txtProductDim").value;

		if (newDim !== document.getElementById("btnSaveNewProductDim").getAttribute("oldDim")) {
			let pid = document.getElementById("btnSaveNewProductDim").getAttribute("pid");

			BlockUI();
			axios.get(APIURL + 'products.php?action=up&id=' + pid + '&proj=' + project.id + '&prop=dim&val=' + newDim + '&t=' + token).then((res) => {
				if (res.status === 200) {
					UnblockUI();
					Notify({ message: "Product dimensions updated successfully", type: "success", duration: 900 });
				} else {
					Notify({ message: "Error saving changes", type: "danger", duration: 900 });
				}
				document.getElementById(pid).setAttribute("data-dim", newDim);
				document.getElementById(pid).querySelector(".sdProdDim").innerText = newDim;
				if (popboxProdID == null) {
					popboxProdID = new Popbox({
						blur: true,
						overlay: true,
					});
				}
				popboxProdID.close("popbox3");
			});
		}
	};

	const updateProductName = () => {
		let newName = document.getElementById("txtProductName").value;
		
		if (newName !== document.getElementById("btnSaveNewProductName").getAttribute("oldName")) {
			let pid = document.getElementById("btnSaveNewProductName").getAttribute("pid");

			BlockUI();
			axios.get(APIURL + 'products.php?action=up&id=' + pid + '&proj=' + project.id + '&prop=pname&val=' + newName + '&t=' + token).then((res) => {
				if (res.status === 200) {
					UnblockUI();
					Notify({ message: "Product name updated successfully", type: "success", duration: 900 });
				} else {
					Notify({ message: "Error saving changes", type: "danger", duration: 900 });
				}
				document.getElementById(pid).setAttribute("data-name", newName);
				document.getElementById(pid).querySelector(".sdProdName").innerText = newName;
				if (popboxProdID == null) {
					popboxProdID = new Popbox({
						blur: true,
						overlay: true,
					});
				}
				popboxProdID.close("popbox2");
			});
		}
	};

	const updateProductPrice = () => {
		let newPrice = document.getElementById("txtProductPrice").value;
		
		if (newPrice !== document.getElementById("btnSaveNewProductPrice").getAttribute("oldPrice")) {
			let pid = document.getElementById("btnSaveNewProductPrice").getAttribute("pid");

			BlockUI();
			axios.get(APIURL + 'products.php?action=up&id=' + pid + '&proj=' + project.id + '&prop=price&val=' + newPrice + '&t=' + token).then((res) => {
				if (res.status === 200) {
					UnblockUI();
					Notify({ message: "Product price updated successfully", type: "success", duration: 900 });
				} else {
					Notify({ message: "Error saving changes", type: "danger", duration: 900 });
				}
				document.getElementById(pid).setAttribute("data-price", newPrice);
				document.getElementById(pid).querySelector(".sdProdPrice").innerText = newPrice;
				$('div[pid="' + pid + '"]').each((i, e) => {
					$(e).find(".prodPrice").text(newPrice);
				});
				if (popboxProdID == null) {
					popboxProdID = new Popbox({
						blur: true,
						overlay: true,
					});
				}
				popboxProdID.close("popbox1");
			});
		}
	};

	const init = () => {
		let initialized = document.getElementById("shelfDesignerContainer").getAttribute("initialized");
		if (initialized == null) {
			document.getElementById("shelfDesignerContainer").setAttribute("initialized", "true");

			document.addEventListener("keydown", e => {
				if (document.getElementById("shelfDesignerContainer") != null) {
					if (e.ctrlKey) {
						switch (e.key) {
							case "v":
								pasteCellContent();
								break;
					
							case "h":
								splitCellHorizontal();
								break;
					
							case "c":
								copyCellContent();
								break;
					
							case "i":
								splitCellVertical();
								break;
					
							default:
						}
					} else {
						if (e.key.toLowerCase() === "delete") {
							deleteCell();
						}
					}
				}
			}, false);

			$('#popupButtons .navbar__link').on("click", (e) => {
				if ($(e.currentTarget).hasClass("active")) {
					$(e.currentTarget).removeClass("active");
				} else {
					$('#popupButtons .navbar__link.active').removeClass("active");
					$(e.currentTarget).addClass("active");
				}
			});

			$("div.picker").on("click", e => {
				const settings = document.getElementById("settings");
				const scrollTop = settings.scrollTop;
				setTimeout(_ => {
					settings.style.overflow = 'auto';
					settings.scrollTo(0, scrollTop);
					$('div.popover').css('top', (e.target.getClientRects()[0].top - 200) + "px");
				}, 0);
			});

			showActiveCellControls(false);

			popboxProdID = new Popbox({
				blur:true,
				overlay:true,
			});

			//console.log("initiating...");

			$("#rootDiv").append("<div id='cellControls_OLD' style='display: none'><img class='btnSplitV' src='img/vsplit.png' alt='Vertical split' /><img class='btnSplitH' src='img/hsplit.png' alt='Horizontal split' /><img class='btnClone' src='img/copy.png' alt='Clone' /><img class='btnShelfOrientation' src='img/right-arrow.png' alt='Orientation' /><img class='btnDelete' src='img/trash.png' alt='Trash can' /></div>");

			$('#shelfDesignerContainer').on("mousedown", "div.shelfCell", e => {
				if ($(e.currentTarget).find(".shelfCell").length === 0) {
					$("div.shelfCell").removeClass("activeCell");
					$(e.currentTarget).toggleClass("activeCell");
					if ($("div.activeCell").attr("id") !== "rootShelf") {
						$("#shelfAlignSVG").css("transform", "rotate(" + ($("div.activeCell")[0]._orientation === "horizontal" ? 90 : 180) + "deg)");
					} else {
						$("#shelfAlignSVG").css("transform", "rotate(180deg)");
					}
					showActiveCellControls(e.currentTarget);
					applyColors();
				}
			});

			$('#shelfDesignerContainer').on("click", ".btnShelfOrientation", e => {
				if ($("div.activeCell").length) {
					let activeCell = document.getElementsByClassName('activeCell')[0];
					if (activeCell._orientation === undefined) {
						activeCell._orientation = "horizontal";
					}
					if (activeCell._orientation === "horizontal") {
						activeCell._orientation = "vertical";
						e.currentTarget.src = "img/down-arrow.png";
					}
					else {
						activeCell._orientation = "horizontal";
						e.currentTarget.src = "img/right-arrow.png";
					}
				}
			});

			$('#shelfDesignerContainer').on("click", ".btnClone", e => {
				cloneCell();
			});   
			$('#btnSplitH').on("click", (e) => {
				splitCellVertical();
			});
			$('#btnSplitV').on("click", (e) => {
				splitCellHorizontal();
			});
			$('#btnDelete').on("click", (e) => {
				deleteCell();
			});
			$('#btnAlign').on("click", (e) => {
				changeAlignment();
			});

			$.contextMenu({
				// define which elements trigger this menu
				selector: ".product-shelf-entry",
				autoHide: true,
				// define the elements of the menu
				items: {
					cut: {
						name: "Cut", callback: (key, opt) => { 
							if (tmp_ContextMenuProdID != null) {
								contextMenuProdID = tmp_ContextMenuProdID;
								tmp_ContextMenuProdID = null;
							}
							
							let cell = document.getElementById(contextMenuEntryID);
							let parent = cell.parentElement;

							if (parent._orientation === "horizontal") {
								let leftMost = cell.offsetLeft;
								let width = cell.offsetWidth;

								$("div.product-shelf-entry", parent).each((i, e) => {
									if (e.offsetLeft > leftMost) {
										$(e).css("left", "-=" + (width + 2) + "px");
									}
								});
							} else if (parent._orientation === "vertical") {
								let topMost = cell.offsetTop;
								let height = cell.offsetHeight;

								let isTopMostEntry = clearPx(cell.style.top) <= 2;
								$("div.product-shelf-entry", parent).each((i, e) => {
									if (cell !== e && cell.offsetLeft === e.offsetLeft) {
										isTopMostEntry = false;
									}
								});
								
								$("div.product-shelf-entry", cell.parentElement).each((i, e) => {
									if (cell.offsetLeft === e.offsetLeft && e.offsetTop > topMost) {
										$(e).css("top", "-=" + (height + 2) + "px");
									}
									if (isTopMostEntry && e.offsetLeft > cell.offsetLeft) {
										$(e).css("left", "-=" + ($(cell).width() + (cell.offsetLeft === 0 ? 0 : 2)) + "px");
									}
									if (e.offsetLeft < 0) {
										$(e).css("left", 0);
									}
								});
							}
							cell.remove();
						}
					},
					copy: {
						name: "Copy", callback: (key, opt) => {
							//if (tmp_ContextMenuProdID != null) {
								tmp_ContextMenuShelfID = null;
								contextMenuShelfID = null;
								contextMenuProdID = tmp_ContextMenuProdID;
								tmp_ContextMenuProdID = null;
							//}
						}
					},
					delete: {
						name: "Delete", callback: (key, opt) => {
							let cell = document.getElementById(contextMenuEntryID);
							let parent = cell.parentElement;

							if (parent._orientation === "horizontal") {
								let leftMost = cell.offsetLeft;
								let width = cell.offsetWidth;

								$("div.product-shelf-entry", parent).each((i, e) => {
									if (e.offsetLeft > leftMost) {
										$(e).css("left", "-=" + (width + 2) + "px");
									}
								});
							} else if (parent._orientation === "vertical") {
								let topMost = cell.offsetTop;
								let height = cell.offsetHeight;

								$("div.product-shelf-entry", parent).each((i, e) => {
									if (parent.offsetLeft === e.offsetLeft && e.offsetTop > topMost) {
										$(e).css("top", "-=" + (height + 2) + "px");
									}
								});
							}
							cell.remove();
						}
					},
					edit: {
						name: "Edit",
						items: {
							prodDim: {
								name: "Dimensions", callback: (key, opt) => {
									let cell = document.getElementById(contextMenuEntryID);
									let pID = cell.getAttribute("pid");
									const selectedUnit = document.getElementById("units").value;
									let currentDim = document.getElementById(pID).getAttribute(selectedUnit === 'metric' ? "data-size-cm" : "data-size");
									showDimEditPopup(pID, currentDim);
								}
							},
							prodPrice: {
								name: "Price", callback: (key, opt) => {
									let cell = document.getElementById(contextMenuEntryID);
									let pID = cell.getAttribute("pid");
									let currentPrice = cell.querySelector(".prodPrice").innerText;
									showPriceEditPopup(pID, currentPrice);
								}
							},
							prodName: {
								name: "Name", callback: (key, opt) => {
									let cell = document.getElementById(contextMenuEntryID);
									let pID = cell.getAttribute("pid");
									let currentName = document.getElementById(pID).querySelector(".sdProdName").innerText;
									showNameEditPopup(pID, currentName);
								}
							}
						}
					},
				}
				// there's more, have a look at the demos and docs...
			});

			$.contextMenu({
				// define which elements trigger this menu
				selector: ".activeCell",
				// define the elements of the menu
				items: {
					paste: {
						name: "Paste", callback: (key, opt) => {
							if (contextMenuProdID != null) {
								document.getElementById(contextMenuProdID).click();
								hideGhostImage();
							} else if (contextMenuShelfID != null) {
								pasteCellContent();
							}
						},
						disabled: (key, opt) => {        
							// Disable this item if the menu was triggered on a div
							if (contextMenuProdID == null && contextMenuShelfID == null) {
								return true;
							}            
						}
					},
					splitV: {
						name: "Split verticaly", callback: (key, opt) => {
							splitCellHorizontal();
						}
					},
					splitH: {
						name: "Split horizontaly", callback: (key, opt) => {
							splitCellVertical();
						}
					},
					flip: {
						name: "Change orientation", callback: (key, opt) => {
							changeAlignment();
						}
					},
					delete: {
						name: "Delete", callback: (key, opt) => {
							deleteCell();
						}
					},
					copy: {
						name: "Copy", callback: (key, opt) => { 
							//if (tmp_ContextMenuShelfID != null) {
								contextMenuProdID = null;
								tmp_ContextMenuProdID = null;
								contextMenuShelfID = tmp_ContextMenuShelfID;
								tmp_ContextMenuShelfID = null;
								copyCellContent();
							//}
						}
					},
					empty: {
						name: "Empty", callback: (key, opt) => {
							emptyCell();
						}
					}
				}
				// there's more, have a look at the demos and docs...
			});

			$('#shelfDesignerContainer').on("contextmenu", 'div.product-shelf-entry', e => {
				contextMenuEntryID = e.currentTarget.getAttribute("id");
				tmp_ContextMenuProdID = e.currentTarget.getAttribute("pid");
				e.preventDefault();
			});

			$('#shelfDesignerContainer').on("contextmenu", 'div.shelfCell', e => {
				if ($(e.currentTarget).find('div.product-shelf-entry').length > 0) {
					tmp_ContextMenuShelfID = e.currentTarget.getAttribute("id");
				}
				e.preventDefault();
			});
		
			document.getElementById('shelfDesignerContainer').addEventListener("mousedown", event => {
				const settings = document.getElementById('settings');
				const rootShelfCont = document.getElementById('rootShelfCont');
				const shelvesSettings = document.getElementById('shelvesSettings');
				const imageGallery = document.getElementById('imageGallery');
				const products = document.getElementById('products');
				const cellControls = document.getElementById('cellControls');

				if (settings && settings.contains(event.target) === false &&
					rootShelfCont.contains(event.target) === false &&
					shelvesSettings.contains(event.target) === false &&
					imageGallery.contains(event.target) === false &&
					products.contains(event.target) === false && 
					cellControls.contains(event.target) === false) {
					$("div.activeCell").removeClass("activeCell");
					showActiveCellControls(false);
					applyColors();
				}
			});	
			
			//console.log(dataShelfDesign.data);
			if (dataShelfDesign.data != null) {
				const data = dataShelfDesign.data.shelf ? dataShelfDesign.data : JSON.parse(dataShelfDesign.data);
				if (data.shelf != null) {
					$("#rootShelfCont").width(data.w);
					$("#rootShelfCont").height(data.h);
					rebuildShelf(JSON.stringify(data.shelf));
					if (data.price) {
						setTimeout(() => {
							setPricesVisible(data.price);
							setRulerVisible(data.ruler);
							$('#activeCellColorCont .swatch').css('background-color', data.acol);
							$("#inactiveCellColorCont .swatch").css('background-color', data.iccol);
							$('#shelvesSeparatorColorCont .swatch').css('background-color', data.sccol);
							$("#shelfWidth").val(data.wd);
							$("#shelfHeight").val(data.hd);
							$("#unit").val(data.unit);
							$("#" + data.acell).addClass("activeCell");
							applyColors();
						}, 100);
					}
				}
				if (data.rulerLines) {
					data.rulerLines.forEach(l => {
						var line = document.createElement("div");
						line.className = "ruler-line";
						line.style.top = l;
						document.getElementById("rootShelfCont").appendChild(line);

						line.addEventListener("dblclick", e => {
							line.remove();
						});

						const markAffectedProducts = line => {
							const allLinesNodes = document.querySelectorAll(".ruler-line");
							const allLines = [];
							allLinesNodes.forEach(l => {
								allLines.push(l);
							});
							allLines.sort((a, b) => {
								return a.offsetTop - b.offsetTop;
							});
							const index = allLines.indexOf(line);
							const allProducts = document.querySelectorAll(".product-shelf-entry");
							const lineTop = line.getClientRects()[0].top;
							if (index > 0) {
								const prevLine = allLines[index - 1];
								const prevLineTop = prevLine.getClientRects()[0].top;
								allProducts.forEach(p => {
									const top = p.getClientRects()[0].top;
									if (top + p.offsetHeight >= prevLineTop && top + p.offsetHeight < lineTop) {
										p.style.opacity = "1";
									} else {
										p.style.opacity = "0.3";
									}
								});
							} else {
								allProducts.forEach(p => {
									const top = p.getClientRects()[0].top;
									if (top + p.offsetHeight < lineTop) {
										p.style.opacity = "1";
									} else {
										p.style.opacity = "0.3";
									}
								});
							}
						};

						new Draggable(line, {
							limit: {
								x: 0
							},
							onDragStart: _ => {
								markAffectedProducts(line);
							},
							onDrag: _ => {
								markAffectedProducts(line);
							},
							onDragEnd: _ => {
								const allProducts = document.querySelectorAll(".product-shelf-entry");
								allProducts.forEach(p => {
									p.style.opacity = "1";
								});
							}
						});
					});
				}
			}

			let tmpRuler = new LightRuler({
				mode: "infinite",
				mountRef: document.getElementById("rulerRef"),
				scrollElement: document.getElementById("shelfWrap"),
				rulerId: "my-ruler",
				width: 30000,
				height: 30000,
				style: {
					scale: 4,
					mode: "right",
					unit: {
						text: "cm",
					},
				},
				onScroll: (x, y) => {
					//console.log(x, y);
				},
			});
			setRuler(tmpRuler);
			window.ruler = tmpRuler;

			const containers = document.querySelectorAll('.tab-container');
			containers.forEach(container => {
				tabs(container);
			});

			applyColors();

			var leftRuler = document.getElementById('canvas-ruler-y-my-ruler');
			leftRuler.addEventListener("contextmenu", e => {
				e.preventDefault();
				var line = document.createElement("div");
				line.className = "ruler-line";
				line.style.backgroundColor = $("#linesColorCont .swatch").css("background-color");
				line.style.top = (document.getElementById("shelfWrap").scrollTop + (e.clientY - 20)) + "px";
				document.getElementById("rootShelfCont").appendChild(line);
				$('.ruler-line').css('background', $("#linesColorCont .swatch").css("background-color"));

				line.addEventListener("dblclick", e => {
					line.remove();
				});

				const markAffectedProducts = line => {
					const allLinesNodes = document.querySelectorAll(".ruler-line");
					const allLines = [];
					allLinesNodes.forEach(l => {
						allLines.push(l);
					});
					allLines.sort((a, b) => {
						return a.offsetTop - b.offsetTop;
					});
					const index = allLines.indexOf(line);
					const allProducts = document.querySelectorAll(".product-shelf-entry");
					const lineTop = line.getClientRects()[0].top;
					if (index > 0) {
						const prevLine = allLines[index - 1];
						const prevLineTop = prevLine.getClientRects()[0].top;
						allProducts.forEach(p => {
							const top = p.getClientRects()[0].top;
							if (top + p.offsetHeight >= prevLineTop && top + p.offsetHeight < lineTop) {
								p.style.opacity = "1";
							} else {
								p.style.opacity = "0.3";
							}
						});
					} else {
						allProducts.forEach(p => {
							const top = p.getClientRects()[0].top;
							if (top + p.offsetHeight < lineTop) {
								p.style.opacity = "1";
							} else {
								p.style.opacity = "0.3";
							}
						});
					}
				};

				new Draggable(line, {
					limit: {
						x: 0
					},
					onDragStart: _ => {
						markAffectedProducts(line);
					},
					onDrag: _ => {
						markAffectedProducts(line);
					},
					onDragEnd: _ => {
						const allProducts = document.querySelectorAll(".product-shelf-entry");
						allProducts.forEach(p => {
							p.style.opacity = "1";
						});
					}
				});
			});
		}
		
	};

	setTimeout(init, 100);

	const emptyCell = () => {
		if ($("div.activeCell").length > 0) {
			// eslint-disable-next-line no-restricted-globals
			if (confirm("Are you sure you want to remove all products from this cell?")) {
				$("div.activeCell").empty();
				applyColors();
			}
		}
	};

	const changeShelfWidth = (e) => {
		const w = $("#shelfWidth").val() * 405;
		$("#rootShelfCont").width(w);
		Object.keys(allSplitters).forEach(key => {
			allSplitters[key].refresh();
		});
	};
	
	const changeShelfHeight = (e) => {
		const h = $("#shelfHeight").val() * 405;
		$("#rootShelfCont").height(h);
		Object.keys(allSplitters).forEach(key => {
			allSplitters[key].refresh();
		});
	};

	const closeShelvesDesigner = () => {
		window.addedShelfDesignerEvents = null;
		//document.getElementById("shelvesDesigner").style.display = "none";
		BlockUI();
		//const shelfData = JSON.stringify(layoutToJSON());
		//axios.get(APIURL + 'shelfdesign.php?action=up&id=' + shelfProject._id + '&data=' + shelfData + '&t='/* + tokken*/).then((res) => {
			//if (res.status === 200) {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Are you sure you want to exit? Any unsaved changes will be lost.")) {
			//saveDesign(() => {
				projectShelvesDesignUpdated.dispatch({});
			//});
		} else {
			//projectShelvesDesignUpdated.dispatch({});
			UnblockUI();
		}
			////} else {
			//	Notify({ message: "Error saving project", type: "danger" });
			//}
		//});
	};

	const saveDesign = (callBack) => {
		BlockUI();
		const shelfData = layoutToJSON();

		const allData = {
			shelf: shelfData,
			acol: $('#activeCellColorCont .swatch').css('background-color'),
			iccol: $("#inactiveCellColorCont .swatch").css('background-color'),
			sccol: $('#shelvesSeparatorColorCont .swatch').css('background-color'),
			lcol: $('#linesColorCont .swatch').css('background-color'),
			price: pricesVisible,
			ruler: rulerVisible,
			w: $("#rootShelfCont").width(),
			h: $("#rootShelfCont").height(),
			hs: $("#prodSpaceHorizontal").value,
			vs: $("#prodSpaceVertical").value,
			wd: $("#shelfWidth").val(),
			hd: $("#shelfHeight").val(),
			unit: $("#unit").val(),
			acell: $("div.activeCell").attr("id")
		};

		allData.rulerLines = [];
		document.querySelectorAll(".ruler-line").forEach(el => {
			// save each line y coordinate
			allData.rulerLines.push(el.style.top);
		});

		console.log(allData);

		const data = new FormData();
		data.append("action", "up");
		data.append("id", shelfProject.id !== undefined ? shelfProject.id : shelfProject._id);
		data.append("data", JSON.stringify(allData));
		
		axios.post(APIURL + 'shelfdesign.php', data).then((res) => {
			if (res.status === 200) {
				Notify({ message: "Shelf design saved", type: "success" });
				UnblockUI();
				if (callBack) {
					callBack();
				}
			} else {
				Notify({ message: "Error saving project", type: "danger" });
			}
		});
	};

	const exportDesign = (callback) => {
		let workbook = new ExcelJS.Workbook();
		workbook.creator = 'Eyesee web portal - Shelf Designer';
		workbook.lastModifiedBy = user.user_name;
		workbook.created = new Date();
		const worksheet = workbook.addWorksheet('List');
		const columns = [];
		const columnWidths = [15, 15, 15, 15, 15, 15, 50, 50, 50, 50, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
		ACCESSORS_VALUES.forEach((entry, i) => {
			columns.push({
				header: entry,
				key: ACCESSORS[i],
				width: columnWidths[i]
			});
		});
		worksheet.columns = columns;
		worksheet.getRow(1).fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: 'ffff0000' }                
		};
		worksheet.getRow(1).font = {
			name: 'Calibri',
			color: { argb: 'ffffff' },
			family: 2,
			bold: true
		};

		workbookDS = new ExcelJS.Workbook();
		workbookDS.creator = "Eyesee web portal";
		workbookDS.created = new Date();
		const worksheetDS = workbookDS.addWorksheet('Sheet1');
		worksheetDS.columns = [
			{ header: "CDB_stimuli_id", key: "cdb_stimuli_id", width: 20 },
			{ header: "Product Short Name", key: "pname1", width: 20 },
			{ header: "Product Full Name (SKU)", key: "pname2", width: 20 },
			{ header: "Product Description 1", key: "pd1", width: 20 },
			{ header: "Product Description 2", key: "pd2", width: 20 },
			{ header: "Product Description 3", key: "pd3", width: 20 },
			{ header: "Brand", key: "brnd", width: 20 },
			{ header: "Sub-Brand", key: "sbrnd", width: 20 },
			{ header: "Purpose", key: "prps", width: 20 },
			{ header: "Client", key: "client", width: 20 },
			{ header: "Unit of Measurement", key: "unit", width: 20 },
			{ header: "Product Price", key: "price", width: 20 },
			{ header: "Product Main Image", key: "pnpng", width: 20 },
			{ header: "Video 360 View", key: "v360", width: 20 },
			{ header: "Loop Video", key: "vloop", width: 20 },
			{ header: "Product Category", key: "pcat", width: 20 },
			{ header: "KPI 1", key: "kp1", width: 20 },
			{ header: "KPI 2", key: "kp2", width: 20 },
			{ header: "KPI 3", key: "kp3", width: 20 },
			{ header: "KPI 4", key: "kp4", width: 20 },
			{ header: "KPI 5", key: "kp5", width: 20 },
			{ header: "Display Only", key: "dnly", width: 20 },
			{ header: "VS_platform_product_id", key: "vsid", width: 20 },
			{ header: "Available in bulk", key: "ablk", width: 20 }
		];
		worksheetDS.addRow({
			cdb_stimuli_id: "Input stimuli_id",
			pname1: "(up to 64 characters)",
			pname2: "(up to 128 characters)",
			pd1: "(up to 255 characters)",
			pd2: "(up to 255 characters)",
			pd3: "(up to 255 characters)",
			brnd: "(up to 127 characters)",
			sbrnd: "(up to 127 characters)",
			prps: "(up to 255 characters)",
			client: "(up to 127 characters)",
			unit: "Unit of Measurement",
			price: "(float)",
			pnpng: "(only filename, i.e. \"deodorant.png\")",
			v360: "Video 360 View",
			vloop: "1 if true, otherwise empty",
			pcat: "(check Sheet 2 for mapping)",
			kp1: 1,
			kp2: 2,
			kp3: 3,
			kp4: 4,
			kp5: 5,
			dnly: "(only product image, no adding to cart)",
			vsid: "(do not edit contents of this column!)",
			ablk: "1 if true, otherwise empty"
		});
		worksheetDS.getRow(1).fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: 'f2f2f2' }                
		};
		worksheetDS.getRow(1).font = {
			name: 'Calibri',
			color: { argb: '000000' },
			family: 2,
			bold: true
		};

		const allCells = document.querySelectorAll('.shelfCell');

		const cellsWithProducts = [];
		allCells.forEach(entry => {
			if (!entry.classList.contains("splitter_panel")) {
				cellsWithProducts.push(entry);
			}
		});

		cellsWithProducts.sort((a, b) => {
			return a.getClientRects()[0].top - b.getClientRects()[0].top;
		});

		const allProds = document.querySelectorAll(".product-shelf-entry"); 
		const allProducts = [];
		allProds.forEach(entry => {
			allProducts.push(entry);
		});
		allProducts.sort((a, b) => {
			return a.getClientRects()[0].top - b.getClientRects()[0].top;
		});

		let prevShelfCell = null;
		let lastProdGroup = [];
		allProducts.forEach(prod => {
			if (prod.parentElement !== prevShelfCell) {
				prevShelfCell = prod.parentElement;
			}
		});

		const lines = document.querySelectorAll(".ruler-line");
		const allLines = [];
		lines.forEach(el => {
			allLines.push(el);
		});
		allLines.sort((a, b) => {
			return a.getClientRects()[0].top - b.getClientRects()[0].top;
		});

		const lineProducts = [];
		allLines.forEach((line, idx) => {
			const lineY = line.getClientRects()[0].top;
			lineProducts.push([]);
			allProducts.forEach(prod => {
				const prodY = prod.getClientRects()[0].top + prod.getClientRects()[0].height;
				if (prodY <= lineY && prod._used == null) {
					prod._used = true;
					lineProducts[idx].push(prod);
				}
			});
			lineProducts[idx].sort((a, b) => {
				if (a.parentElement === b.parentElement && b.parentElement._orientation === "vertical") {
					return (a.offsetLeft - clearPx(a.style.marginLeft)) - (b.offsetLeft - clearPx(b.style.marginLeft)) || a.offsetTop - b.offsetTop;
				} else {
					const aX = a.getBoundingClientRect().x;
					const bX = b.getBoundingClientRect().x;
					return aX - bX;
				}
			});
		});

		const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const finalProducts = {};
		let groupCount = 1;
		let lastProd = null;
		lineProducts.forEach((line, lineIdx) => {
			//line = line.reverse();
			groupCount = 1; //lineIdx === 0 ? 1 : 0;
			line.forEach((prod, pIdx) => {
				delete prod._used;
				const prodPID = prod.getAttribute("pid");
				const lastProdPID = lastProd ? lastProd.getAttribute("pid") : null;
				if (lastProd != null && (prodPID !== lastProdPID && pIdx > 0)) {
					groupCount++;
				}
				
				if (finalProducts[alphabet[lineIdx] + groupCount] == null) {
					finalProducts[alphabet[lineIdx] + groupCount] = [];
				}
				finalProducts[alphabet[lineIdx] + '' + groupCount].push(prod);
				lastProd = prod;
			});
		});

		Object.keys(finalProducts).forEach((key, idx) => {
			const data = JSON.parse(document.getElementById(finalProducts[key][0].getAttribute("pid")).getAttribute("allData"));
			if (data) {
				if (data.pnpng.indexOf('projprodimgs/') > -1) {
					data.pnpng = data.pnpng.replace("projprodimgs/", "");
				}
				worksheetDS.addRow({
					cdb_stimuli_id: "",
					pname1: data.pname,
					pname2: data.pname,
					pd1: "",
					pd2: "",
					pd3: "",
					brnd: data.brnd,
					sbrnd: data.sbrnd,
					prps: "",
					client: "",
					unit: data.unit,
					price: isNaN(parseFloat(data.price)) ? "" : parseFloat(data.price),
					pnpng: data.pnpng.indexOf(";") > -1 ? data.pnpng.split(";")[0] : data.pnpng,
					v360: "",
					vloop: "",
					pcat: "",
					kp1: key,
					kp2: finalProducts[key].length,
					kp3: data.dim,
					kp4: "",
					kp5: "",
					dnly: "",
					vsid: "",
					ablk: ""
				});
			}
		});
		
		$(".product-shelf-entry").each((index, prod) => {
			prod._processed = false;
		});

		workbookDS.xlsx.writeBuffer().then(bufferDS => {
			callback(bufferDS);
		});

	};

	const downloadProject = () => {
		if (workbookDS === null) {
			Notify({
				title: "ERROR!",
				message: "Generate datasource first!",
				type: "danger",
				duration: 8000
			});
			return;
		}
		const allProductsImages = $("img.productImage");
		const imagesToDownload = {};
		const usedImages = [];
		let imgCnt = 0;
		allProductsImages.each((index, img) => {
			let src = img.src.substr(img.src.lastIndexOf("/") + 1);
			src = src.replace("_thumb", "");
			if (usedImages.indexOf(src) === -1) {
				imagesToDownload[imgCnt++] = decodeURIComponent(src);
				usedImages.push(src);
			}
		});

		workbookDS.xlsx.writeBuffer().then(bufferDS => {
			const blobDS = new Blob([bufferDS], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			//zip.file("products_ds.xlsx", blobDS);

			const json = JSON.stringify(imagesToDownload);
			const blob = new Blob([json], {
				type: 'application/json'
			});
			const data = new FormData();
			data.append("file[]", blob, "products.json");
			data.append("file[]", blobDS, "products.xlsx");

			let progDiv = document.createElement('div');
			progDiv.id = "downloadProgressContainer";
			progDiv.innerHTML = "<div id='downloadProgressBar'></div><span style='position: relative'>Downloading...</span>";
			document.body.appendChild(progDiv);

			axios.post(APIURL + "downloadFiles.php", data).then(res => {
				if (res.status === 200 && res.data.msg === "Success") {
					const fileData = new FormData();
					fileData.set("file", res.data.filename);
					axios.post(APIURL + "getf.php", fileData, {
						responseType: 'blob',
						onDownloadProgress: progressEvent => {
							const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
							document.getElementById('downloadProgressBar').style.width = percentage + "%";
						}
					}).then((r) => {
						const date = new Date();
						let fileName = 'prod_' + (project ? project.name + '_' : '_') + date.toLocaleDateString() + '_' + date.toLocaleTimeString() + '.zip';
						if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
							window.navigator.msSaveOrOpenBlob(new Blob([r.data],
									{ type: 'application/zip' }
								),
								fileName
							);
						} else {
							const url = window.URL.createObjectURL(new Blob([r.data], { type: 'application/zip' }));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', fileName);
							document.body.appendChild(link);
							link.click();
							document.getElementById('downloadProgressContainer').remove();
							UnblockUI();
							// delete file on server
							axios.get(APIURL + 'project.php?action=delzip&file=' + res.data.filename).then(r => {});
						}
					});
				} else {
					document.getElementById('downloadProgressContainer').remove();
					UnblockUIError(res.status);
				}
			}).catch(err => {
				document.getElementById('downloadProgressContainer').remove();
				UnblockUIError(err);
			});
		});
	};

	const toggleFullscreen = () => {
		let svg = document.getElementById("fullScreenToggle");
		//let isFullScreen = svg.getAttribute("isFullScreen");
		if (document.fullscreenElement == null) {
            document.body.requestFullscreen().then(() => {
                svg.innerHTML = '<path class="cls-1" d="M1.86,13H8.53v6.67"/><path class="cls-1" d="M19.64,8.53H13V1.86"/><path class="cls-1" d="M13,8.53,20.75.75"/><path class="cls-1" d="M.75,20.75,8.53,13"/><path class="cls-1" d="M1.86,13H8.53v6.67"/><path class="cls-1" d="M19.64,8.53H13V1.86"/><path class="cls-1" d="M13,8.53,20.75.75"/><path class="cls-1" d="M.75,20.75,8.53,13"/>';
            });
        } else {
            document.exitFullscreen().then(() => {
                svg.innerHTML = '<path class="cls-1" d="M14.08.75h6.67V7.42"/><path class="cls-1" d="M7.42,20.75H.75V14.08"/><path class="cls-1" d="M20.75.75,13,8.53"/><path class="cls-1" d="M.75,20.75,8.53,13"/>';
            });
        }		
	};

	const showTemplateSaveDialog = () => {
		if (popboxTemplate == null) {
			popboxTemplate = new Popbox({
				blur: true,
				overlay: true,
			});
		}
		popboxTemplate.open("popboxTemplate");
	};

	const uploadShelfTemplateImage = (img, isTemplateImage = true, callback = null) => {
		const file = img;
		const formData = new FormData();
		const fileName = file.name;
		formData.append("file[]", file, fileName);

		let uploadURL = isTemplateImage ? 'uploadShelfTpltImage.php' : 'uploadShelfProjImage.php';

		/*eslint-disable */
		const uploadFile = (formData) => {
			BlockUI();
			//console.log(formData);
			axios.post(APIURL + uploadURL, formData, {
				onUploadProgress: progressEvent => {
					const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
					//console.log(progress);
				}
			}).then(res => {
				if (res.status === 200) {
					//console.log(res.data);
					if ((res.data + "").indexOf("Fatal error") > -1) {
						Notify({
							title: "ERROR!",
							message: "Fatal error: Out of memory. Image thumbnail is not created!",
							type: "danger",
							duration: 8000
						});
						return;
					}
					Notify({
						message: "Shelf template image successfully uploaded!",
						type: "success",
						duration: 8000
					});
					
					if (callback) callback();

					UnblockUI();
				}
			}).catch(err => {
				Notify({
					title: "ERROR!",
					message: "Error uploading image: " + err.message,
					type: "danger",
					duration: 8000
				});
				UnblockUI();
			});
		};

		uploadFile(formData);
	};

	const saveTemplate = () => {
		const templateName = document.getElementById("txtTemplateName").value;
		if (templateName.length > 0) {
			BlockUI();
			// check if template name already exists
			axios.get(APIURL + 'shelftemplate.php?action=chk&name=' + templateName + '&t=tokken').then((response) => {
				if (response.status === 200 && response.data.length === 0) {
					BlockUI();
					const shelfData = {
						w: document.getElementById("rootShelf").offsetWidth,
						h: document.getElementById("rootShelf").offsetHeight,
						shelf: layoutToJSON(null, null, true)
					};

					const data = new FormData();
					data.append("action", "nt");
					data.append("id", shelfProject._id);
					data.append("name", $("#txtTemplateName").val());
					data.append("auth", user.uset_name);
					data.append("data", JSON.stringify(shelfData));
				
					axios.post(APIURL + 'shelftemplate.php', data).then((res) => {
						if (res.status === 200) {
							document.querySelectorAll('.shelfCell').forEach(el => {
								el._tmpBkgCol = el.style.backgroundColor;
								el.style.backgroundColor = 'transparent';
							});
							const oldSpliterColor = $(".vsplitter").css("background-color");
							$(".vsplitter").css("background-color", "#000000");
							$(".hsplitter").css("background-color", "#000000");
							$("div.product-shelf-entry").css("display", "none");
							$("#rootShelfCont").css("border", "none");
							html2canvas(document.querySelector("#rootShelfCont"), {
								backgroundColor: "white"
							}).then(canvas => {
								const img = new Image();
								img.src = canvas.toDataURL("image/jpeg");

								fetch(img.src)
									.then(res => res.blob())
									.then(blob => {
										const file = new File([blob], $("#txtTemplateName").val() + ".jpeg", blob);
										uploadShelfTemplateImage(file, true, () => {
											Notify({ message: "Template saved", type: "success" });
											UnblockUI();
										});
									});
								
								document.querySelectorAll('.shelfCell').forEach(el => {
									el.style.backgroundColor = el._tmpBkgCol;
									delete el._tmpBkgCol;
								});
								$(".vsplitter").css("background-color", oldSpliterColor);
								$(".hsplitter").css("background-color", oldSpliterColor);
								$("div.product-shelf-entry").css("display", "block");
								$("#rootShelfCont").css("border", "2px solid rgb(170, 170, 170)");
							});
						} else {
							Notify({ message: "Error saving project", type: "danger" });
							UnblockUI();
						}
						popboxTemplate.close("popboxTemplate");
					});
				} else {
					Notify({ message: "Template name already exists", type: "danger" });
					UnblockUI();
				}
			});
		} else {
			Notify({ message: "Please enter template name", type: "danger" });
		}
	};

	const downloadShelfImage = () => {
		document.querySelectorAll('.shelfCell').forEach(el => {
			el._tmpBkgCol = el.style.backgroundColor;
			el.style.backgroundColor = 'transparent';
		});
		$("div.prodPrice").css("line-height", "2px");
		$("#rootShelfCont").css("border", "none");
		$(".ruler-line").css("display", "none");
		html2canvas(document.querySelector("#rootShelfCont"), {
			backgroundColor: null
		}).then(canvas => {
			downloadCanvasAsImage(canvas, project.name + ".png");
			document.querySelectorAll('.shelfCell').forEach(el => {
				el.style.backgroundColor = el._tmpBkgCol;
				delete el._tmpBkgCol;
			});
			$("div.prodPrice").css("line-height", "14px");
			$("#rootShelfCont").css("border", "2px solid rgb(170, 170, 170)");
			$(".ruler-line").css("display", "block");
		});
	};

	const downloadCanvasAsImage = (canvas, filename) => {
		let a = document.createElement("a");
		a.href = canvas.toDataURL();
		a.download = filename;
		a.dispatchEvent(new MouseEvent("click"));
	};

	const toggleSettings = () => {
		const x = document.getElementById("settings");
		document.getElementById("products").style.display = "none";
		document.getElementById("shelves").style.display = "none";
		document.getElementById("excelEditor").style.display = "none";
		if (x.style.display === "none") {
			x.style.display = "block";
			document.getElementById("shelfContainer").style.left = "362px";
			document.getElementById("shelfContainer").style.width = "calc(100% - 362px)";
		} else {
			x.style.display = "none";
			document.getElementById("shelfContainer").style.left = "41px";
			document.getElementById("shelfContainer").style.width = "calc(100% - 41px)";
		}
	};

	const toggleShelvesLib = () => {
		if (shelvesSimplebar == null) {
			shelvesSimplebar = new SimpleBar(document.getElementById('shelvesList'));			
			$('.simplebar-placeholder').remove();
		}
		const x = document.getElementById("shelves");
		if (x.style.display === "none") {
			x.style.display = "block";
			document.getElementById("shelfContainer").style.left = "447px";
			document.getElementById("shelfContainer").style.width = "calc(100% - 447px)";
		} else {
			x.style.display = "none";
			document.getElementById("shelfContainer").style.left = "41px";
			document.getElementById("shelfContainer").style.width = "calc(100% - 41px)";
		}
		document.getElementById("products").style.display = "none";
		document.getElementById("settings").style.display = "none";
		document.getElementById("excelEditor").style.display = "none";
		shelvesSimplebar.recalculate();
	};

	const toggleProductsLib = () => {
		if (simplebar == null) {
			simplebar = new SimpleBar(document.getElementById('productsList'));
			simplebarAllProducts = new SimpleBar(document.getElementById('allProdList'));
			
			$('.simplebar-placeholder').remove();

			document.getElementById("products").style.display = "none";
		}
		const x = document.getElementById("products");
		if (x.style.display === "none") {
			x.style.display = "block";
			document.getElementById("shelfContainer").style.left = "447px";
			document.getElementById("shelfContainer").style.width = "calc(100% - 447px)";
		} else {
			x.style.display = "none";
			document.getElementById("shelfContainer").style.left = "41px";
			document.getElementById("shelfContainer").style.width = "calc(100% - 41px)";
		}
		document.getElementById("settings").style.display = "none";
		document.getElementById("shelves").style.display = "none";
		document.getElementById("excelEditor").style.display = "none";
		try {
			simplebar.recalculate();
			simplebarAllProducts.recalculate();
		} catch (e) {
			console.log(e);
		}
	};

	const enableHorizontalSort = (div) => {
		new Draggable(div, {
			limit: {
				x: [0, $("div.activeCell").width() - $(div).width() - 1],
				y: $("div.activeCell").height() - $(div).height() - 1
			},
			setPosition: false,
			setCursor: true,
			onDragStart: (el, x, y) => {
				dragGhost.style.cssText = "border: solid 1px #000; background: rgba(255,255,255,0.6); position: absolute; width: " + $(el).width() + "px; height: " + $(el).height() + "px; left: " + $(el).position().left + "px; bottom: " + $(el).position().bottom + "px;";
				$("div.activeCell").append(dragGhost);
				el._sX = $(el).position().left;
				el._b = $(el).position().bottom;
				el.style.top = "";
			},
			onDrag: (el, x, y) => {
				$("div.activeCell").find(".product-shelf-entry").each((i, e) => {
					el.style.top = "";
					dragGhost.style.bottom = el.style.bottom = "1px";
					if (e !== el) {
						const elLeft = $(el).position().left;
						const eLeft = $(e).position().left;
						if (elLeft < eLeft + $(e).width() - 10 && elLeft > eLeft + 10) {
							if (elLeft > el._sX) {
								if (lastMovedElID1 !== e.id) {
									lastMovedElID1 = e.id;
									lastMovedElID2 = "";
									$(e).css("left", $(dragGhost).position().left);
									$(dragGhost).css("left", $(e).position().left + $(e).width() + horizontalSpacing);
								}
							} else {
								if (lastMovedElID2 !== e.id) {
									lastMovedElID2 = e.id;
									lastMovedElID1 = "";
									const gLeft = $(dragGhost).position().left;
									$(dragGhost).css("left", eLeft);
									$(e).css("left", eLeft + $(dragGhost).width() + horizontalSpacing);
								}
							}
							el._sX = $(el).position().left;
						}
					}
				});
			},
			onDragEnd: (el, x, y) => {
				$(el).css("left", $(dragGhost).position().left + "px");
				el.style.bottom = "1px";
				el.style.top = "";
				lastMovedElID1 = "";
				lastMovedElID2 = "";
				$(dragGhost).remove();
			}
		});
	};

	const enableVerticalSort = (div) => {
		new Draggable(div, {
			limit: {
				x: $(div).position().left,
				y: [0, $("div.activeCell").height() - $(div).height() - 1]
			},
			setPosition: false,
			setCursor: true,
			onDragStart: (el, x, y) => {
				dragGhost.style.cssText = "border: solid 1px #000; background: rgba(255,255,255,0.6); position: absolute; width: " + $(el).width() + "px; height: " + $(el).height() + "px; left: " + $(el).position().left + "px; top: " + $(el).position().top + "px;";
				$("div.activeCell").append(dragGhost);
				el._sY = $(el).position().top;
			},
			onDrag: (el, x, y) => {
				$(el).css("left", $(dragGhost).position().left + "px");
				$("div.activeCell").find(".product-shelf-entry").each((i, e) => {
					if (e !== el && $(el).position().left === $(e).position().left) {
						const elTop = $(el).position().top;
						const eTop = $(e).position().top;
						if (elTop < eTop + $(e).height() - 10 && elTop > eTop + 10) {
							if (elTop > el._sY) {
								if (lastMovedElID1 !== e.id) {
									lastMovedElID1 = e.id;
									lastMovedElID2 = "";
									$(e).css("top", $(dragGhost).position().top);
									$(dragGhost).css("top", eTop);
									//dragGhost.style.top = ($(e).position().top + $(e).height() + verticalSpacing) + "px";
								}
							} else {
								if (lastMovedElID2 !== e.id) {
									lastMovedElID2 = e.id;
									lastMovedElID1 = "";

									const gTop = $(dragGhost).position().top;
									$(dragGhost).css("top", eTop);
									$(e).css("top", gTop);

									//const oldTop = $(e).position().top;
									//dragGhost.style.top = oldTop + "px";
									//$(e).css("top", $(dragGhost).position().top + $(dragGhost).height() + verticalSpacing + 2);
								}
							}
							el._sY = $(el).position().top;
						}
					}
				});
			},
			onDragEnd: (el, x, y) => {
				$(el).css("top", $(dragGhost).position().top + "px");
				$(el).css("left", $(dragGhost).position().left + "px");
				lastMovedElID1 = "";
				lastMovedElID2 = "";
				$(dragGhost).remove();
			}
		});
	};

	const addProductToProject = (e) => {
		const product = e.currentTarget.id;

		const sizeData = e.currentTarget.getAttribute("data-size").split(",");
		const width = parseFloat(sizeData[0]) * 400;
		const height = parseFloat(sizeData[1]) * 400;
		const imgSrc = e.currentTarget.getAttribute("data");
		const pid = e.currentTarget.id;
		const price = e.currentTarget.getAttribute("data-price");

		axios.get(APIURL + 'project.php?action=apr&id=' + project.id + '&prods=' + [product] + '&t=' + token).then((response) => {
            const data = response.data;
            if (data.added.length > 0) {
                Notify({
                    title: "Success",
                    message: "Product added to current project!",
                    type: "success"
				});
				
				// add prduct to project products tab
				const div = document.getElementById(pid).cloneNode(true);
				div.addEventListener("click", (e) => {
					addProductToProject(e);
				});
				div.addEventListener("mouseover", (e) => {
					productHover(e);
				});
				div.addEventListener("mouseout", (e) => {
					hideGhostImage();
				});

				document.getElementById("productsList").querySelector(".simplebar-content").appendChild(div);
				simplebar.recalculate();
			}
			
			_addProdToShelf(width, height, imgSrc, pid, price);

            UnblockUI();
        }).catch((error) => {
            console.log(error);
            UnblockUIError(error);
        });
	};

	const _addProdToShelf = (width, height, imgSrc, pid, price) => {
		if ($("div.activeCell").length > 0 && $("div.activeCell")[0]._orientation === "horizontal") {
			let left = 2;
			let leftMost = 0;
			let lastWidth = 0;
			$("div.activeCell div.product-shelf-entry").each((i, e) => {
				if (e.offsetLeft > leftMost) {
					leftMost = e.offsetLeft;
					lastWidth = e.offsetWidth;
				}
			});
			left = (leftMost + lastWidth) + horizontalSpacing;
			if (left + width > $("div.activeCell").width()) {
				Notify({
					title: "Error",
					message: "Not enough room on the shelf!",
					type: "danger",
					duration: 900,
					position: "top-center"
				});
			} else {
				const div = document.createElement("div");
				const img = new Image();
				//img.onload = _ => {
					img.style.cssText = "width: " + width + "px";
					$("div.activeCell").append(div);
					div.append(img);
					div.className = "product-shelf-entry";
					div.setAttribute("pid", pid);
					div.id = makeUUID(8);
					div.style.cssText = "position: absolute; bottom: 1px; left: 2px;";
					div.style.left = left + "px";
					div.style.width = width + "px";
					
					addEntryButtons(div, "horizontal");

					const prodPrice = document.createElement("div");
					prodPrice.className = "prodPrice";
					prodPrice.innerHTML = price;
					div.append(prodPrice);
					if (!pricesVisible) {
						prodPrice.style.display = "none";
					}
					_prodHover(width, height, imgSrc, pid);

					enableHorizontalSort(div);
				//};
				img.src = imgSrc;
				img.className = "productImage";
			}
		} else {
			let pos = _prodHover(width, height, imgSrc, pid);				
			let top = pos.top;
			let left = pos.left;				
			if (top + pos.height > $("div.activeCell").height()) {
				top = verticalSpacing;
			}

			if (left + pos.width > $("div.activeCell").width()) {
				Notify({
					title: "Error",
					message: "Not enough room on the shelf!",
					type: "danger",
					duration: 900,
					position: "top-center"
				});
				_prodHover(width, height, imgSrc, pid);
				return;
			}

			const div = document.createElement("div");
			const img = new Image();
			//img.onload = _ => {
				img.style.cssText = "width: " + width + "px";
				$("div.activeCell").append(div);
				div.append(img);
				div.className = "product-shelf-entry";
				div.setAttribute("pid", pid);
				div.id = makeUUID(8);
				div.style.cssText = "position: absolute;";
				div.style.top = top + "px";
				div.style.left = left + "px";
				//div.style.height = pos.height + "px";
				div.style.width = width + "px";
			
				addEntryButtons(div, "vertical");
			
				const prodPrice = document.createElement("div");
				prodPrice.className = "prodPrice";
				prodPrice.innerHTML = price;
				if (!pricesVisible) {
					prodPrice.style.display = "none";
				}
				div.append(prodPrice);
				_prodHover(width, height, imgSrc, pid);

				enableVerticalSort(div);

				// find widest product
				let widestProduct = -1;
				$("div.activeCell div.product-shelf-entry").each((i, e) => {
					if (clearPx(e.style.left) === clearPx(div.style.left) && e.offsetWidth > widestProduct) {
						widestProduct = e.offsetWidth;
					}
				});

				// center horizontaly all products in the same column by addapting their left margin
				$("div.activeCell div.product-shelf-entry").each((i, e) => {
					if (clearPx(e.style.left) === clearPx(div.style.left)) {
						e.style.marginLeft = (widestProduct - e.offsetWidth) / 2 + "px";
					}
				});
			//};
			img.src = imgSrc;
			img.className = "productImage";			
		}
	};

	const addProductToShelf = (e) => {
		if ($("div.activeCell").length > 0 && !e.target.classList.contains("sdProdPrice") && !e.target.classList.contains("sdProdName")) {
			const sizeData = e.currentTarget.getAttribute("data-size").split(",");
			const width = parseFloat(sizeData[0]) * 400;
			const height = parseFloat(sizeData[1]) * 400;
			const imgSrc = e.currentTarget.getAttribute("data");
			const pid = e.currentTarget.id;
			const price = e.currentTarget.getAttribute("data-price");
			
			_addProdToShelf(width, height, imgSrc, pid, price);
		}
	};

	const _prodHover = (width, height, imgSrc, pid) => {
		if ($("div.activeCell").length > 0 && $("div.activeCell")[0]._orientation === "horizontal") {
			ghostImg.style.top = "";
			ghostImg.style.bottom = "1px";
			ghostImg.src = imgSrc;
			ghostImg.style.width = width + "px";
			//ghostImg.style.height = height + "px";
			let left = 2;
			let leftMost = 0;
			let lastWidth = 0;
			$("div.activeCell div.product-shelf-entry").each((i, e) => {
				if (e.offsetLeft > leftMost) {
					leftMost = e.offsetLeft;
					lastWidth = e.offsetWidth;
				}
			});
			left = (leftMost + lastWidth) + 2;
			ghostImg.style.left = left + "px";
			$("div.activeCell").append(ghostImg);
		} else {
			let isDifferentProduct = false;
			let mostLeftImg = null;
			let top = 2;
			let mostLeft = 0;
			let widestProduct = -1;

			const entriesCount = $("div.activeCell div.product-shelf-entry").length;
			if (entriesCount > 0) {
				mostLeftImg = $("div.activeCell div.product-shelf-entry").last();
				$("div.activeCell div.product-shelf-entry").each((i, e) => {
					if (e.offsetLeft > mostLeft) {
						mostLeft = e.offsetLeft;
						mostLeftImg = $(e);
					}
				});
				$("div.activeCell div.product-shelf-entry").each((i, e) => {
					if (clearPx(e.style.left) === clearPx(mostLeftImg[0].style.left)) {
						if (e.offsetWidth > widestProduct) {
							widestProduct = e.offsetWidth;
						}
						if (e.offsetTop > mostLeftImg[0].offsetTop) {
							mostLeftImg = $(e);
						}
					}
				});
			}
			
			if (mostLeftImg != null) {
				const mlLeft = $(mostLeftImg).position().left;
				//const mlWidth = $(mostLeftImg).width();
				mostLeft = (!isNaN(mlLeft) ? mlLeft : 2);
				let isTopOverflow = false;
				if ($(mostLeftImg).position().top + $(mostLeftImg).height() + height > $("div.activeCell").height()) {
					isTopOverflow = true;
				}
				if (isTopOverflow /*|| mostLeftImg.attr("pid") !== pid*/) {
					isDifferentProduct = true;
					mostLeft += widestProduct; //mlWidth;
				}
			}
	
			ghostImg.style.left = mostLeft + "px";
			ghostImg.style.top = "1px";
			ghostImg.style.bottom = "";
			ghostImg.src = imgSrc;
			ghostImg.style.width = width + "px";
			//ghostImg.style.height = height + "px";

			if (isDifferentProduct) {
				ghostImg.style.left = mostLeft + "px";
				ghostImg.style.top = top + "px";
			} else {
				let topMost = 0;
				let lastHeight = 0;
				$("div.activeCell div.product-shelf-entry").each((i, e) => {
					const ghostLeft = clearPx(ghostImg.style.left);
					const imgLeft = $(e).position().left;
					if (imgLeft === ghostLeft) {
						if (e.offsetTop > topMost) {
							topMost = e.offsetTop;
							lastHeight = e.offsetHeight;
							//console.log(lastHeight, e.offsetHeight);
						}
					}
				});
				top = (topMost + lastHeight) + 2;
			
				const parentHeight = $("div.activeCell").height();
				if (top + clearPx(ghostImg.style.height) + 2 > parentHeight) {
					ghostImg.style.left = mostLeft + "px";
					ghostImg.style.top = "1px";
				} else {
					ghostImg.style.left = mostLeft + "px";
					ghostImg.style.top = top + "px";
				}
			}
			$("div.activeCell").append(ghostImg);
			
			return { top: top, left: mostLeft, width: width, height: height }; 
		}
	};

	const ghostImg = new Image();
	ghostImg.style.cssText = "position: absolute; bottom: 1px; left: 2px; opacity: 0.5;";
	ghostImg.id = "ghostImg";
	const productHover = (e) => {
		if ($("div.activeCell").length > 0 && !e.target.classList.contains("sdProdPrice")) {
			const sizeData = e.currentTarget.getAttribute("data-size").split(",");
			const width = parseFloat(sizeData[0]) * 400;
			const height = parseFloat(sizeData[1]) * 400;
			const imgSrc = e.currentTarget.getAttribute("data");
			const pid = e.currentTarget.id;

			if (e.currentTarget.querySelector('.btnDeleteProd') != null) {
				e.currentTarget.querySelector('.btnDeleteProd').style.display = "block";
			}

			return _prodHover(width, height, imgSrc, pid);
		}
	};
	const hideGhostImage = (e) => {
		if (e.currentTarget.querySelector('.btnDeleteProd') != null) {
			e.currentTarget.querySelector('.btnDeleteProd').style.display = "none";
		}
		$("#ghostImg").remove();
	};

	let measureUnit = "metric";
	const setUnits = (val) => {
		measureUnit = val;
		if (val === "metric") {
			const w = document.getElementById('shelfWidth').value;
			const h = document.getElementById('shelfHeight').value;
			document.getElementById('shelfWidth').value = w / 3.281;
			document.getElementById('shelfHeight').value = h / 3.281;
			document.getElementById('shelfWidthUnit').innerText = "m";
			document.getElementById('shelfHeightUnit').innerText = "m";
			document.querySelectorAll('.sdProd').forEach(e => {
				let txt = e.getAttribute("data-size-cm");
				e.querySelector('.sdProdDim').innerText = txt;
			});
		} else {
			const w = document.getElementById('shelfWidth').value;
			const h = document.getElementById('shelfHeight').value;
			document.getElementById('shelfWidth').value = w * 3.281;
			document.getElementById('shelfHeight').value = h * 3.281;
			document.getElementById('shelfWidthUnit').innerText = "ft";
			document.getElementById('shelfHeightUnit').innerText = "ft";
			document.querySelectorAll('.sdProd').forEach(e => {
				let txt = e.getAttribute("data-size-inches");
				e.querySelector('.sdProdDim').innerText = txt;
			});
		}
	};
	
	const changeSeparatorColor = (val) => {
		$('.splitter_panel .vsplitter').css('background', 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')');
		$('.splitter_panel .hsplitter').css('background', 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')');
		applyColors();
	};
	useDebouncyEffect(() => {
		changeSeparatorColor(separatorColor);
	}, 10, [separatorColor]);
	
	const changeActiveCellColor = (val) => {
		$('.shelfCell').css('background-color', '');
		$('.activeCell').css('background-color', 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')');
		$('.splitter_panel').css('background-color', 'transparent');
		applyColors();
	};
	useDebouncyEffect(() => {
		changeActiveCellColor(activeCellColor);
	}, 10, [activeCellColor]);

	const changeInactiveCellColor = (val) => {
		$('.shelfCell').css('background-color', 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')');
		$('.splitter_panel').css('background-color', 'transparent');
		applyColors();
	};
	useDebouncyEffect(() => {
		changeInactiveCellColor(inactiveCellColor);
	}, 10, [inactiveCellColor]);

	const changeLinesColor = (val) => {
		$('.ruler-lines').css('background-color', 'rgba(' + val.r + ',' + val.g + ',' + val.b + ',' + val.a + ')');
		applyColors();
	};
	useDebouncyEffect(() => {
		changeLinesColor(linesColor);
	}, 10, [linesColor]);

	const onProdDragOver = (event) => {
		//console.log(event.currentTarget.id);
		if (event.currentTarget.id === "newProductImage") {
            event.stopPropagation();
            event.preventDefault();
            event.currentTarget.style.backgroundColor = '#ff5e33';
            event.target.style.border = "solid black 1px";
            event.dataTransfer.dropEffect = 'copy';
        }
	};
	const onProdDragLeave = (event) => {
        if (event.currentTarget.id === "newProductImage") {
            event.stopPropagation();
            event.preventDefault();
            event.currentTarget.style.backgroundColor = 'rgb(255, 146, 49)';
			event.target.style.border = "1px dashed rgb(38 38 38)";
        }
	};
	const onProdDrop = event => {
        event.stopPropagation();
        event.preventDefault();
        if (event.currentTarget.id === "newProductImage") {            
			event.currentTarget.style.backgroundColor = 'rgb(255, 146, 49)';		
			event.target.style.border = "1px dashed rgb(38 38 38)";
        
			const files = event.dataTransfer.files;
			/* if (files.length > 1) {
				Notify({
					title: "Error",
					message: "You can only drop one file at a time!",
					type: "danger",
					duration: 900,
					position: "top-center"
				});
				return;
			} */
			const curtain = document.createElement("div");
			curtain.style.cssText = "position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999999; background-color: rgba(0,0,0,0.5); overflow: hidden";
			const win = document.createElement("div");
			win.style.cssText = "width: 60%; height: 70%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: #fff; border-radius: 5px; padding: 10px; overflow: hidden; min-width: 1150px;";
			const title = document.createElement("div");
			title.style.cssText = "font-size: 20px; font-weight: bold; text-align: center; margin-bottom: 10px;";
			title.innerText = "Upload images";
			win.appendChild(title);
			const close = document.createElement("div");
			close.style.cssText = "position: absolute; top: 5px; right: 5px; width: 20px; height: 20px; cursor: pointer;";
			close.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="20px" height="20px"><path d="M0 0h24v24H0z" fill="none"/><path fill="#000000" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>';
			close.onclick = _ => {
				curtain.remove();
			};
			win.appendChild(close);
			const header = document.createElement("div");
			header.style.cssText = "width: 100%;height: 40px;border-bottom: 1px solid rgb(170, 170, 170);border-top: 1px solid rgb(170, 170, 170);margin-bottom: 1px;display: flex;align-items: center;background: #eee;font-weight: bold;font-size: 13px;";
			header.innerHTML = "<div style='width: 120px;text-align: center;'>Image</div><div style='width: 29%;text-align: center;'>Name</div><div style='width: 27%;text-align: center;'>Dimensions</div><div style='width: 21%;text-align: center;'>Price</div><div style='width: 9%;text-align: center;'>Uploaded</div><div style='width: 5%;text-align: center;'>Remove</div>";
			win.appendChild(header);
			const content = document.createElement("div");
			content.style.cssText = "width: 100%; height: calc(100% - 130px); overflow: auto; padding: 10px;";
			curtain.appendChild(win);
			win.appendChild(content);
			document.body.appendChild(curtain);
			Object.keys(files).forEach(key => {
				const file = files[key];
				const div = document.createElement("div");
				div.className = "newProductEntry";
				div.style.cssText = "width: 100%;height: 80px;margin-bottom: 12px;position: relative;display: flex;align-items: center;justify-content: space-between;";
				const divImg = document.createElement("div");
				divImg.style.cssText = "height: 80px;width: 100px;float: left;margin-right: 10px;display: flex;border: 1px solid rgb(170, 170, 170);padding: 4px;place-content: center center;flex-wrap: wrap-reverse;";
				const img = new Image();
				img.src = URL.createObjectURL(file);
				img.style.cssText = "max-width: 90px; max-height: 70px";
				img._file_ = file;
				divImg.appendChild(img);
				
				const name = document.createElement("input");
				name.type = "text";
				let fileExt  = file.name.substring(file.name.lastIndexOf('.') + 1);
				name.placeholder = "Name";
				name.value = file.name.replace('.' + fileExt, '');
				name.style.cssText = "width: 290px; height: 40px; border-radius: 20px; margin-left: 10px; border-color: rgb(193 193 193); margin-top: 7px; font-size: 15px;";

				const size = document.createElement("input");
				size.type = "text";
				size.placeholder = "Dimensions";
				size.value = "5x5x5cm";
				size.style.cssText = "width: 290px; height: 40px; border-radius: 20px; margin-left: 10px; border-color: rgb(193 193 193); margin-top: 7px; font-size: 15px;";

				const price = document.createElement("input");
				price.type = "text";
				price.placeholder = "Price";
				price.value = "$1.99";
				price.style.cssText = "width: 210px; height: 40px; border-radius: 20px; margin-left: 10px; border-color: rgb(193 193 193); margin-top: 7px; font-size: 15px;";
				
				const uploaded = document.createElement("div");
				uploaded.style.cssText = "width: 36px; height: 36px; margin-top: 31px; margin-left: 6%;";
				const del = document.createElement("div");

				del.style.cssText = "width: 20px; height: 20px; cursor: pointer; float: right; margin-top: -2px;";
				// add trash can icon to delete button
				del.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="25px" height="25px" viewBox="0 0 24 24"><path d="M22,5H17V2a1,1,0,0,0-1-1H8A1,1,0,0,0,7,2V5H2A1,1,0,0,0,2,7H3.061L4,22.063A1,1,0,0,0,5,23H19a1,1,0,0,0,1-.937L20.939,7H22a1,1,0,0,0,0-2ZM9,3h6V5H9Zm9.061,18H5.939L5.064,7H18.936ZM9,11v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm4,0v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm3-1a1,1,0,0,1,1,1v6a1,1,0,0,1-2,0V11A1,1,0,0,1,16,10Z"/></svg>';
				del.onclick = _ => {
					div.remove();
				};
				div.append(divImg);
				div.append(name);
				div.append(size);
				div.append(price);
				div.append(uploaded);
				div.append(del);
				content.append(div);
			});

			const footer = document.createElement("div");
			footer.style.cssText = "width: 100%;height: 59px;border-top: 1px solid rgb(170, 170, 170);/* margin-top: 10px; */display: flex;align-items: center;justify-content: flex-end;";
			const cancel = document.createElement("div");
			cancel.style.cssText = "width: 100px;height: 40px;border-radius: 20px;float: right;margin-right: 10px;border-color: rgb(170, 170, 170);cursor: pointer;text-align: center;line-height: 40px;background: #eee;";
			cancel.innerText = "Cancel";
			cancel.onclick = _ => {
				curtain.remove();
			};
			const upload = document.createElement("div");
			upload.style.cssText = "width: 160px;height: 40px;border-radius: 20px;float: right;margin-right: 10px;border-color: rgb(170, 170, 170);cursor: pointer;text-align: center;line-height: 40px;background-color: rgb(51, 51, 51);color: rgb(255, 255, 255);";
			upload.innerText = "Upload products";
			upload.onclick = _ => {
				const newEntries = [];
				document.querySelectorAll(".newProductEntry").forEach(e => {
					const img = e.querySelector("img");
					const name = e.querySelector("input:nth-child(2)").value;
					const size = e.querySelector("input:nth-child(3)").value;
					const price = e.querySelector("input:nth-child(4)").value;
					if (img && name /* && size && price */) {
						newEntries.push({
							parent: img.parentElement,
							image: img._file_,
							name: name,
							size: size,
							price: price
						});
					}
				});

				const uploadImage = _ => {
					if (newEntries.length > 0) {
						const entry = newEntries.pop();
						uploadProductImage(entry, "projprodimg").then(res => {
							entry.parent.parentElement.remove();
							uploadImage();
						});
					} else {
						curtain.remove();
						Notify({
							title: "Success",
							message: "Images uploaded successfully!",
							type: "success",
							duration: 900,
							position: "top-center"
						});
					}
				};

				uploadImage();

				/* //console.log("upload");
				const files = event.dataTransfer.files;
				const formData = new FormData();
				Object.keys(files).forEach(key => {
					const file = files[key];
					const div = content.querySelector("div:nth-child(" + (parseInt(key) + 1) + ")");
					const name = div.querySelector("input:nth-child(2)").value;
					const price = div.querySelector("input:nth-child(3)").value;
					const size = div.querySelector("input:nth-child(4)").value;
					formData.append("files[]", file);
					formData.append("names[]", name);
					formData.append("prices[]", price);
					formData.append("sizes[]", size);
				});
				formData.append("shelfId", shelfId);
				formData.append("shelfName", shelfName);
				formData.append("shelfWidth", shelfWidth);
				formData.append("shelfHeight", shelfHeight);
				formData.append("shelfOrientation", shelfOrientation);
				formData.append("shelfBackgroundImage", shelfBackgroundImage);
				formData.append("shelfBackgroundColor", shelfBackgroundColor);
				formData.append("shelfInactiveCellColor", shelfInactiveCellColor);
				formData.append("shelfActiveCellColor", shelfActiveCellColor);
				formData.append("shelfLinesColor", shelfLinesColor);
				formData.append("shelfSeparatorColor", shelfSeparatorColor);
				formData.append("shelfPricesVisible", shelfPricesVisible);
				formData.append("shelfPricesColor", shelfPricesColor);
				formData.append("shelfPricesFont", shelfPricesFont);
				formData.append("shelfPricesFontSize", shelfPricesFontSize);
				formData.append("shelfPricesFontWeight", shelfPricesFontWeight);

				$.ajax({
					url: "uploadMultipleImages.php",
					type: "POST",
					data: formData,
					processData: false,
					contentType: false,
					success: function (res) {
						//console.log(res);
						curtain.remove();
						Notify({
							title: "Success",
							message: "Images uploaded successfully!",
							type: "success",
							duration: 900,
							position: "top-center"
						});
						//window.location.reload();

						// add new products to the list
						const products = JSON.parse(res);
						products.forEach(p => {
							const div = document.createElement("div");
							div.className = "sdProd";
							div.setAttribute("data", p.image);
							div.setAttribute("data-size", p.size);
							div.setAttribute("data-size-cm", p.sizeCm);
							div.setAttribute("data-size-inches", p.sizeInches);
							div.setAttribute("data-price", p.price);
							div.setAttribute("data-name", p.name);
							div.setAttribute("data-id", p.id);
							div.setAttribute("data-orientation", p.orientation);
							div.setAttribute("data-orientation2", p.orientation2);
							div.setAttribute("data-orientation3", p.orientation3);
							div.setAttribute("data-orientation4", p.orientation4);
							 
							const img = new Image();
							img.src = p.image;
							img.className = "sdProdImg";
							div.appendChild(img);
							const name = document.createElement("div");
							name.className = "sdProdName";
							name.innerText = p.name;
							div.appendChild(name);
							const price = document.createElement("div");
							price.className = "sdProdPrice";
							price.innerText = p.price;
							div.appendChild(price);
							const dim = document.createElement("div");
							dim.className = "sdProdDim";
							dim.innerText = p.sizeCm;
							div.appendChild(dim);
							div.onclick = addProductToShelf;
							div.ondragstart = onProdDragStart;
							div.ondragend = hideGhostImage;
							div.ondragover = onProdDragOver;
							div.ondragleave = onProdDragLeave;
							div.ondrop = onProdDrop;
							document.getElementById("productsList").appendChild(div);
						});
					},
					error: function (err) {
						console.log(err);
						curtain.remove();
						Notify({
							title: "Error",
							message: "Error uploading images!",
							type: "danger",
							duration: 900,
							position: "top-center"
						});
					}
				}); */
			};
			footer.appendChild(cancel);
			footer.appendChild(upload);
			win.appendChild(footer);

				/* const fileName = f.name.toLowerCase();
				if (fileName.endsWith(".png") || fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
					uploadedProductImageFile = f;
					const img = document.getElementById("imgNewProd");
					img.src = URL.createObjectURL(f);
					img.style.cssText = "position: relative; margin: auto; width: 100%;";
					//uploadProductImage(files[0], "projprodimg");
					showUploadNewProduct();
				}
			}); */
        }
	};

	const onDragOver = (event) => {
        if (event.target.id === "shelfBackgroundImage") {
            event.stopPropagation();
            event.preventDefault();
            event.target.style.backgroundColor = '#ff5e33';
            event.target.style.border = "solid black 1px";
            event.dataTransfer.dropEffect = 'copy';
        }
    };
    const onDragLeave = (event) => {
        if (event.target.id === "shelfBackgroundImage") {
            event.stopPropagation();
            event.preventDefault();
            event.target.style.backgroundColor = 'rgb(255, 146, 49)';
			event.target.style.border = "1px dashed rgb(38 38 38)";
        }
    };
    const onDrop = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.id === "shelfBackgroundImage") {            
            event.target.style.backgroundColor = 'rgb(255, 146, 49)';
			event.target.style.border = "1px dashed rgb(38 38 38)";
        
			const files = event.dataTransfer.files;
			const fileName = files[0].name.toLowerCase();
			if (fileName.endsWith(".png") || fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
				uploadedShelfImageFile = files[0];
				if (document.getElementById("shelfBkgImage") != null) {
					document.getElementById("shelfBkgImage").src = URL.createObjectURL(files[0]);
					document.getElementById("shelfBkgImageThumbnail").src = URL.createObjectURL(files[0]);
				} else {
					$("#shelfBackgroundImage").text("");
					const img = new Image();
					img.src = URL.createObjectURL(files[0]);
					img.id = "shelfBkgImage";
					img.style.cssText = "position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;";
					$("#rootShelfCont").prepend(img);

					const img2 = new Image();
					img2.src = URL.createObjectURL(files[0]);
					img2.id = "shelfBkgImageThumbnail";
					img2.style.cssText = "position: relative; margin: auto; height: 100%;";
					$("#shelfBackgroundImage").prepend(img2);

					const img3 = new Image();
					img3.onclick = () => {
						if (document.getElementById("shelfBkgImage") != null) {
							document.getElementById("shelfBkgImage").remove();							
							document.getElementById("shelfBkgImageThumbnail").remove();
							document.getElementById("shelfBkgImageClose").remove();
							$("#shelfBackgroundImage").text("Drop image here");
						}
					};
					img3.src = "img/close.png";
					img3.style.cssText = "height: 17px; position: absolute; top: 4px; right: 4px; width: 17px; cursor: pointer; z-index: 5; padding: 0;";
					img3.id = "shelfBkgImageClose";
					$("#shelfBackgroundImage").prepend(img3);
				}

				uploadShelfImage();
			}
        }
	};

	const setShelfBackground = (image) => {
		if (document.getElementById("shelfBkgImage") != null) {
			document.getElementById("shelfBkgImage").src = image.src;
			document.getElementById("shelfBkgImageThumbnail").src = image.src;
		} else {
			$("#shelfBackgroundImage").text("");
			const img = new Image();
			img.src = image.src;
			img.id = "shelfBkgImage";
			img.style.cssText = "position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;";
			$("#rootShelfCont").prepend(img);

			const img2 = new Image();
			img2.src = image.src;
			img2.id = "shelfBkgImageThumbnail";
			img2.style.cssText = "position: relative; margin: auto; height: 100%;";
			$("#shelfBackgroundImage").prepend(img2);

			const img3 = new Image();
			img3.onclick = () => {
				if (document.getElementById("shelfBkgImage") != null) {
					document.getElementById("shelfBkgImage").remove();							
					document.getElementById("shelfBkgImageThumbnail").remove();
					document.getElementById("shelfBkgImageClose").remove();
					$("#shelfBackgroundImage").text("Drop image here");
				}
			};
			img3.src = "img/close.png";
			img3.style.cssText = "height: 17px; position: absolute; top: 4px; right: 4px; width: 17px; cursor: pointer; z-index: 5; padding: 0;";
			img3.id = "shelfBkgImageClose";
			$("#shelfBackgroundImage").prepend(img3);
		}
	};

	const layoutToJSON = (root, exclude, layoutOnly) => {
		const data = {};
		if (root == null) {
			root = "rootShelf";
		}
		const rootEl = document.getElementById(root);		
		$('div.shelfCell', rootEl).each((e, i) => {
			if (exclude != null && i.id === exclude) {
				return;
			}

			if (i._splitter) {
				const id = i._splitter._id;
				const panel1Data = [];
				$(i._splitter._panels[0][0]).find('.product-shelf-entry').each((i, item) => {
					panel1Data.push({
						id: item.getAttribute("pid"), 
						c: $(item).find('.productImage').length,
						t: $(item).css("top"),
						b: $(item).css("bottom"),
						l: $(item).css("left"),
						w: $(item)[0].clientWidth + "px",
						h: $(item)[0].clientHeight + "px"
					});
				});
				const panel2Data = [];
				$(i._splitter._panels[1][0]).find('.product-shelf-entry').each((i, item) => {
					panel2Data.push({
						id: item.getAttribute("pid"),
						c: $(item).find('.productImage').length,
						t: $(item).css("top"),
						b: $(item).css("bottom"),
						l: $(item).css("left"),
						w: $(item)[0].clientWidth + "px",
						h: $(item)[0].clientHeight + "px"
					});
				});
				
				data[id] = {
					parent: i._splitter[0].id === exclude ? root : i._splitter[0].id,
					orientation: i._splitter.orientation,
					position: i._splitter.orientation === 'horizontal' ? i._splitter._panels[0][0].offsetHeight : i._splitter._panels[0][0].offsetWidth,
					panels: [{
						id: i._splitter._panels[0][0].id,
						size: i._splitter.orientation === 'horizontal' ? i._splitter._panels[0][0].offsetHeight : i._splitter._panels[0][0].offsetWidth,
						data: panel1Data,
						orientation: i._splitter._panels[0][0]._orientation
					}, {
						id: i._splitter._panels[1][0].id,
						size: i._splitter.orientation === 'horizontal' ? i._splitter._panels[1][0].offsetHeight : i._splitter._panels[1][0].offsetWidth,
						data: panel2Data,
						orientation: i._splitter._panels[1][0]._orientation
					}]
				};

				if (layoutOnly) {
					delete data[id].panels[0].data;
					delete data[id].panels[1].data;
				}
			}			
		});

		return data;
	};

	const toggleExportJSON = () => {
		const x = document.getElementById("jsonExport");

		if (x.style.bottom === "-300px") {
			$(x).animate({ bottom: "0px" }, 100);
		} else {
			$(x).animate({ bottom: "-300px" }, 100);
		}		
		
		x.innerText = JSON.stringify(layoutToJSON(), null, 2);
	};

	const rebuildCell = (cell, item, orient) => {
		const product = $("#products").find("#" + item.id)[0];
				
		const sizeData = product.getAttribute("data-size").split(",");
		const width = parseFloat(sizeData[0]) * 400;
		const height = parseFloat(sizeData[1]) * 400;

		const div = document.createElement("div");
		cell.appendChild(div);
		div.className = "product-shelf-entry";
		div.setAttribute("pid", item.id);
		div.id = makeUUID(8);
		div.style.position = "absolute";
		div.style.left = item.l;
		div.style.width = item.w;
		div.style.height = item.h;
		
		if (orient === "horizontal") {
			addEntryButtons(div, "horizontal");
			enableHorizontalSort(div);
			div.style.bottom = item.b;
		} else {
			addEntryButtons(div, "vertical");
			enableVerticalSort(div);
			div.style.top = item.t;
		}

		const prodPrice = document.createElement("div");
		prodPrice.className = "prodPrice";
		prodPrice.innerText = product.getAttribute("data-price");
		if (!pricesVisible) {
			prodPrice.style.display = "none";
		}
		div.append(prodPrice);

		let isStacked = false;
		if (item.c > 1 && clearPx(item.h) > height) {
			isStacked = true;
		}

		for (let i = 0; i < item.c; ++i) {
			const img = new Image();
			img.onload = _ => {
				div.append(img);
				img.className = "productImage";
				img.style.cssText = "position: absolute; width: " + width + "px;";// height: " + height + "px;";
				if (orient === "horizontal") {
					if ((item.c * img.clientHeight) + ((item.c - 1) * verticalSpacing) === clearPx(item.h)) {
						img.style.bottom = (i * (img.clientHeight + verticalSpacing)) + "px";
						img.style.left = "0px";
					} else {
						img.style.left = (i * 10) + "px";
						img.style.bottom = "0px";
					}
				} else {
					img.style.top = (i * 10) + "px";
				}
			};
			img.src = product.getAttribute("data");
		}
	};

	const _fixVerticalItemsAlignment = (cell) => {
		// find widest product
		let columns = {};
		$("div.product-shelf-entry", cell).each((i, e) => {
			if (clearPx(e.style.top) <= 5 && columns[clearPx(e.style.left)] === undefined) {
				columns[clearPx(e.style.left)] = {
					widestProduct: e.offsetWidth,
					allEntries: [e]
				};
			}
		});		
		$("div.product-shelf-entry", cell).each((i, e) => {
			if (columns[clearPx(e.style.left)].allEntries.indexOf(e) === -1) {
				columns[clearPx(e.style.left)].allEntries.push(e);
				columns[clearPx(e.style.left)].allEntries.forEach(e => {
					if (e.offsetWidth > columns[clearPx(e.style.left)].widestProduct) {
						columns[clearPx(e.style.left)].widestProduct = e.offsetWidth;
					}
				});
			}
		});
		//console.log(columns);

		Object.keys(columns).forEach(key => {
			const col = columns[key];
			const entries = col.allEntries;
			const widest = col.widestProduct;
			entries.forEach((e, i) => {
				e.style.marginLeft = (widest - e.offsetWidth) / 2 + "px";
			});
		});
	};

	const rebuildShelf = (data, parent) => {
		setTimeout(() => {
			data = JSON.parse(data);
			Object.keys(data).forEach(key => {
				const root = $("#" + data[key].parent);
				const orientation = data[key].orientation;
				const position = data[key].position;
				const panels = data[key].panels;

				root.html("<div class='shelfCell'></div><div class='shelfCell'></div>");
				let splitter = root.split({
					orientation: orientation,
limit: 50,
					position: position,
					/* onDrag: event => {
						onSpliterDrag(event, orientation);
						showActiveCellControls();
					},
					onDragEnd: event => {
						$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
							if ($(e).css("display") === "none") {
								$(e).remove();
							}
						});
					} */
				});
				splitter.limit.leftUpper = splitter.limit.rightBottom = 10;
				splitter._id = key;
				allSplitters[key] = splitter;
				let newActiveCell1 = $("#" + data[key].parent + " div.shelfCell")[0];
				let newActiveCell2 = $("#" + data[key].parent + " div.shelfCell")[1];
				newActiveCell1.id = panels[0].id;
				newActiveCell2.id = panels[1].id;
				newActiveCell1._orientation = panels[0].orientation;
				newActiveCell2._orientation = panels[1].orientation;
				newActiveCell1._splitter = splitter;
				newActiveCell2._splitter = splitter;
				$("div.shelfCell").removeClass("activeCell");
				$(newActiveCell1).toggleClass("activeCell");

				let orient = panels[0].orientation;
				if (panels[0].data) {
					panels[0].data.forEach(item => {
						rebuildCell(newActiveCell1, item, orient);
					});
					if (orient === "vertical") {
						_fixVerticalItemsAlignment(newActiveCell1);
					}
				}

				orient = panels[1].orientation;
				if (panels[1].data) {
					panels[1].data.forEach(item => {
						rebuildCell(newActiveCell2, item, orient);
					});
					if (orient === "vertical") {
						_fixVerticalItemsAlignment(newActiveCell2);
					}
				}
			});
			Object.keys(allSplitters).forEach(key => {
				allSplitters[key].refresh();
			});
			applyColors();
		}, 10);
	};
	window.rebuildShelf = rebuildShelf;

	const toggleShelfButtonsEnabled = (enabled) => {
		document.getElementById("cellControls").style.pointerEvents = enabled ? "all" : "none";
		document.getElementById("cellControls").style.opacity = enabled ? 1 : 0.3;
		if ($("div.activeCell").attr("id") === "rootShelf") {
			$("#btnDelete, #btnAlign").css("pointer-events", "none");
			$("#btnDelete, #btnAlign").css("opacity", 0.3);
		} else {
			$("#btnDelete, #btnAlign").css("pointer-events", "all");
			$("#btnDelete, #btnAlign").css("opacity", 1);
		}
	};

	const updateProductsSearch = (data) => {
		document.getElementById("allProdList").innerHTML = "";
		Object.keys(data).forEach(key => {
			const product = data[key];
			if (product.dim.indexOf("x") > -1) {
				const div = document.createElement("div");
				div.className = "sdProd";
				div.id = product._id;
				div.setAttribute("data", IMGURL + "prodimgs/" + product.pnpng.replace(".png", "_thumb.png"));
				div.setAttribute("data-price", product.price);
				div.setAttribute("data-size", product.dim.indexOf("x") !== -1 ? roundTo(product.dim.split("x")[0] / 39.37, 2) + "," + roundTo(product.dim.split("x")[1] / 39.37, 2) : "10,10");
				div.setAttribute("data-size-inches", product.dim.indexOf("x") !== -1 ? product.dim.split("x")[0] + "x" + product.dim.split("x")[1] + "in" : "10,10");
				div.setAttribute("data-size-cm", (product.dim.indexOf("x") !== -1 ? roundTo(product.dim.split("x")[0] / 0.3937, 2) + "x" + roundTo(product.dim.split("x")[1] / 0.3937, 2) : product.dim) + "cm");
				
				div.addEventListener("click", (e) => {
					addProductToProject(e);
				});
				div.addEventListener("mouseover", (e) => {
					productHover(e);
				});
				div.addEventListener("mouseout", (e) => {
					hideGhostImage();
				});
		
				const img = document.createElement("img");
				img.src = IMGURL + "prodimgs/" + product.pnpng.replace(".png", "_thumb.png");
				div.append(img);

				if (product.lvl1 === "" && product.lvl2 === "" && product.lvl3 === "" && product.lvl4 === "" && product.lvl5 === "" && product.lvl6 === "" && product.brnd === "" && product.asin === "" && product.desc === "") {
					const btnDelete = document.createElement("div");
					btnDelete.className = "btnDeleteProd";
					btnDelete.setAttribute("prodid", product._id);
					btnDelete.title = "Delete product";
					btnDelete.style.cssText = "width: 24px; height: 24px; padding: 2px; position: absolute; top: 5px; right: 5px; display: none; background: red; zIndex: 2;";
					btnDelete.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px" viewBox="0 0 24 24"><path d="M22,5H17V2a1,1,0,0,0-1-1H8A1,1,0,0,0,7,2V5H2A1,1,0,0,0,2,7H3.061L4,22.063A1,1,0,0,0,5,23H19a1,1,0,0,0,1-.937L20.939,7H22a1,1,0,0,0,0-2ZM9,3h6V5H9Zm9.061,18H5.939L5.064,7H18.936ZM9,11v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm4,0v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm3-1a1,1,0,0,1,1,1v6a1,1,0,0,1-2,0V11A1,1,0,0,1,16,10Z"/></svg>`;
					btnDelete.addEventListener("click", (e) => {
						e.stopPropagation(); e.preventDefault(); 
						deleteProduct(e.currentTarget.getAttribute("prodid"));
					});
					div.append(btnDelete);
				}

				const dim = document.createElement("button");
				dim.className = "sdProdDim";
				dim.innerText = (product.dim.indexOf("x") !== -1 ? roundTo(product.dim.split("x")[0] / 0.3937, 2) + "x" + roundTo(product.dim.split("x")[1] / 0.3937, 2) : product.dim) + "cm";
				dim.title = dim.innerText;
				div.append(dim);

				const name = document.createElement("button");
				name.className = "sdProdName";
				name.innerText = product.pname;
				name.title = product.pname;
				div.append(name);

				const price = document.createElement("button");
				price.className = "sdProdPrice";
				price.innerText = "$" + product.price;
				div.append(price);
				
				$("#allProdList").append(div);
			}
		});
		if (data.length === 0) {
			document.querySelector("#allProductsCont").style.background = "url(img/findprodbkg.png) no-repeat center center";
		} else {
			document.querySelector("#allProductsCont").style.background = "unset";
		}
	};

	const updateRowsAndColumns = () => {
		if (document.getElementById("rootShelf").innerHTML !== "") {
			Notify({
				title: "Error",
				message: "Root shelf is not empty!",
				type: "danger",
				duration: 900,
				position: "top-center"
			});
			return;
		}
		const rows = parseInt(document.getElementById("shelfRowsNum").value) - 1;
		const cols = parseInt(document.getElementById("shelfColumnsNum").value) - 1;

		const width = parseInt(document.querySelector("#rootShelf").offsetWidth / (cols + 1));
		const height = parseInt(document.querySelector("#rootShelf").offsetHeight / (rows + 1));

		const splitV = (activeCell) => {
			// split vertically
			activeCell.html("<div class='shelfCell'></div><div class='shelfCell'></div>");
			let splitter = activeCell.split({
				orientation: 'vertical',
limit: 50,
				position: width,
				/* onDrag: event => {
					onSpliterDrag(event, "vertical");
					showActiveCellControls();
				},
				onDragEnd: event => {
					$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
						if ($(e).css("display") === "none") {
							$(e).remove();
						}
					});
				} */
			});
			splitter.limit.leftUpper = splitter.limit.rightBottom = 10;
			splitter._id = makeUUID(8);
			allSplitters[splitter._id] = splitter;
			let newActiveCell1 = $("div.shelfCell", activeCell)[0];
			let newActiveCell2 = $("div.shelfCell", activeCell)[1];
			newActiveCell1.id = makeUUID(8);
			newActiveCell2.id = makeUUID(8);
			newActiveCell1._splitter = splitter;
			newActiveCell2._splitter = splitter;
			newActiveCell1._orientation = "horizontal";
			newActiveCell2._orientation = "horizontal";
			$("div.shelfCell").removeClass("activeCell");
			$(newActiveCell2).toggleClass("activeCell");
			
			showActiveCellControls();
			
			applyColors();

			return $(newActiveCell2);
		};
		const splitH = (activeCell) => {
			activeCell.html("<div class='shelfCell'></div><div class='shelfCell'></div>");
			let splitter = activeCell.split({
				orientation: 'horizontal',
				limit: 50,
				position: height,
				/* onDrag: event => {
					onSpliterDrag(event, "horizontal");
					showActiveCellControls();
				},
				onDragEnd: event => {
					$(event.target.parentElement).find("div.product-shelf-entry").each((i, e) => {
						if ($(e).css("display") === "none") {
							$(e).remove();
						}
					}); 
				} */
			});
			splitter.limit.leftUpper = splitter.limit.rightBottom = 10;
			splitter._id = makeUUID(8);
			allSplitters[splitter._id] = splitter;
			let newActiveCell1 = $("div.shelfCell", activeCell)[0];
			let newActiveCell2 = $("div.shelfCell", activeCell)[1];
			newActiveCell1.id = makeUUID(8);
			newActiveCell2.id = makeUUID(8);
			newActiveCell1._splitter = splitter;
			newActiveCell2._splitter = splitter;
			newActiveCell1._orientation = "horizontal";
			newActiveCell2._orientation = "horizontal";
			$("div.shelfCell").removeClass("activeCell");
			$(newActiveCell2).toggleClass("activeCell");

			showActiveCellControls();
				
			applyColors();	
			
			return $(newActiveCell2);
		};

		const cellsToSplit = [];
		let activeCell = $("#rootShelf");
		for (let i = 0; i < cols; i++) {
			activeCell = splitV(activeCell);
			if (activeCell.hasClass("right_panel") && Math.floor(activeCell.width()) === Math.floor(width)) {
				break;
			}
		}
		$("#rootShelf").find(".shelfCell").each((i, e) => {
			if (Math.floor($(e).width()) <= Math.floor(width)) {
				cellsToSplit.push($(e));
			}
		});
		for (let m = 0; m < cellsToSplit.length; ++m) {
			activeCell = cellsToSplit[m];
			for (let i = 0; i < rows; i++) {
				activeCell = splitH(activeCell);
				if (Math.floor(activeCell.height()) <= Math.floor(height)) {
					break;
				}
			}
		}
	};

	const toggleDataSourceEditor = () => {
		exportDesign((bufferDS) => {
			const container = document.getElementById('excelContent');
			const eview = new ExcelViewer(container, bufferDS, {
				theme: "light",
				themeBtn: true,
				lang: "en"
			});
			window.eview = eview;
			
			$("#excelEditor").toggle();
			const visible = $("#excelEditor").css("display");
			document.getElementById("settings").style.display = visible;
			document.getElementById("shelves").style.display = visible;
			document.getElementById("products").style.display = visible;
			if (visible === 'none') {
				$("#shelfContainer").css({ left: "41px", width: "calc(100% - 41px)" });
			}
		});
	};

	const uploadedProjectImages = [];
	let uploadedProductImageFile = null;
	const uploadProductImage = async (img, type, callback) => {
		return new Promise((resolve, reject) => {
			if (img == null) {
				img = uploadedProductImageFile;
			}
			if (type == null) {
				type = "projprodimg";
			}

			const formData = new FormData();
			let productName = "";
			let productDim = "";
			let productPrice = "";
			let fileName = "";

			if (img.name && img.image /* && img.price && img.size */) {
				fileName = img.image.name;
				formData.append("file[]", img.image, fileName);
				productName = img.name;
				productDim = img.size;
				productPrice = img.price;
			} else {
				const file = img;				
				fileName = file.name;
				formData.append("file[]", file, fileName);

				productName = $("#txtNewProductName").val().trim();
				productDim = $("#txtNewProductDim").val().trim();
				productPrice = $("#txtNewProductPrice").val().trim();

				if (productName === "") {
					Notify({
						title: "Error",
						message: "Please enter product name!",
						type: "danger",
						duration: 900,
						position: "top-center"
					});
					reject();
				}
				if (productDim === "") {
					Notify({
						title: "Error",
						message: "Please enter product dimensions!",
						type: "danger",
						duration: 900,
						position: "top-center"
					});
					reject();
				}
				if (productPrice === "") {
					Notify({
						title: "Error",
						message: "Please enter product price!",
						type: "danger",
						duration: 900,
						position: "top-center"
					});
					reject();
				}
			}

			const jsonObject = {
				id: project.id,
				name: productName,
				dim: productDim,
				price: productPrice,
				pnpng: fileName,
				pid: makeUUID(8),
			};
			const blob = new Blob([JSON.stringify(jsonObject)], { type: "application/json" });
			formData.append('file[]', blob, "data");

			let uploadURL = "";
			switch (type) {
				case "projprodimg":
					uploadURL = 'uploadProjectProduct.php';
					break;
				
				default:
					
			}

			/*eslint-disable */
			const uploadFile = (formData) => {
				BlockUI();
				//console.log(formData);
				axios.post(APIURL + uploadURL, formData, {
					onUploadProgress: progressEvent => {
						const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
						//console.log(progress);
					}
				}).then(res => {
					if (res.status === 200) {
						//console.log(res.data);
						if ((res.data + "").indexOf("Fatal error") > -1) {
							Notify({
								title: "ERROR!",
								message: "Fatal error: Out of memory. Image thumbnail is not created!",
								type: "danger",
								duration: 8000
							});
							reject();
						}
						Notify({
							message: "Product successfully added to project!",
							type: "success",
							duration: 8000
						});
						//popboxNewProduct.close("popboxNewProduct");

						uploadedProjectImages.push(fileName);
						
						const div = document.createElement("div");
						div.className = "sdProd";
						div.id = jsonObject.pid;
						div.setAttribute("data", IMGURL + "projprodimgs/" + jsonObject.pnpng.replace(".png", "_thumb.png"));
						div.setAttribute("data-price", jsonObject.price);
						div.setAttribute("data-size", jsonObject.dim.indexOf("x") !== -1 ? roundTo(jsonObject.dim.split("x")[0] / 39.37, 2) + "," + roundTo(jsonObject.dim.split("x")[1] / 39.37, 2) : "10,10");
						div.setAttribute("data-size-inches", jsonObject.dim.indexOf("x") !== -1 ? jsonObject.dim.split("x")[0] + "x" + jsonObject.dim.split("x")[1] + "in" : "10,10");
						div.setAttribute("data-size-cm", (jsonObject.dim.indexOf("x") !== -1 ? roundTo(jsonObject.dim.split("x")[0] / 0.3937, 2) + "x" + roundTo(jsonObject.dim.split("x")[1] / 0.3937, 2) : jsonObject.dim) + "cm");
						
						div.addEventListener("click", (e) => {
							addProductToShelf(e);
						});
						div.addEventListener("mouseover", (e) => {
							productHover(e);
						});
						div.addEventListener("mouseout", (e) => {
							hideGhostImage(e);
						});
				
						const imgDiv = document.createElement("div");
						imgDiv.style.cssText = "width: 98px; height: 98px; padding: 10px; margin-bottom: 5px;";
						const img = document.createElement("img");
						img.src = IMGURL + "projprodimgs/" + jsonObject.pnpng.replace(".png", "_thumb.png");
						imgDiv.append(img);
						div.append(imgDiv);

						const btnDelete = document.createElement("div");
						btnDelete.className = "btnDeleteProd";
						btnDelete.setAttribute("prodid", jsonObject.pid);
						btnDelete.title = "Delete product";
						btnDelete.style.cssText = "width: 24px; height: 24px; padding: 2px; position: absolute; top: 5px; right: 5px; display: none; background: red; zIndex: 2;";
						btnDelete.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px" viewBox="0 0 24 24"><path d="M22,5H17V2a1,1,0,0,0-1-1H8A1,1,0,0,0,7,2V5H2A1,1,0,0,0,2,7H3.061L4,22.063A1,1,0,0,0,5,23H19a1,1,0,0,0,1-.937L20.939,7H22a1,1,0,0,0,0-2ZM9,3h6V5H9Zm9.061,18H5.939L5.064,7H18.936ZM9,11v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm4,0v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm3-1a1,1,0,0,1,1,1v6a1,1,0,0,1-2,0V11A1,1,0,0,1,16,10Z"/></svg>`;
						btnDelete.addEventListener("click", (e) => {
							e.stopPropagation(); e.preventDefault(); 
							deleteProduct(e.currentTarget.getAttribute("prodid"));
						});
						div.append(btnDelete);

						const name = document.createElement("button");
						name.className = "sdProdName";
						name.innerText = jsonObject.name;
						name.title = jsonObject.name;
						div.append(name);
						name.addEventListener("click", showNameEditPopup);

						const price = document.createElement("button");
						price.className = "sdProdPrice";
						price.innerText = jsonObject.price;
						div.append(price);
						price.addEventListener("click", showPriceEditPopup);

						const dim = document.createElement("button");
						dim.className = "sdProdDim";
						dim.innerText = (jsonObject.dim.indexOf("x") !== -1 ? roundTo(jsonObject.dim.split("x")[0] / 0.3937, 2) + "x" + roundTo(jsonObject.dim.split("x")[1] / 0.3937, 2) : jsonObject.dim) + "cm";
						dim.title = dim.innerText;
						div.append(dim);
						dim.addEventListener("click", showDimEditPopup);
						
						$("#productsList").find("div.simplebar-content").append(div);
						
						if (callback) callback();

						resolve();

						UnblockUI();
					}
				}).catch(err => {
					Notify({
						title: "ERROR!",
						message: "Error uploading image: " + err.message,
						type: "danger",
						duration: 8000
					});
					reject();
					UnblockUIError();
				});
			};

			uploadFile(formData);
		});
	};

	const deleteProduct = (id) => {
		// show confirmation dialog
		if (window.confirm("Are you sure you want to delete this product?")) {
			// delete product from project
			const products = id + "," + 'x2x3x4';
			BlockUI();
			// `${APIURL}project.php?action=dprod&id=${project.id}&prods=${productIDs}&t=${token}`
			axios.get(`${APIURL}project.php?action=dprod&id=${project.id}&prods=${products}&t=${token}`).then(res => {
				console.log(res);
				if (res.status === 200) {
					Notify({
						message: "Product successfully deleted from project!",
						type: "success",
						duration: 8000
					});
					$("#" + id).remove();
					UnblockUI();
				}
			}).catch(err => {
				Notify({
					title: "ERROR!",
					message: "Error deleting product: " + err.message,
					type: "danger",
					duration: 8000
				});
				UnblockUIError();
			});
		}
	};

	const uploadedProjectShelves = [];
	let uploadedShelfImageFile = null;
	const uploadShelfImage = (img, callback) => {
		if (img == null) {
			img = uploadedShelfImageFile;
		}
		const file = img;
		const formData = new FormData();
		const fileName = file.name;
		formData.append("file[]", file, fileName);

		let uploadURL = 'uploadProjectShelf.php';

		/*eslint-disable */
		const uploadFile = (formData) => {
			BlockUI();
			//console.log(formData);
			axios.post(APIURL + uploadURL, formData, {
				onUploadProgress: progressEvent => {
					const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
					//console.log(progress);
				}
			}).then(res => {
				if (res.status === 200) {
					//console.log(res.data);
					if ((res.data + "").indexOf("Fatal error") > -1) {
						Notify({
							title: "ERROR!",
							message: "Fatal error: Out of memory. Image thumbnail is not created!",
							type: "danger",
							duration: 8000
						});
						return;
					}
					Notify({
						message: "Shelf image successfully added to project!",
						type: "success",
						duration: 8000
					});
					
					uploadedProjectShelves.push(fileName);
					
					const img = new Image();
					img.src = IMGURL + "projshelvimgs/" + fileName.replace(".png", "_thumb.png");
					img.onclick = (e) => {
						setShelfBackground(e.target);
					};					
					$("#shelvesList div.simplebar-content").append(img);
					
					if (callback) callback();

					UnblockUI();
				}
			}).catch(err => {
				Notify({
					title: "ERROR!",
					message: "Error uploading image: " + err.message,
					type: "danger",
					duration: 8000
				});
				UnblockUIError();
			});
		};

		uploadFile(formData);
	};

	const filterProducts = () => {
		const val = document.getElementById("txtProdSearch").value.trim();
		if (val !== "") {
			$(".sdProdName").each((i, el) => {
				el.parentElement.style.display = "none";
				if (el.innerText.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
					el.parentElement.style.display = "block";
				}
			});
		} else {
			$(".sdProdName").parent().css("display", "block");
		}
	};

	const increaseHorizontalSpacing = (cell) => {
		if ($(cell).hasClass("splitter_panel")) return;

		const val = parseInt(document.getElementById('prodSpaceHorizontal').value);

		if ($("div.product-shelf-entry", cell).length > 0) {
			const children = $("div.product-shelf-entry", cell).get();
			children.sort((a, b) => {
				return a.offsetLeft - b.offsetLeft;
			});
			let lastLeft = children[0];
			children.forEach((el, i) => {
				if (i > 0) {
					$(el).css({ left: (lastLeft.offsetLeft + lastLeft.offsetWidth + val) + "px" });
					lastLeft = el;
					if (el.offsetLeft + el.offsetWidth > $(cell).width()) {
						$(el).remove();
					}
				}
			});
		}
	};

	const increaseVerticalSpacing = (cell) => {
		if ($(cell).hasClass("splitter_panel")) return;

		const val = parseInt(document.getElementById('prodSpaceVertical').value);

		const collOffsets = {};
		if ($("div.product-shelf-entry", cell).length > 0) {
			const children = $("div.product-shelf-entry", cell).get();
			children.sort((a, b) => {
				return a.offsetTop - b.offsetTop;
			});
			children.forEach((el, i) => {
				if (collOffsets[el.style.left] == null) {
					collOffsets[el.style.left] = [];
				}
				collOffsets[el.style.left].push(el);
			});
		}

		let lastEl = null;
		Object.keys(collOffsets).forEach((key, idx) => {
			const arr = collOffsets[key];
			lastEl = arr[0];
			for (let m = 0; m < arr.length; ++m) {
				if (m > 0) {
					const el = arr[m];
					$(el).css({ top: lastEl.offsetTop + lastEl.offsetHeight + val });
					lastEl = el;
					if (el.offsetTop + el.offsetHeight > cell.offsetHeight) {
						$(el).remove();
					}
				}
			}
		});
	};

	const updateHorizontalSpacing = () => {
		const val = parseInt(document.getElementById('prodSpaceHorizontal').value);
		setHorizontalSpacing(val);
		$("div.shelfCell").each((i, el) => {
			if (el._orientation === "horizontal") {
				increaseHorizontalSpacing(el);
			}
		});
	};
	const updateVerticalSpacing = () => {
		const val = parseInt(document.getElementById('prodSpaceVertical').value);
		setVerticalSpacing(val);
		$("div.shelfCell").each((i, el) => {
			if (el._orientation === "vertical") {
				increaseVerticalSpacing(el);
			}
		});
	};

    
	return (
		<ShelvesDesignerStyles>
		<nav class="navbar">
			<ul class="navbar__menu" id="popupButtons">
				<li class="navbar__item" id="imageGallery" onClick={toggleProductsLib}>
					<span class="navbar__link"><svg width="24" height="24" viewBox="0 0 19 28" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
					<path d="M11 8H1V27H11V8Z"  stroke-linejoin="round"/>
					<path d="M11 7.20229L14.5 5L18 7.20229V27H11"  stroke-linejoin="round"/>
					<path d="M4 4V1H14V5"  stroke-linejoin="round"/>
					<path d="M5.5 18C6.88071 18 8 16.8807 8 15.5C8 14.1193 6.88071 13 5.5 13C4.11929 13 3 14.1193 3 15.5C3 16.8807 4.11929 18 5.5 18Z"  stroke-linejoin="round"/>
					</svg><span>Products</span></span>
				</li>
				<li class="navbar__item" id="shelfGallery" onClick={toggleShelvesLib}>
				<span class="navbar__link"><svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
					<path d="M4.54541 30.909V11.0908C4.54541 9.98625 5.44084 9.09082 6.54541 9.09082H33.4545C34.5591 9.09082 35.4545 9.98625 35.4545 11.0908V30.909"  stroke-linejoin="round"/>
					<path d="M10 17.2725H30" />
					<path d="M10 26.3633H30" />
					<path d="M12.7275 17.2731V13.6367" />
					<path d="M17.2725 17.2731V13.6367" />
					<path d="M22.7275 17.2731V13.6367" />
					<path d="M12.7275 26.3639V22.7275" />
					<path d="M17.2725 26.3639V22.7275" />
					<path d="M22.7275 26.3639V22.7275" />
					<path d="M27.2725 26.3639V22.7275" />
					</svg><span>Shelf images</span></span>
				</li>
				<li class="navbar__item" id="shelvesSettings" onClick={toggleSettings}>
					<span class="navbar__link"><svg width="28" height="28" viewBox="0 0 38 33" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
						<path d="M13.5983 22.6283C14.8292 22.6283 15.827 21.6305 15.827 20.3996C15.827 19.1687 14.8292 18.1709 13.5983 18.1709C12.3675 18.1709 11.3696 19.1687 11.3696 20.3996C11.3696 21.6305 12.3675 22.6283 13.5983 22.6283Z" />
						<path d="M5.79785 18.1717C6.17195 16.8984 6.84111 15.7325 7.7504 14.7696L6.19552 11.2837L9.68704 9.25684L11.918 12.343C13.203 12.0352 13.9848 12.0352 15.2699 12.343" />
						<path d="M11.9268 12.343C13.2105 12.0332 13.986 12.0332 15.2698 12.343L17.5047 9.25684L20.9895 11.2837L19.4478 14.7696C20.3563 15.7325 21.025 16.8984 21.3988 18.1717" />
						<path d="M21.3989 23.1863C21.0248 24.4596 20.3556 25.0683 19.4463 26.0311L21.0012 29.5171L17.5097 31.5439L15.2788 28.4577C13.9938 28.7656 13.2119 28.7656 11.9269 28.4577" />
						<path d="M15.27 28.4577C13.9862 28.7676 13.2107 28.7676 11.927 28.4577L9.69207 31.5439L6.20729 29.5171L7.74902 26.0311C6.84045 25.0683 6.17182 24.4596 5.79801 23.1863" />
						<path d="M7.63514 25.9462C6.72492 24.9893 6.33717 24.3177 5.96361 23.051L2.17343 22.6586L2.18632 18.6273L5.97612 18.2195C6.35569 16.9512 6.55392 15.7892 7.4697 14.8288" />
						<path d="M19.5679 14.828C20.4782 15.7849 20.7005 16.8858 21.074 18.1525L24.8642 18.5449L24.8513 22.5762L21.0615 22.984C20.6819 24.2523 20.4837 25.4143 19.5679 26.3747" />
						<path d="M30.1239 12.877L27.8955 15.9426L24.4119 13.9333L25.9531 10.4705L25.5757 9.06866L22.5132 6.84087L24.5231 3.35821L27.9869 4.899L29.3891 4.52175L31.6175 1.45605L35.1011 3.4694L33.5599 6.93221L33.9372 8.33004L36.9998 10.5578L34.9898 14.0445L31.5261 12.5037L30.1239 12.877Z" />
						</svg><span>Settings</span></span>
				</li>
				<li class="navbar__item" id="dataSourceToggle" onClick={toggleDataSourceEditor}>
					<span class="navbar__link"><svg width="26" height="24" viewBox="0 0 26 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.777832 1H8.11117C9.40778 1 10.6513 1.51508 11.5681 2.43192C12.485 3.34877 13.0001 4.59227 13.0001 5.88889V23C13.0001 22.0275 12.6137 21.0949 11.9261 20.4073C11.2385 19.7196 10.3058 19.3333 9.33339 19.3333H0.777832V1Z" />
					<path d="M25.2222 1H17.8889C16.5923 1 15.3488 1.51508 14.4319 2.43192C13.5151 3.34877 13 4.59227 13 5.88889V23C13 22.0275 13.3863 21.0949 14.0739 20.4073C14.7616 19.7196 15.6942 19.3333 16.6667 19.3333H25.2222V1Z" /></svg><span>Data source editor</span></span>
				</li>
			</ul>
			<hr style={{ margin: '6px', borderTop: 'solid 2px #aaa' }}/>
			<ul class="navbar__menu" id="cellControls" onclick={splitCellVertical}>
				<li class="navbar__item" id="btnSplitV">
					<span class="navbar__link"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path></svg><span>Split shelf verticaly</span></span>
				</li>
				<li class="navbar__item" id="btnSplitH">
					<span class="navbar__link"><svg style={{ transform: 'rotate(90deg)' }} viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path></svg><span>Split shelf horizontaly</span></span>        
				</li>
				<li class="navbar__item" id="btnAlign">
					<span class="navbar__link"><svg id="shelfAlignSVG" style={{ transform: 'rotate(180deg)' }} viewBox="0 0 12 20" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path class="cls-1" d="M5.75,25.75V.75"/><path class="cls-1" d="M.75,5.75l5-5,5,5"/></svg><span id="msgShelfAlign">Shelf products alignment</span></span>        
				</li>
				<li class="navbar__item" id="btnDelete">
					<span class="navbar__link"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><span>Delete shelf</span></span>
				</li>
			</ul>
			<hr style={{ margin: '6px', borderTop: '2px solid rgb(170, 170, 170)' }}/>
			<ul class="navbar__menu">
				<li class="navbar__item" onClick={showTemplateSaveDialog}>
					<span class="navbar__link"><svg viewBox="0 0 24 28" width="24" height="28" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path class="cls-1" d="M22.76,26.7H13.43a1.33,1.33,0,0,1-1.34-1.33V16a1.33,1.33,0,0,1,1.34-1.34h7.33L24.09,18v7.33a1.33,1.33,0,0,1-1.33,1.33Z"/><path class="cls-1" d="M20.89,26.71v-4.8h-5.6v4.8"/><path class="cls-1" d="M15.29,14.7v3.2h4.8"/><path class="cls-1" d="M12.86,2.71h2a2.08,2.08,0,0,1,1.43.57,1.94,1.94,0,0,1,.59,1.39v6.86M4.79,2.71h-2a2.08,2.08,0,0,0-1.43.57A1.94,1.94,0,0,0,.75,4.67V18.39a1.9,1.9,0,0,0,.59,1.38,2,2,0,0,0,1.43.58H8.82"/><path class="cls-1" d="M11.85.75H5.79a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h6.06a1,1,0,0,0,1-1v-2A1,1,0,0,0,11.85.75Z"/></svg><span>Save shelf template</span></span>        
				</li>
				<li class="navbar__item" onClick={() => { saveDesign(); }}>
					<span class="navbar__link"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg><span>Save shelf design</span></span>        
				</li>
				<li class="navbar__item" onClick={() => { downloadProject(); }}>
					<span class="navbar__link"><svg viewBox="0 0 21.23 24.93" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path class="cls-1" d="M11.85.75V5.68a1.27,1.27,0,0,0,.36.88,1.24,1.24,0,0,0,.87.36H18"/><path class="cls-1" d="M11.85,20.48h8.63m0,0-3.7-3.7m3.7,3.7-3.7,3.7M6.92,23H3.22A2.46,2.46,0,0,1,.75,20.48V3.22A2.46,2.46,0,0,1,3.22.75h8.63L18,6.92l-1,5.32Z"/></svg><span>Download DS</span></span>
				</li>
				<li class="navbar__item" onClick={() => { downloadShelfImage(); }}>
					<span class="navbar__link">
						<svg width="40" height="40" viewBox="0 0 40 40" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1" xmlns="http://www.w3.org/2000/svg">
							<path d="M28.5 23L28.5 31M28.5 31L32 27.2354M28.5 31L25 27.2354"/>
							<path d="M21.4411 29.3922H11.857C9.72686 29.3922 8 27.6653 8 25.5351V12.6783C8 10.5481 9.72686 8.82129 11.857 8.82129H24.7139C26.844 8.82129 28.5709 10.5481 28.5709 12.6783V18.7563" />
							<path d="M8 22.9639L13.1427 17.8212C13.7291 17.257 14.3942 16.96 15.0712 16.96C15.7483 16.96 16.4134 17.257 16.9998 17.8212L21.7917 22.9639" />
							<path d="M20.8569 21.6782L22.1426 20.3925C22.729 19.8283 23.3941 19.5312 24.0711 19.5312C24.7482 19.5312 25.4133 19.8283 25.9997 20.3925" />
							<circle cx="23" cy="14" r="1" fill="#333333"/>
						</svg>
					</span>
				</li>
				<li class="navbar__item" onClick={() => { toggleFullscreen(); }}>
					<span class="navbar__link"><svg isFullScreen="false" id="fullScreenToggle" viewBox="0 0 21.5 21.5" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path class="cls-1" d="M14.08.75h6.67V7.42"/><path class="cls-1" d="M7.42,20.75H.75V14.08"/><path class="cls-1" d="M20.75.75,13,8.53"/><path class="cls-1" d="M.75,20.75,8.53,13"/></svg><span>Toggle fullscreen</span></span>
				</li>
			</ul>
			<ul class="navbar_menu" style={{ position: "absolute", bottom: '0px' }}>
				<li class="navbar__item" onClick={closeShelvesDesigner}>
					<span style={{ background: '#aaaaaa7a' }} class="navbar__link"><svg width="24" height="32" viewBox="0 0 100 110" stroke="currentColor" stroke-width="2" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
					<g>
						<g transform="rotate(180 45.92 61.4395)" id="svg_1">
							<path id="svg_2" d="m81.75,64.617l-40.385,0c-1.738,0 -3.147,-1.423 -3.147,-3.178c0,-1.756 1.409,-3.179 3.147,-3.179l40.383,0l-13.189,-15.105c-1.146,-1.31 -1.025,-3.311 0.271,-4.469c1.297,-1.159 3.278,-1.037 4.425,0.273l17.798,20.383c1.065,1.216 1.037,3.029 -0.011,4.21l-17.788,20.368c-1.146,1.311 -3.128,1.433 -4.425,0.273c-1.296,-1.158 -1.417,-3.16 -0.271,-4.47l13.192,-15.106l0,0zm-10.909,35.012c0,-1.756 1.423,-3.179 3.178,-3.179c1.756,0 3.179,1.423 3.179,3.179l0,14.242c0,2.475 -1.017,4.729 -2.648,6.36c-1.633,1.632 -3.887,2.648 -6.36,2.648l-59.181,0c-2.475,0 -4.73,-1.014 -6.363,-2.646c-1.63,-1.63 -2.646,-3.881 -2.646,-6.362l0,-104.862c0,-2.48 1.013,-4.733 2.644,-6.365c1.631,-1.631 3.884,-2.644 6.365,-2.644l59.18,0c2.479,0 4.731,1.016 6.362,2.646c1.633,1.633 2.646,3.889 2.646,6.363l0,14.241c0,1.755 -1.423,3.178 -3.179,3.178c-1.755,0 -3.178,-1.423 -3.178,-3.178l0,-14.241c0,-0.722 -0.301,-1.385 -0.785,-1.869c-0.482,-0.482 -1.144,-0.783 -1.867,-0.783l-59.179,0c-0.726,0 -1.389,0.3 -1.87,0.782c-0.483,0.481 -0.782,1.144 -0.782,1.87l0,104.862c0,0.724 0.301,1.385 0.783,1.867c0.484,0.484 1.147,0.785 1.869,0.785l59.18,0c0.72,0 1.381,-0.302 1.865,-0.786c0.485,-0.484 0.787,-1.146 0.787,-1.866l0,-14.242l0,0z"/>
						</g>
					</g>
					</svg><span>Close shelf designer</span></span>
				</li>
			</ul>
		</nav>
		<div className="App container mx-auto mt-3 font-thin">
			<div id="shelvesDesigner">
				<div id="rootDiv">
					<div id="shelfContainer">
						<div id="shelfWrap">
							<div id="shelfInnerWrap">
								<div id="rootShelfCont">
									<div id="rootShelf" class="shelfCell">
										
									</div>
								</div>
							</div>
						</div>
						<div id="rulerRef"></div>
					</div>
				</div>
				<div id="products" style={{ display: 'none' }}>
					<div class="tab-container">
						<div className="tabs">
							<a className="tab active">Project products</a>
							<a className="tab">Library</a>
							<a className="tab">Upload products</a>
						</div>
						<div className="tab-panes">
							<div className="tab-pane active">
								<div id="prodCont">
									<div id="prodSearchDiv">
										<input type="text" id="txtProdSearch" onChange={filterProducts} placeholder="Enter product name..." />
									</div>
									<div id="productsList">
										{dataProducts.map((product, i) => (
											product.dim.indexOf("x") > -1 &&	
											<div allData={JSON.stringify(product)} id={product._id} className="sdProd" data={IMGURL + (product.pnpng.indexOf("projprodimgs") === -1 ? "prodimgs/" : "") + product.pnpng.replace(".png", "_thumb.png")} data-price={product.price} data-size={(product.dim.indexOf("x") !== -1 ? roundTo(product.dim.split("x")[0] / (product.dim.indexOf("in") === -1 ? 39.37 : 1), 2) + "," + roundTo(product.dim.split("x")[1] / (product.dim.indexOf("in") === -1 ? 39.37 : 1), 2) : "10,10") } data-size-inches={(product.dim.indexOf("x") !== -1 ? roundTo(product.dim.split("x")[0] / (product.dim.indexOf("in") === -1 ? 39.37 : 1), 2) + "," + roundTo(product.dim.split("x")[1] / (product.dim.indexOf("in") === -1 ? 39.37 : 1), 2) : "10,10") } data-size-cm={(product.dim.indexOf("x") !== -1 ? roundTo(product.dim.split("x")[0] / (product.dim.indexOf("cm") === -1 ? 0.3937 : 1), 2) + "x" + roundTo(product.dim.split("x")[1] / (product.dim.indexOf("cm") === -1 ? 0.3937 : 1), 2) + (product.dim.split("x").length === 3 ? "x" + roundTo(parseFloat(product.dim.split("x")[2]) / (product.dim.indexOf("cm") === -1 ? 0.3937 : 1), 2) : "") : product.dim) + "cm"} onClick={(e) => { addProductToShelf(e) }} onMouseOver={(e) => { productHover(e) }} onMouseOut={(e) => { hideGhostImage(e) }}>
												{product.lvl1 === "" && product.lvl2 === "" && product.lvl3 === "" && product.lvl4 === "" && product.lvl5 === "" && product.lvl6 === "" && product.brnd === "" && product.asin === "" && product.desc === "" &&
													<div style={{ width: '24px', height: '24px', padding: '2px', position: 'absolute', top: '5px', right: '5px', display: 'none', background: 'red', zIndex: 2 }} className="btnDeleteProd" prodid={product._id} onClick={(e) => { e.stopPropagation(); e.preventDefault(); deleteProduct(e.currentTarget.getAttribute("prodid")) }}>
														<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px" viewBox="0 0 24 24"><path d="M22,5H17V2a1,1,0,0,0-1-1H8A1,1,0,0,0,7,2V5H2A1,1,0,0,0,2,7H3.061L4,22.063A1,1,0,0,0,5,23H19a1,1,0,0,0,1-.937L20.939,7H22a1,1,0,0,0,0-2ZM9,3h6V5H9Zm9.061,18H5.939L5.064,7H18.936ZM9,11v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm4,0v6a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0Zm3-1a1,1,0,0,1,1,1v6a1,1,0,0,1-2,0V11A1,1,0,0,1,16,10Z"/></svg>
													</div>
												}
												<div style={{ width: '98px', height: '98px', padding: '10px', marginBottom: '5px' }}>
													<img src={IMGURL + (product.pnpng.indexOf("projprodimgs") === -1 ? "prodimgs/" : "") + product.pnpng.replace(".png", "_thumb.png")} alt={product.pname} />
												</div>
												
												<button onClick={showNameEditPopup} title={product.pname} productId={product._id} className="sdProdName">{product.pname}</button>
												<button onClick={showPriceEditPopup} productId={product._id} className="sdProdPrice">{product.price}</button>
												<button onClick={showDimEditPopup} productId={product._id} className="sdProdDim">{(product.dim.indexOf("x") !== -1 ? roundTo(product.dim.split("x")[0] / (product.dim.indexOf("cm") === -1 ? 0.3937 : 1), 2) + "x" + roundTo(product.dim.split("x")[1] / (product.dim.indexOf("cm") === -1 ? 0.3937 : 1), 2) + (product.dim.split("x").length === 3 ? "x" + roundTo(parseFloat(product.dim.split("x")[2]) / (product.dim.indexOf("cm") === -1 ? 0.3937 : 1), 2) : "") : product.dim) + "cm" }</button>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="tab-pane">
								<div style={{ zoom: '0.8', position: 'relative', margin: 'auto', width: '420px', marginTop: '27px' }}>
									<SearchProducts onSearch={(data) => {
										updateProductsSearch(data);
									}} byNameOnly={true} />
								</div>
								<div id="allProductsCont">
									<div id="allProdList">
										
									</div>
								</div>
							</div>
							<div className="tab-pane">
							<div id="newProductImage" onDragOver={onProdDragOver} onDragLeave={onProdDragLeave} onDrop={onProdDrop}>
								Drop images here
							</div>
							{/* <div>
								<MultiFileUploader />
								<Progress.Circle style={{marginRight:15}} width={80} strokeWidth={2} percent={30} />
								<Progress.Line style={{marginRight:15}} percent={50} status="active" />
							</div> */}
							</div>
						</div>
					</div>
				</div>
				<div id="shelves" style={{ display: 'none' }}>
					<div class="tab-container">
						<div className="tabs">
							<a className="tab active">Shelves</a>
							<a className="tab">Upload shelf image</a>
						</div>
						<div className="tab-panes">
							<div className="tab-pane active">
								<div id="shelvesCont">
									<div id="shelvesList">
										{dataShelves.map((shelf, i) => (							
											<img src={IMGURL + "shelvimgs/" + shelf.img.replace(".png", "_thumb.png")} onClick={(e) => {
												setShelfBackground(e.target);
											}} alt={shelf.img} />
										))}
									</div>
								</div>
							</div>
							<div className="tab-pane">						
								<p style={{ marginBottom: '6px', marginTop: '22px', marginLeft: '20px', fontSize: '13px', marginRight: '20px' }}>
									<div id="shelfBackgroundImage" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
										Drop image(s) here
									</div>
									{/* <div>Test<MultiFileUploader /></div> */}
								</p>
							</div>
						</div>
					</div>
				</div>	
				<div id="settings" style={{ display: 'none' }}>
					<p><b style={{ borderBottom: 'solid 2px #777', width: '84px', display: 'block', textIndent: '12px', fontSize: '16px', fontWeight: 'bold', paddingBottom: '5px', marginTop: '2px', marginBottom: '20px' }}>Settings</b></p>
					<p style={{ fontWeight: 'bold', fontSize: '13px' }}>Shelf size</p>
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '28px' }}>
						<span>Measurement unit:&nbsp;</span>
						<select style={{ padding: '0px 31px 0px 35px', fontSize: '12px', float: 'right', marginRight: '10px', borderRadius: '40px' }} id="units" onChange={(e) => { setUnits(e.target.value) }}>
							<option selected value="metric">Metric</option>
							<option value="imperial">Imperial</option>
						</select>			
					</p>				
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '28px' }}><span style={{ width: '100px', display: 'inline-block' }}>Shelf width:</span><span style={{ float: 'right', marginRight: '10px', width: '14px', textAlign: 'right', marginTop: '4px' }} id="shelfWidthUnit">m</span><input id="shelfWidth" onChange={changeShelfWidth} type="number" min="0.5" max="50" step="0.02" defaultValue="3" style={{ padding: '0px 0px 0px 10px', fontSize: '12px', float: 'right', marginRight: '0px', borderRadius: '40px', width: '97px', textAlign: 'center' }} /></p>
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '28px' }}><span style={{ width: '100px', display: 'inline-block' }}>Shelf height:</span><span style={{ float: 'right', marginRight: '10px', width: '14px', textAlign: 'right', marginTop: '4px' }} id="shelfHeightUnit">m</span><input id="shelfHeight" onChange={changeShelfHeight} type="number" min="0.5" max="5" step="0.02" defaultValue="2" style={{ padding: '0px 0px 0px 10px', fontSize: '12px', float: 'right', marginRight: '0px', borderRadius: '40px', width: '97px', textAlign: 'center' }} /></p>
					<hr style={{ marginTop: '17px', borderColor: '#aaa' }} />
					
					<p><b style={{ width: '138px', display: 'block', fontSize: '13px', fontWeight: 'bold', paddingBottom: '5px', marginTop: '21px', marginBottom: '20px' }}>Rows and columns</b></p>
					
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '30px' }}><span style={{ width: '100px', display: 'inline-block' }}>No. of columns:</span><input id="shelfColumnsNum" type="number" min="1" max="50" step="1" defaultValue="1" style={{ padding: '0px 0px 0px 10px', fontSize: '12px', float: 'right', marginRight: '0px', borderRadius: '40px', width: '97px', textAlign: 'center' }} /></p>
					
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '30px' }}><span style={{ width: '100px', display: 'inline-block' }}>No. of rows:</span><input id="shelfRowsNum" type="number" min="1" max="50" step="1" defaultValue="1" style={{ padding: '0px 0px 0px 10px', fontSize: '12px', float: 'right', marginRight: '0px', borderRadius: '40px', width: '97px', textAlign: 'center' }} /></p>
						
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '28px' }}>
						<button id="btnSetRowsAndColumns" onClick={updateRowsAndColumns}>Set</button>
					</p>
					<hr style={{ marginTop: '17px', borderColor: '#aaa' }} />
					
					<p><b style={{ width: '138px', display: 'block', fontSize: '13px', fontWeight: 'bold', paddingBottom: '5px', marginTop: '21px', marginBottom: '20px' }}>Product spacing</b></p>
					
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '30px' }}><span style={{ width: '100px', display: 'inline-block' }}>Horizontal:</span><input onChange={updateHorizontalSpacing} id="prodSpaceHorizontal" type="number" min="1" max="50" step="1" defaultValue="3" style={{ padding: '0px 0px 0px 10px', fontSize: '12px', float: 'right', marginRight: '0px', borderRadius: '40px', width: '97px', textAlign: 'center' }} /></p>
					
					<p style={{ marginBottom: '7px', marginLeft: '20px', fontSize: '13px', height: '30px', lineHeight: '30px' }}><span style={{ width: '100px', display: 'inline-block' }}>Vertical:</span><input onChange={updateVerticalSpacing} id="prodSpaceVertical" type="number" min="1" max="50" step="1" defaultValue="3" style={{ padding: '0px 0px 0px 10px', fontSize: '12px', float: 'right', marginRight: '0px', borderRadius: '40px', width: '97px', textAlign: 'center' }} /></p>
				
					<hr style={{ marginTop: '17px', borderColor: '#aaa' }} />
					
					<p><b style={{ width: '138px', display: 'block', fontSize: '13px', fontWeight: 'bold', paddingBottom: '5px', marginTop: '21px', marginBottom: '20px' }}>Colors</b></p>
					
					<p id="shelvesSeparatorColorCont" style={{ marginLeft: '20px', marginBottom: '6px', marginTop: '12px', height: '25px', fontSize: '13px', }}>
						<span style={{ marginRight: '6px', display: 'inline-block', float: 'left' }}>Shelves separator color</span>
						<span style={{ float: 'right', marginRight: '10px' }}>
							<PopoverPicker color={separatorColor} onChange={setSeparatorColor} />
						</span>
					</p>
					<p id="activeCellColorCont" style={{ marginLeft: '20px', marginBottom: '6px', marginTop: '12px', fontSize: '13px', }}>
						<span style={{ marginRight: '55px', display: 'inline-block', float: 'left' }}>Active cell color</span>
						<span style={{ float: 'right', marginRight: '10px' }}>
							<PopoverPicker color={activeCellColor} onChange={setActiveCellColor} />
						</span>
					</p>
					<p id="inactiveCellColorCont" style={{ fontSize: '13px', marginLeft: '20px', marginBottom: '6px', marginTop: '50px' }}>
						<span style={{ marginRight: '45px', display: 'inline-block', float: 'left' }}>Inactive cell color</span>
						<span style={{ float: 'right', marginRight: '10px' }}>
							<PopoverPicker color={inactiveCellColor} onChange={setInactiveCellColor} />
						</span>
					</p>
					<p id="linesColorCont" style={{ fontSize: '13px', marginLeft: '20px', marginBottom: '6px', marginTop: '87px' }}>
						<span style={{ marginRight: '45px', display: 'inline-block', float: 'left' }}>Lines color</span>
						<span style={{ float: 'right', marginRight: '10px' }}>
							<PopoverPicker color={linesColor} onChange={setLinesColor} />
						</span>
					</p><br />
					<hr style={{ marginTop: '17px', borderColor: '#aaa', clear: "both" }} />
					
						
					<p><b style={{ width: '138px', display: 'block', fontSize: '13px', fontWeight: 'bold', paddingBottom: '5px', marginTop: '21px', marginBottom: '20px' }}>Additional settings</b></p>
					
					<p id="showPricesCont" style={{ marginLeft: '20px', marginBottom: '6px' }}>
						<label>
							<span>Show prices</span>
							<span style={{ float: "right", marginRight: '10px' }}>
								<Switch height={20} width={44} checkedIcon={false} uncheckedIcon={false} onColor={"#c51953"} onChange={() => toggleProductsPrices()} checked={pricesVisible} />
							</span>
						</label>
					</p>
					<p id="showPricesCont" style={{ marginLeft: '20px', marginBottom: '6px' }}>
						<label>
							<span>Show ruler</span>
							<span style={{ float: "right", marginRight: '10px' }}>
								<Switch height={20} width={44} checkedIcon={false} uncheckedIcon={false} onColor={"#c51953"} onChange={() => toggleRuler()} checked={rulerVisible} />
							</span>
						</label>
					</p>
				</div>
				<div id="excelEditor">
					<div id="excelContent"></div>
				</div>
			</div>
			<div id="jsonExport"></div>
			<div data-popbox-id="popbox1" class="popbox">
				<div class="popbox_container">
					<div>
					<p class="heading"><strong>Edit product price:</strong></p>
					<p style={{ textAlign: 'center' }}><input type="text" id="txtProductPrice" /></p>
					</div>
					<p style={{ textAlign: 'right', marginTop: '30px' }}>
						<button id="btnSaveNewProductPrice" onClick={updateProductPrice}>Save</button>
						<button data-popbox-close="popbox1">Close</button>
					</p>
				</div>
			</div>
			<div data-popbox-id="popbox2" class="popbox">
				<div class="popbox_container">
					<div>
					<p class="heading"><strong>Edit product name:</strong></p>
					<p style={{ textAlign: 'center' }}><input type="text" id="txtProductName" style={{ width: '97%' }} /></p>
					</div>
					<p style={{ textAlign: 'right', marginTop: '30px' }}>
						<button id="btnSaveNewProductName" onClick={updateProductName}>Save</button>
						<button data-popbox-close="popbox2">Close</button>
					</p>
				</div>
			</div>
			<div data-popbox-id="popbox3" class="popbox">
				<div class="popbox_container">
					<div>
					<p class="heading"><strong>Edit product dimensions:</strong></p>
					<p style={{ textAlign: 'center' }}><input type="text" id="txtProductDim" style={{ width: '97%' }} /></p>
					</div>
					<p style={{ textAlign: 'right', marginTop: '30px' }}>
						<button id="btnSaveNewProductDim" onClick={updateProductDim}>Save</button>
						<button data-popbox-close="popbox3">Close</button>
					</p>
				</div>
			</div>
			<div data-popbox-id="popboxTemplate" class="popbox">
				<div class="popbox_container">
					<div>
					<p class="heading"><strong>Enter template name:</strong></p>
					<p style={{ textAlign: 'center' }}><input type="text" id="txtTemplateName" /></p>
					</div>
					<p style={{ textAlign: 'right', marginTop: '30px' }}>
						<button id="btnSaveNewTemplate" onClick={saveTemplate}>Save</button>
						<button data-popbox-close="popboxTemplate">Close</button>
					</p>
				</div>
			</div>
			<div data-popbox-id="popboxNewProduct" class="popbox">
				<div class="popbox_container" style={{ height: '310px' }}>
					<div id="newProdImgPreview" style={{ width: '35%', float: 'left', marginRight: '25px', display: 'flex', height: '100%' }}>
						<img id="imgNewProd" src="" />
					</div>
					<div style={{ float: 'left' }}>
						<p><strong>Enter product name:</strong></p>
						<p style={{ textAlign: 'center', marginBottom: '10px' }}><input type="text" placeholder="Product name..." id="txtNewProductName" /></p>
						<p><strong>Enter product dimensions:</strong></p>
						<p style={{ textAlign: 'center', marginBottom: '10px' }}><input type="text" placeholder="e.g. 12x10x8cm" id="txtNewProductDim" /></p>
						<p><strong>Enter product price:</strong></p>
						<p style={{ textAlign: 'center', marginBottom: '10px' }}><input type="text" placeholder="e.g. $12.5" id="txtNewProductPrice" /></p>
						<p style={{ textAlign: 'right', marginTop: '30px' }}>
							<button id="btnSaveNewProduct" onClick={() => { uploadProductImage(); }}>Save</button>
							<button data-popbox-close="popboxNewProduct">Close</button>
						</p>
					</div>
				</div>
			</div>
        </div>
		</ShelvesDesignerStyles>
    );
};

export default ShelvesDesigner;